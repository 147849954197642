import React from 'react'
import NoElemPreview from '../../static/blankPreview.png';
function elementParse(elements) {
  if(elements.type === 'video') {
    if(elements?.content?.includes(".mp4") || elements?.content?.includes(".webm"))
      return <video className='aspect-square w-full' controls width="400"  preload="metadata" onClick={(e) => {e.stopPropagation()}}>
          <source  src={elements?.content + "#t=1.0"} type="video/mp4" />
        </video>
      
  }
  if(elements.type === "img" || elements.type === "viewTemplateImage" || elements.type === "image") {
    return (
      <img 
        src={elements?.content}
        className='aspect-square w-full'
      />
    )
  }
  console.log(elements.type);
  if(elements.type === "template" || elements.type === "viewTemplate") {
    return <img
        src={NoElemPreview}
        className='aspect-square w-full'

      />
  }
  return <div className='w-full h-full flex flex-col items-center justify-center'>
    <h2 className='text-white font-bold text-2xl'>{elements.type}</h2>
  </div>
}

const PlaylistElement = ({element}) => {
  return (
    <div className='h-[100%] w-[100%]  rounded-lg p-2'>
      <div className='  w-full bg-slate-900 flex justify-center items-center overflow-clip'>
        {elementParse(element)}    
      </div>
      <div className='w-full rounded-b-lg bg-gray-500 border-gray-300 border-t-2 flex justify-center items-center'>
        <h2 className='text-white font-bold'>{element.type}</h2>
      </div>
    </div>
  )
}

export default PlaylistElement