import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getItem, updateItem } from "../api/itemsApi";
import PreviewTemplate from "../components/temps/PreviewTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddImage from '../static/upload.png'
import { uploadImage } from "../api/uploadApi";
import Loading from "../modals/Loading";
import { usePDF } from "react-to-pdf";

const PreviewTemplateWithItem = () => {
  const {toPDF, targetRef} = usePDF({filename:"template.pdf"});

  const {item} = useParams();
  const {t} = useTranslation();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  const [loadingModal, setLoadingModal] = useState(false);
  const [itemToDisplay, setitemToDisplay] = useState({});
  const [imageError, setImageError] = useState("");

  const selectedStore = useSelector(state => state.storeSelection.selectedStore);

  useEffect(() => {
    getItem(user.token,"",item, selectedStore)
      .then(res => setitemToDisplay(res))
      .catch(err => console.log(err));
  }, []);

  const onChange = (e) => {
    setitemToDisplay(item => ({...item, [e.target.name]:e.target.value}));
  }

  const imageUploadHandle = async (e) => {
    const file = e.target.files[0];
    if(file.type != "image/jpeg" && file.type != "image/png") {
      setImageError("Images must be .jpgs or .pngs only")
      setTimeout(() => {
        setImageError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', item.admin);
    form.append('selectedStore', item.storeId);
    setLoadingModal(true);
    try {
      const result = await uploadImage(user.token, "", form);
      setitemToDisplay(item => ({...item, imageLink:result.link}));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const onSubmit = async () => {
    try {
      await updateItem(user.token, "", itemToDisplay);
      navigate(-1)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="w-full h-sceen flex justify-start items-start">
      {loadingModal && <Loading/>}
      <div className="relative h-screen bg-black w-[70%] flex flex-col justify-center items-center">
        {/* <button className="btn absolute top-1 left-1" onClick={() => toPDF()}>
          To PDF
        </button> */}
        <div className="w-[95%] border-2 border-white h-[80%] rounded-lg">
          <PreviewTemplate item={ itemToDisplay} ref={targetRef}/>
        </div>
      </div>
      <div className="h-[85%] relative w-[30%] flex flex-col overflow-scroll justify-start items-center gap-5">
        <div className="flex flex-col justify-center items-center text-center w-[90%] gap-2 mt-5">
          <p
            className="block text-sm text-black font-bold"
          >
            {t("name")}
          </p>
          <input
            onChange={onChange}
            type="text"
            name="name"
            value={itemToDisplay.name}
            className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
        </div>
        <div className="flex flex-col justify-center items-center text-center w-[90%] gap-2">
          <p
            className="block text-sm text-black font-bold"
          >
            {t("unit")}
          </p>
          <input
            onChange={onChange}
            type="text"
            name="unit"
            value={itemToDisplay.unit}
            className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
        </div>
        <div className="flex flex-col justify-center items-center text-center w-[90%] gap-2">
          <p
            className="block text-sm text-black font-bold"
          >
            {t("price")}
          </p>
          <input
            onChange={onChange}
            type="text"
            name="price"
            value={itemToDisplay.price}
            className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
        </div>
        <div className="flex flex-col justify-center items-center text-center w-[90%] gap-2">
          <p
            className="block text-sm text-black font-bold"
          >
            מחיר קודם
          </p>
          <input
            onChange={onChange}
            type="text"
            name="originalPrice"
            value={itemToDisplay.originalPrice}
            className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
        </div>
        <div className="relative flex flex-col justify-center items-center text-center w-[90%] gap-2">
          <input id="imageInput" hidden type="file" accept='image/*' onChange={imageUploadHandle} />
          <label
            className='w-15 h-5 absolute top-11 left-2  cursor-pointer'
            htmlFor="imageInput"
            
          >
            <img htmlFor="imageInput"  src={AddImage} width={24}/>
          </label>
          <p
            className="block text-sm text-black font-bold"
          >
            {t("imageLink")}
          </p>
          <input
            onChange={onChange}
            type="text"
            name="imageLink"
            value={itemToDisplay.imageLink}
            className="h-10 pl-8 text-black  border-[1px] border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
          <h2 className='text-red-500 font-bold text-md'>{imageError}</h2>
        </div>
        <button
          className="btn transition-all hover:bg-blue-900"
          onClick={onSubmit}
        >
          {t("save")}
        </button>
      </div>
    </div>
  )
}

export default PreviewTemplateWithItem