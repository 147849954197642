import { useEffect, useState, version } from "react";
import Header from "../components/layout/Header";
import Select from "react-tailwindcss-select";
import deleteIcon from '../static/delete.png';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store";
import { getGroupDevices, getGroups, updateGroup } from "../api/versionApi";
import { useTranslation } from "react-i18next";
import { uploadApk } from "../api/uploadApi";
import Loading from "../modals/Loading";
import { useNavigate } from "react-router-dom";
import { getVersions } from "../api/versionUploadApi";
import OperationSuccesfulModal from "../components/layout/OperationSuccesfulModal";
import AddGroupDevice from "../modals/upload/AddGroupDevice";
import DeleteGroupDevice from "../modals/upload/DeleteGroupDevice";

const VersionPage = () => {
  const [groups, setGroups] = useState([]);
  const [versions, setVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [currentGroup, setCurrentGroup] = useState({
    group:"",
    link:"",
    version:""
  });

  //modals
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [addDevice, setAddDevice] = useState(false);
  const [deleteDevice, setDeleteDevice] = useState({
    show:false,
    code:""
  })

  const {t} = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  
  const fetchData = async () => {
    try {
      let res = await getVersions(user.token);
      setVersions(res);
      res = await getGroups(user.token);
      setGroups(res);
      setCurrentGroup(res[0]);
    } catch (err) {
      console.error(err)
      dispatch(logoutUser());
    }
  }

  const fetchDevices = async () => {
    if(currentGroup.group == "default") {
      return [];
    }
    try {
      const res = await getGroupDevices(user.token, {groupName: currentGroup.group});
      setDevices(res);
    } catch (err) {
      console.log(err);
    }
  }

  const handleUpdateGroup = async () => {
    try {
      await updateGroup(user.token, currentGroup);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000)
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
    fetchDevices();
  }, [currentGroup.group, addDevice, deleteDevice])


  return (
    <>
      <Header showBack={true} backTitle={""}/>
      {loading && <Loading/>}
      {showSuccessMessage && <OperationSuccesfulModal/>}
      {addDevice && <AddGroupDevice setShowModal={setAddDevice} group={currentGroup.group}/>}
      {deleteDevice.show && <DeleteGroupDevice setShowModal={setDeleteDevice} code={deleteDevice.code}/>}
      <div className='w-full h-full flex flex-col items-center overflow-scroll gap-5 p-2'>
        <h2 className='text-black font-bold text-2xl'>Version Control Page</h2>
        <div className="flex flex-row w-full items-center justify-center gap-2">

          <div className='w-[25%] flex flex-col gap-2'>
            <h2 className='text-black font-bold text-xl'>Select Group:</h2>
            <Select
              value={{label:currentGroup.group, value:currentGroup}}
              options={groups.map(group => ({label:group.group, value:group}))}
              onChange={(selection)=> setCurrentGroup(selection.value)}
              classNames={{
                menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                listItem: ({ isSelected }) => (
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                      isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`
                )
              }}
            />
          </div>
          <div className='w-[25%] flex flex-col gap-2'>
            <h2 className='text-black font-bold text-xl'>Version:</h2>
            <Select
              value={{label:currentGroup.version, value:currentGroup.version}}
              options={versions.map(version => ({label:version.version, value:version}))}
              onChange={(selection)=> setCurrentGroup(grp => ({...grp, link:selection.value.link, version:selection.value.version}))}
              classNames={{
                menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                listItem: ({ isSelected }) => (
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                      isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`
                )
              }}
            />
          </div>
        </div>
        <div className='w-[50%] flex flex-col gap-2 relative'>
          <h2 className='text-black font-bold text-xl'>Link:</h2>
          <input
            onChange={(e)=> setCurrentGroup(currGrp => ({...currGrp, link:e.target.value}))}
            type="text"
            name="imageLink"
            disabled
            value={currentGroup.link}
            className="h-10  text-black  border-[1px] rounded-sm bg-gray-300 border-[#c7c7c8]  focus:ring-none outline-none w-[100%] px-2 py-6"
          />
        </div>
        <div className="w-[25%] flex flex-col ">
          <button 
            className="btn"
            onClick={handleUpdateGroup}
          >
            Deploy
          </button>
        </div>
        {currentGroup.group && currentGroup.group !== "default"  && <div className="w-full flex flex-col items-end justify-center overflow-scroll">
          <button 
            className="border-2 border-[#2D5186] text-[#2D5186] p-2 rounded-md"
            onClick={() => {
              setAddDevice(true);
            }}
          >
            Add Device
          </button>
          <table className="w-full">
            <thead className="text-sm md:text-[16px] text-slate-700 font-bold border-b-2  border-gray-300">
              <tr>
                <th
                  className="px-6 py-4" 
                  scope='col'
                >
                  <h2>Device Code</h2>
                </th>
                <th
                  className="px-6 py-4" 
                  scope='col'
                >
                  <h2>Device Mac</h2>
                </th>
                <th
                  className="px-6 py-4" 
                  scope='col'
                >
                  <h2>Status</h2>
                </th>
                <th
                  className="px-6 py-4" 
                  scope='col'
                >
                  <h2>actions</h2>
                </th>
              </tr>

            </thead>
            <tbody>
              {devices.map(device => <tr
                className="border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
              >
                <th
                  className="px-6 py-4"
                >
                  {device.code}
                </th>
                <th
                  className="px-6 py-4"
                >
                  {device.mac}
                </th>
                <th
                  className="px-6 py-4"
                >
                  Online
                </th>
                <th
                  className="px-6 py-4 flex items-center justify-center"
                >
                  <img
                    src={deleteIcon}
                    className="w-6 hover:cursor-pointer"
                    onClick={() => setDeleteDevice({show:true, code: device.code})}
                  />                  
                </th>
              </tr>)}
            </tbody>
          </table>
        </div>
        }
      </div>
    </>
  )
}

export default VersionPage