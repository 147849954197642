import axios from 'axios';

export const searchItems = async (token,url,barcode,storeFilter,temp, department) => {
  const params = {
    barcode,
    storeFilter
  };
  
  if(temp) params["temp"] = temp;
  if(department) params["department"] = department;
  try {
    const res = await axios.get(
    `https://backend.konekt.vip/items/searchItem`,
    {
      params,
      headers:{
        token: `bearer ${token}`
      }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const getItem = async (token,url,barcode,storeFilter) => {
  try {
    const res = await axios.get(`https://backend.konekt.vip/items/getItem?barcode=${barcode}&storeFilter=${storeFilter}`, {
      headers:{
        token: `bearer ${token}`
      }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const updateItem = async (token,url,body) => {
  
  try {
    const res = await axios.put(
      `https://backend.konekt.vip/items/updateItem`,
      body, 
      {
        headers:{
          token: `bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const addItem = async (token, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/items/addItem`,
    body, 
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getItemNames = async (token, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/items/names`,
    body,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}