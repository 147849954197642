import React, { useState } from 'react';
import Checkmark from '../../static/checkmark.png';
import DeleteIcon from '../../static/delete_black.png';
import Preview from '../../static/eye.png';
import arrowLeft from "../../static/arrow-left.png";
import arrowRight from "../../static/arrow-right.png";
import Edit from '../../static/stylus.png';

import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import i18n from '../../i18n';

const PlaylistItem = ({playListId,setDeletePlaylistId, showId}) => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();
  return (
    <div 
      onClick={() => {
        navigate(`/editPlaylist/${playListId}`);
      }}  
      dir={i18n.language == "he" ? "rtl" :"ltr"}
      className='relative flex flex-row justify-end items-start bg-white p-4 gap-3 border-b-2
      border-gray-200 w-[90%] overflow-clip cursor-pointer'
    >
      <>
        <div className='flex flex-col justify-start items-start gap-2 w-full'>
          <h2 className='text-black font-bold text-2xl'>List ID: {playListId}</h2>
          <h2 className='text-black  font-semibold flex justify-center items-center'>
            {t("showProfileId") + ": "}
            {showId ? t("yes") : t("no")}
          </h2>
          <button 
            onClick={(e) => {
              e.stopPropagation();
              setDeletePlaylistId(playListId);
            }} 
            className='bg-red-300 p-2 rounded-lg  text-white font-semibold text-lg'
          >
            <img src={DeleteIcon}/>
          </button>
        </div>
        <button className='flex flex-col justify-center items-center mt-10'>
          {i18n.language == "he" ? (<img src={arrowLeft} width={25}/>) :(<img src={arrowRight} width={25}/>)}
        </button>
      </>
    </div>
  )
}

export default PlaylistItem