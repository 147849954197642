import React from 'react'
import { useTranslation } from 'react-i18next'

const Title = ({title}) => {
  const {t} = useTranslation()
  return (
    <div className='bg-white w-[80%] p-2 shadow-md border-2 mt-5 flex justify-center items-center'>
          <h2 className='text-black font-bold text-2xl'>{t(title)}</h2>
        </div>
  )
}

export default Title