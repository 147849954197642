import React, { useState } from 'react'
import SaveIcon from '../../../static/floppy-disk.png';
import CloseIcon from '../../../static/crossB.png';
import { useSelector } from 'react-redux';
import { editDeviceNameApi } from '../../../api/deviceApi';
const EditPlaylistName = ({playlistName, playlistId, setShowModal}) => {
  //Yeah, yeah, shut the fuck up already
  const [newPlaylistName, setNewPlaylistName] = useState(playlistName || "");
  const [error, setError] = useState("");
  const user = useSelector(state => state.user);

  const submitName = async () => {
    if(!newPlaylistName) {
      setError("No playlist name provided");
      return setTimeout(() => {
        setError("")
      }, 3000)
    }
    try {
      await editDeviceNameApi(
          user.token,
          {
            playlistName: newPlaylistName,
            deviceId: playlistId,
          }
      );
      setShowModal(false);
    } catch (err) {
      setError(err.response.data.msg);
      return setTimeout(() => {
        setError("")
      }, 3000)
    }
  }

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden
        overflow-y-auto fixed inset-0 z-50
        outline-none focus:outline-none"
      >
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Playlist Name
              </p>
              <input 
                className='w-[70%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                value={newPlaylistName}
                name="playlistName"
                onChange={(e) => setNewPlaylistName(e.target.value)}
              />
            </div>
            <div className='w-full flex-col flex justify-center items-center'>
              <h2 className='text-red-500 font-bold m-2'>{error}</h2>
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 ">
              <div className="flex flex-row justify-center gap-10 w-[100%]">
                <button
                  onClick={submitName}
                  className='border-2 p-1 border-[#2D5186] rounded-lg hover:bg-gray-100 transition-all'
                >
                  <img src={SaveIcon} className='w-8'/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditPlaylistName