import { useState } from 'react';
import CloseIcon from '../../static/crossB.png';
import { addGroupDevice } from '../../api/versionApi';
import { useSelector } from 'react-redux';

const AddGroupDevice = ({setShowModal, group}) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const user = useSelector(state => state.user);
  const submitDevice = async () => {
    try {
    
      await addGroupDevice(user.token, {code, groupName: group});
      
      setShowModal(false);
    } catch (err) {
      if(err.response.data.msg) {
        setError(err.response.data.msg);
      }
      if(!err?.response?.data?.msg) {
        setError("Unknown Error - Please try again later")
      }
      setTimeout(() => {
        setError("");
      }, 3000)
    }
  }

  return (
    <div>
      <div className="mt-24 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full md:w-[80%] h-full ">
          <div className="relative flex flex-col justify-start items-center bg-white rounded-lg shadow w-full md:w-[50%] h-[45%] p-2">
            <div className="flex flex-row justify-end gap-10 w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>

            <div className='w-full overflow-scroll flex flex-col p-5 gap-5 items-center'>
              <h2 className='text-black font-bold text-xl'>Device Code</h2>
              <input
                className='rounded-md border-2 border-gray-500 p-2 w-[80%]'
                value={code}
                onChange={e => setCode(e.target.value)}
                name='code'
              />
              <button
                onClick={() => {
                  submitDevice();
                }} 
                className='btn'
              >
                Add Device To Group
              </button>
              {error && <h2 className='text-red-500 font-bold'>{error}</h2>}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AddGroupDevice