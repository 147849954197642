import axios from "axios"

export const previewTemplate = async (token, data) => {
  const res = await axios.post(`https://backend.konekt.vip/items/previewTemplate`,
    data,
    {
      headers:{
        "token": `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getTemplates = async (token, data) => {
  const res = await axios.post(`https://backend.konekt.vip/template/search`,
    data,
    {
      headers:{
        "token": `bearer ${token}`
      }  
    }
  );
  return res.data;
}