import React, { useEffect, useState } from 'react'
import { getUploadsApi } from '../../api/uploadApi';
import { useSelector } from 'react-redux';
import CloseIcon from '../../static/crossB.png'
import PlaylistElement from '../../components/playlistElements/PlaylistElement';
const UploadModal = ({setShowModal, setItem, type, setPreview}) => {

  const [uploadedItems, setUploadedItems] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    showMore: false
  });
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const fetchData = async (limit) => {
    try {
      const res = await getUploadsApi(
        user.token,
        {
          type,
          storeId: selectedStore,
          limit
        } 
      );
      setUploadedItems(res.uploads);
      setPagination(pagination => ({...pagination, showMore: res.showMore}));

    } catch (err) {
      console.log(err)
    }
  }

  const increasePageLimit = () => {
    fetchData(pagination.limit +10);
    setPagination(pagination => ({...pagination, limit: pagination.limit +10}))
  }

  const pickUpload = (upload) => {
    let itemUpdate;
    if(type == "image") {
      itemUpdate = {imageLink: upload.link};
      if(setPreview) {
        setPreview(prv => ({...prv, img: upload.link}))
      }
    }
    if(type == "video") {
      itemUpdate = {videoLink: upload.link};
      if(setPreview) {
        setPreview(prv => ({...prv, video: upload.link}))
      }
    }
    if(type == "powerpoint") {
      itemUpdate = {powerpointLink: upload.link};
    }
    if(type == "PDF") { 
      itemUpdate = {pdfLink: upload.link};
    }
    setItem(item => ({...item, ...itemUpdate}));
    setShowModal(false);
  }

  useEffect(() => {
    fetchData(pagination.limit);
  }, []);
  
  return (
    <div>
      <div className="mt-24 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full md:w-[80%] h-full ">
        <div className="relative flex flex-col justify-center items-center bg-white rounded-lg shadow w-full md:w-[70%] h-[80%] p-2">
            <div className="flex flex-row justify-end gap-10 w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className='w-full overflow-scroll flex flex-col p-5'>
              <div className='grid grid-cols-3  p-2 gap-5 '>
                {uploadedItems.map(upItem => <div
                  key={upItem._id} 
                  className='w-[100%] h-[100%] cursor-pointer' onClick={() => pickUpload(upItem)}>
                    <PlaylistElement  element={{type: type, content: upItem.link}}/>
                </div>)}
              </div>
              <button
                disabled={!pagination.showMore}
                className='btn disabled:opacity-50'
                onClick={increasePageLimit}
              >
                  Load more
              </button>

            </div>

          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default UploadModal