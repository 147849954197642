import {Link, useNavigate, useLocation} from 'react-router-dom'
import i18n from '../i18n';
import Scanner from '../components/scanner/Scanner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { replaceBarcodeByIndex } from '../api/deviceApi';
import { useTranslation } from 'react-i18next';

const ReplaceWithScanner = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const device = useSelector(state => state.device);
	const url = useSelector(state => state.url.url);
	const user = useSelector(state => state.user);
	const {t} = useTranslation();
	const item = location.state?.item;
	const [startCamera, setStartCamera] = useState(true);
	const [barcode, setBarcode] = useState("");
	const updateItem = async () => {
		try {
			await replaceBarcodeByIndex(user.token,url, {
				deviceId: device.deviceId,
				displayIndex: item.displayIndex,
				barcodeIndex: item.barcodeIndex,
				barcode
			});
			navigate(-1);
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if(!item) {
			navigate("/")
		}
	},[])

	if(!item) {
		return <></>
	}
	
	return <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='bg-gray-100 w-full h-full flex flex-col justify-start items-center p-5 gap-2 overflow-scroll'>
		<div className='bg-white flex flex-col w-full md:w-[50%] shadow-md p-5 items-center'>
			<h2 className='text-black font-bold text-lg'>{t("itemToReplace")}</h2>
			<h2 className='text-black font-bold text-md'>{item.barcode}</h2>
			<h2 className='text-black font-bold text-md'>{item.name}</h2>
		</div>
			<button className='btn !p-2 m-3' onClick={() => navigate(-1)}>
				{t("back")}
			</button>
		<Scanner setStartCamera={setStartCamera} startCamera={startCamera} setData={setBarcode}/>
		<input
			type='text'
			name="itemBarcode"
			value={barcode}
			onChange={(e) => setBarcode(e.target.value)}
			placeholder='item Barcode'
			className="h-10 pl-8 w-full text-black bg-white border-[1px] border-[#e0e0e2]  focus:ring-none outline-none"
			
		/>
		{barcode && <>
			<button className='btn' onClick={updateItem}>Replace</button>
		</>}
	</div>
}

export default ReplaceWithScanner;