import React, { useEffect, useState } from 'react'
import { getAndroidKeys } from '../api/AndroidKeyApi';
import { useSelector } from 'react-redux';
import Header from '../components/layout/Header';
import i18n from '../i18n';
import OnlineStats from '../components/androidKey/Stats/OnlineStats';

const GeneralDashboard = () => {
  const [deviceData, setDeviceData] = useState({});
  
  
  const user = useSelector(state => state.user);

  const fetchData = async () => {
    try {
      let deviceCollection = {}; 
      const res = await getAndroidKeys(user.token, {});
      res.forEach(device => {
        if(!device.storeId || device.storeId == "") {
          device.storeId = "Unbound"
        }
        if(!deviceCollection[device.storeId]) {
          deviceCollection[device.storeId] = {}
          deviceCollection[device.storeId].online = 0;
          deviceCollection[device.storeId].offline = 0;
        }

        if(deviceCollection[device.storeId]) {
          if(device.connected) {
            deviceCollection[device.storeId].online +=1;
          }
          if(!device.connected) {
            deviceCollection[device.storeId].offline +=1;
          }
        }
      });
    setDeviceData(deviceCollection);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <>
     <Header/>
     
      <div dir={i18n.language == "he" ? "rtl" :"ltr"}
        className='h-screen w-full bg-white flex flex-col items-center
          overflow-y-scroll pb-[100px]'
      >
        <div className='w-full flex flex-row gap-5 p-5'>
          {deviceData &&  Object.keys(deviceData).length > 0 && <OnlineStats deviceData={deviceData}/>}
        </div>
      </div>
    </>
  )
}

export default GeneralDashboard