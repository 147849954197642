import axios from "axios";

export const uploadVersion = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/uploadVersion`,
    data,
    {headers:{token:`bearer ${token}`}}
  );
  return res.data;
}

export const getVersions = async (token) => {
  const res = await axios.get(
      `https://backend.konekt.vip/uploadVersion`,
      {headers:{token:`bearer ${token}`}}
  );
  return res.data;
}