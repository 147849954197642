import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getStoresApi} from "../../api/storesApi"
import Select from "react-tailwindcss-select";
import { setSelectedCategory, setSelectedStore } from "../../store";


const UserSelect = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const storeSelection = useSelector(state => state.storeSelection);
  const [StoreFilter, setStoreFilter] = useState(storeSelection.selectedStore);


  const [categories, setCategories] = useState([]);

  const [stores, setStores] = useState([]);
  const [filter, setFilter] = useState(storeSelection.selectedCategory);
  const [filteredStores, setFilteredStores] = useState([]);

  const [storeDic, setStoreDic] = useState([]);

  useEffect(() => {
    getStoresApi(user.token, url)
      .then(res => {

        setStores(res);
        if(storeSelection.selectedCategory && !storeSelection.selectedCategory !== "All") {
          setFilteredStores(res.filter(store=> store.category === storeSelection.selectedCategory));

        }
        let storeLookup = {}
        let categoryLookup = {}
        res.forEach(store => {
          storeLookup[store.storeId] = `${store.storeId} ${store.name ? `(${store.name})` :""}`
          if(store.category) {
            categoryLookup[store.category] = store.category
          }
        });
        setCategories(["All",...Object.keys(categoryLookup)]);
        setStoreDic(storeLookup);
      })
      .catch(err => console.log(err))
  }, []);

  console.log(categories)

  return (
    <div className='relative  md:h-[20%] flex flex-col md:flex-row gap-2 md:gap-0  justify-center items-center text-center'>
        {user.superAdmin &&  categories && categories.length > 0 && <div className='flex flex-row w-full gap-2 justify-center items-center px-4'>
          <h2 className='text-white w-[100px] font-bold text-md'>Category:</h2>
          <Select
            options={
              categories.map(cat => 
                ({value:cat, label:`${cat}`}))
            }
            value={{value:filter, label:filter}}
            onChange={(selection) => {
              setFilter(selection.value);
              if(selection.value !== "All") {
                setFilteredStores(stores.filter(store => store.category === selection.value));
                setStoreFilter(stores.filter(store => store.category === selection.value)[0].storeId);
                dispatch(setSelectedStore(stores.filter(store => store.category === selection.value)[0].storeId));
                dispatch(setSelectedCategory(selection.value));
              }
              if(selection.value === "All") {
                setFilteredStores(stores);
                dispatch(setSelectedCategory("All"))
              }
              
            }}
            classNames={{
              menu:"absolute w-[220px] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
              menuButton:() => "flex flex-row w-[220px] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
        </div>}
        <div className='flex flex-row w-full gap-2 justify-center items-center px-4'>
          <h2 className='text-white w-[100px] font-bold text-md'>Store:</h2>
          <Select
            options={
              filteredStores.map(storeId => 
                ({value:storeId.storeId, label:`${storeId.storeId} ${storeId.name ? `(${storeId.name})` :""}`}))
            }
            value={{value:StoreFilter, label:storeDic[StoreFilter]}}
            onChange={(selection)=> {
              setStoreFilter(selection.value);
              dispatch(setSelectedStore(selection.value));
            }}
            classNames={{
              menu:"absolute w-[220px] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
              menuButton:() => "flex flex-row w-[220px] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
        </div>
        
      </div>
  )
}

export default UserSelect