import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng:"en",
    resources: {
      en: {
        translation: {
          smartPrint:"Smart Print",
          displayActive:"Display Active",
          online:"status:",
          showProfileId:"Show Playlist/device Id",
          url:"URL",
          email:"Email",
          password:"Password",
          enter:"Enter",
          language:"Language",
          deviceId:"Device Id:",
          addDisplay:"Add Display",
          profileId:"Playlist:",
          display:"Display:",
          rotationTime:"Rotation Time (Seconds)",
          addNewDisplay:"Add New Display",
          displays:"Displays",
          barcodes:"content",
          addAbarcode:"Add content",
          add:"Add",
          submit:"Save",
          close:"Close",
          areYouSureYouWantToDeleteDisplay:"Are you sure you want to delete this display?",
          yes:"Yes",
          no:"No",
          editDisplay:"Edit Display",
          deviceProfiles:"Playlists",
          licenses:"Web Player Codes",
          displays:"Displays",
          templates:"Templates",
          products:"Content",
          logout:"Logout",
          editLicenseProperties:"Edit Code Properties",
          licenseId:"Code Id",
          pairedTo:"Paired To",
          structure:"Structure",
          searchItems:"search Content",
          editItem:"Edit Content",
          name:"Name",
          template:"Template",
          price:"Price",
          unit:"Unit",
          imageLink:"Image Link",
          description:"Description",
          image:"Image",
          video:"Video",
          view:"View",
          type:"Type",
          save:"Save",
          content:"Content",
          elementDetails:"Element Details",
          cloneDisplay:"Clone Display",
          cloneToUser:"Clone To Store",
          clone:"Clone",
          showUnboundItems:"Show Unbound Content",
          display:"Display",
          displayName:"Display Name",
          startHour:"Start Hour",
          endHour:"End Hour",
          barcodeNotFound:"Barcode Not Found",
          videoLink:"Video Link",
          itemToReplace:"Content To Replace",
          back:"Back",
          replaceWith:"Replace With:",
          replace:"Replace",
          active:"Active",
          notActive:"Not Active",
          screenStatus:"Screen Status",
          displayStatus:"Display Status",
          displayName:"Display Name",
          printer:"Print",
          originalPrice:"Original Price",
          print:"Print",
          startDate:"Start Date",
          endDate: "End Date",
          setShowDate:"Limit display to specific date ",
          content:"content",
          addPlaylist:"Add Global Playlist",
          deletePlaylist:"Delete Global Playlist",
          askDeletePlaylist:"Are you sure you want to delete this Global playlist?",
          addNewPlaylist:"Add new Global Playlist",
          submitPlaylist:"Submit Global playlist",
          cancel:"cancel",
          addStore:"Add Store",
          addDevice:"Add Device",
          savePlaylist:"Save Global playlist",
          savePlaylistAndSend:"Save Global playlist and send Changes",
          selectAllDevices: "Select All Devices",
          selectAllStores: "Select All Stores",
          listId:"Global Playlist Id",
          stores:" Stores",
          delete:"delete",
          playlists:"Global Playlists",
          showItems:"Show Content",
          displayTemplates:"Content Design",
          refreshDevice:"Refresh Devices",
          androidDevices:"Devices",
          send:"Send",
          androidDeviceProperties:"Device Properties",
          choiceCommand:"Choice Command...",
          webPlayerStatus:"Web Player Status:",
          boundDevices:"Bound Devices",
          areyousureyouwantounbindthisDevice:"Are you sure you want to unbind this device?",
          showContent:"Show Content",
          hideContent:"Hide Content",
          message:"Message",
          duration:"Duration",
          uploads:"Material"
        }
      },
      he: {
        translation: {
          smartPrint:"הדפסה חכמה",
          isplayActive:"צג פעיל",
          online:"פעיל:",
          url:"כתובת",
          email:"כתובת אימייל",
          password:"סיסמה",
          enter:"כניסה",
          language:"שנה שפה",
          deviceId:"מספר מכשיר:",
          addDisplay:"הוסף צג",
          profileId:"מספר פליליסט:",
          display:"צג:",
          showProfileId:"הצג מספר פליליסט/קוד מכשיר",
          rotationTime:"זמן שינוי בין צגים (שניות)",
          addNewDisplay:"הוסף צג חדש",
          displays:"צגים",
          barcodes:"תוכן",
          addAbarcode:"הוסף תוכן",
          add:"הוסף",
          submit:"שמירה",
          close:"סגור",
          areYouSureYouWantToDeleteDisplay:"אתה בטוח שאתה רוצה למחוק את הצג",
          yes:"כן",
          no:"לא",
          notActive:"לא פעיל",
          active:"פעיל",
          editDisplay:"שינוי צג",
          deviceProfiles:"פליליסטים",
          licenses:"קודי נגני ווב",
          displays:"צגים",
          templates:"טמפלטים",
          products:"תוכן",
          logout:"יציאה",
          editLicenseProperties:"שינוי הגדרות קוד",
          licenseId:"קוד",
          pairedTo:"שויך ל",
          structure:"מבנה",
          searchItems:"חיפוש תוכן",
          editItem:"עדכן תוכן",
          name:"שם",
          template:"טמפליט",
          price:"מחיר",
          unit:"יחידת מחיר",
          imageLink:"לינק תמונה",
          description:"שדה נוסף",
          image:"תמונב",
          video:"וידאו",
          view:"טמפליט",
          elementDetails:"פרטים",
          type:"סוג",
          save:"שמירה",
          content:"תוכן",
          cloneDisplay:"שכפל צג",
          cloneToUser:"שכפל לחנות",
          clone:"שכפל",
          showUnboundItems:"הצג תוכן לא משויך",
          display:"צג",
          displayName:"שם צג",
          startHour:"שעת התחלה",
          endHour:"שעת סיום",
          barcodeNotFound:"ברקוד לא נמצא",
          videoLink:"לינק וידאו",
          itemToReplace:"החלף תוכן:",
          back:"חזור",
          replaceWith:"החלף ב:",
          replace:"החלף",
          active:"מנגן עכשיו",
          notActive:"לא מנגן",
          screenStatus:"סטטוס מסך",
          displayStatus:"סטטוס צג",
          webPlayerStatus:"סטטוס נגן ווב:",
          displayName:"שם צג",
          printer:"הדפסה",
          originalPrice:"מחיר רגיל",
          print:"הדפס",
          startDate:"תאריך התחלה",
          endDate:"תאריך סיום",
          setShowDate:"להגביל צג לתאיך מסוים",
          content:"תוכן מוצג",
          addPlaylist:"הוסף רשימה גלובלית",
          deletePlaylist:"מחק רשימה גלובלית",
          askDeletePlaylist:"אתה בתוך שאתה רוצה למחוק את הרשימה גלובלית הזו",
          addNewPlaylist:"הוסף רשימה גלובלית חדשה",
          submitPlaylist:"צור רשימה גלובלית",
          cancel:"בטל",
          addStore:"הוסף חנות",
          addDevice:"הוסף מסך",
          savePlaylist:"שמור רשימה גלובלית",
          savePlaylistAndSend:"שמור רשימה גלובלית והפעל שנוים",
          selectAllDevices: "בחר את כל המסכים",
          selectAllStores: "בחר את כל חנויות",
          listId:"שם רשימה גלובלית",
          playlists:"רשימות גלובליות",
          stores:" חנויות",
          showItems:"הצג תוכן",
          displayTemplates:"עיצוב תוכן",
          refreshDevice:"רענן מכשירים",
          androidDevices:" מכשירים",
          send:"שלח",
          androidDeviceProperties:"הגדרות מכשיר",
          choiceCommand:"בחרו בפעולה... ",
          boundDevices:"שיוך מכשירים",
          areyousureyouwantounbindthisDevice:"אתה בתוך שאתה רוצה למחוק את המכשיר?",
          showContent:"הצג תוכן פעיל",
          hideContent:"הסתר תוכן פעיל",
          message:"הודעה",
          duration:"זמן",
          uploads:"מולטימדיה"
        }
      }
    },
  });

export default i18n;
