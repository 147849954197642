import React, { useState } from 'react'
import Delete from '../../static/delete.png';
import Edit from '../../static/stylus.png';
import DeleteDisplay from '../../modals/Device/DeleteDisplay';
import EditDisplay from '../../modals/Device/EditDisplay';
import Active from '../../static/check_circle.png';
import Activate from '../../static/eye.png';
import hideIcon from '../../static/eyes.png';
import Up from '../../static/up.png';
import Down from '../../static/down.png';

import { deviceSetDates, deviceSetRotation, setDeviceDisplayNumber } from '../../api/deviceApi';
import { useDispatch, useSelector } from 'react-redux';
import { setNewDisplayDates, setNewDisplayDatesPlaylist, setNewDisplayTime, setNewDisplayTimePlaylist, setNewRotation, setNewRotationPlaylist } from '../../store';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import DeleteDisplayModal from './modals/DeleteDisplayModal';


const PlayListDisplayItem = ({display, barcodes, deviceId, displayIndex, active}) => {
  const { t, i18n } = useTranslation();
  const playlist = useSelector(state => state.playlist);
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [showDelete, setShowDelete] = useState(false);
  const [time, setTime] = useState(playlist.displayTime[displayIndex] || {start:0, end:24});
  const [date, setDate] = useState({
    startDate: playlist?.displayDates[displayIndex]?.start || new Date().toString(),
    endDate: playlist?.displayDates[displayIndex]?.end || new Date().toString()
  });

  const [showDate, setShowDate] = useState(playlist?.displayDates[displayIndex]?.checkDate || false);
  const dispatch = useDispatch();

  const onChangeTime = (start, end) => {
    let deviceRotation = playlist.deviceRotation;
    let displayTime = [...playlist.displayTime];
    displayTime[displayIndex] = {start, end};
    
    dispatch(setNewDisplayTimePlaylist(displayTime));
    setTime({start, end});
  }


  return (
    <div className='flex flex-col w-[100%] min-h-[150px] justify-start items-center text-center rounded-xl  gap-5 border-[1px] border-gray-200'>
      <div className='flex justify-between items-center gap-2 bg-[#F7F7F8] w-full p-2 rounded-t-md'>
      {showDelete && <DeleteDisplayModal setShowModal={setShowDelete} displayIndex={displayIndex}/>}
        <div className='flex flex-col gap-5 justify-center overflow-x-scroll p-2'>
          <h2 className='font-bold text-md xl:text-xl'>{display}</h2> 
        </div>
        <div className='flex justify-center gap-5'>
          {playlist?.deviceRotation?.includes(displayIndex) ? (<button 
            className=''
            onClick={async () => {
              try {
                const newRotation = playlist.deviceRotation.filter(
                  x => x !== displayIndex);
                dispatch(setNewRotationPlaylist(newRotation));
                setTime({start:0, end:24});
              } catch (err) {
                console.log(err)
              }
            }}
          >
          <img className='w-[25px]' src={hideIcon}  alt=''/>
          </button>) : (<button 
            className=''
            onClick={async () => {
              try {
                let newRotation = []
                if(playlist.deviceRotation) {
                  newRotation = [...playlist.deviceRotation, displayIndex];
                }
                else {
                  newRotation = [displayIndex];
                }
                dispatch(setNewRotationPlaylist(newRotation));
              } catch (err) {
                console.log(err)
              }
            }}
          >
            <img className='w-[25px]' src={Activate} alt='activate/deactivate display' />
          </button>)}
         
          <button
            className=''
            onClick={() => {
              navigate(`/editDisplayToPlaylist/${displayIndex}`)
            }}
          >
            <img className='w-[25px]' src={Edit} alt='' />
          </button>
          
          <button 
            className=''
            onClick={() => {
              setShowDelete(true);
            }}
          >
            <img className='w-[25px]' src={Delete} alt='' />
          </button>
        </div>
      </div>
      <div className={`items-center flex flex-row xl:flex-row justify-between gap-2  ${playlist?.deviceRotation?.includes(displayIndex) && "border-b-[1px] pb-4"} w-[90%]`}>
        <h2 className='text-black font-medium text-lg xl:text-xl'>{t('displayStatus')}</h2>
        <h2 className={`font-semibold text-lg xl:text-xl ${playlist?.deviceRotation?.includes(displayIndex) ? "text-green-500" : "text-gray-400"}`}>
          {playlist?.deviceRotation?.includes(displayIndex) ? t('active'): t('notActive')}
          </h2>
      </div>
      {barcodes && <div className={`w-[89%] items-start justify-start flex flex-col ${barcodes.length > 1 && "border-b-[1px]"}`}>
        <h2 className='text-black font-bold'>
          {i18n.t("content")}:
        </h2>
        { barcodes.map(barcode => <h2 className='font-medium text-lg'>{barcode}</h2>)}
      </div>}
      {playlist?.deviceRotation?.includes(displayIndex) &&
       <div className='w-[90%] flex flex-row justify-center items-center gap-2  p-1'>
        <input 
          type='checkBox'
          checked={showDate}
          onChange={async (e) => {
            try {
              let displayDates = [...playlist.displayDates]
              displayDates[displayIndex] = {
                start: date.startDate,
                end: date.endDate,
                checkDate: !showDate
              };
              dispatch(setNewDisplayDatesPlaylist(displayDates));
              setShowDate(!showDate)
            } catch (err) {
              console.log(err)
            }
          }}
        />
        <h2 className='text-black font-bold text-md'>{t("setShowDate")}</h2>
      </div>}
      {showDate && <div className=' flex flex-row justify-between items-center w-[80%]'>
      </div>}
      {playlist?.deviceRotation?.includes(displayIndex) && 
      <div className='items-center flex flex-row xl:flex-row justify-center gap-2 pb-2 w-[80%] mt-[-25px]'>
        <div className='flex flex-col justify-start items-center gap-2 w-[50%] p-2'>
         {showDate &&  <div className='flex flex-col justify-start items-center'>
            <h2 className='text-black m-2'>{t("startDate")}</h2>
            <DatePicker
              selected={date.startDate}
              onChange={async (newDate) => {
                try {
                  let displayDates = [...playlist.displayDates]
                  displayDates[displayIndex] = {
                    start: newDate,
                    end: date.endDate,
                    checkDate: showDate
                  };
                  dispatch(setNewDisplayDatesPlaylist(displayDates));
                  setDate((date) => ({...date, startDate:newDate}))
                } catch (err) {
                  console.log(err)
                }
              }}
              className='p-2 border-[#2D5186] border-[1px]  focus:border-[#2D5186] focus:outline-none w-[100%]'
            />
          </div>}
          <h2 className='font-medium text-md'>{t("startHour")}</h2>
          <div className='flex  gap-2 border-[1px] border-[#2D5186] p-1'>
            <button
              className=''
              disabled={time.start <= 0}
              onClick={async () => onChangeTime(time.start - 1, time.end)}
            >
              <img src={Down} width={30}/>
            </button>
            <h2>{time.start}:00</h2>
            <button
              className=''
              disabled={time.start >= 24}
              onClick={async () => onChangeTime(time.start + 1, time.end)}
            >
              <img src={Up} width={30}/>
            </button>
          </div>
        </div>
        <div className='flex flex-col justify-start items-center gap-2 w-[50%] p-2'>
        {showDate && <div className='flex flex-col justify-start items-center '>
            <h2 className='text-black m-2'>{t("endDate")}</h2>
            <DatePicker
              selected={date.endDate}
              onChange={async (newDate) => {
                try {
                  let displayDates = [...playlist.displayDates]
                  displayDates[displayIndex] = {
                    start: date.startDate,
                    end: newDate,
                    checkDate: showDate
                  };
                  dispatch(setNewDisplayDatesPlaylist(displayDates));
                  setDate((date) => ({...date, endDate:newDate}))
                } catch (err) {
                  console.log(err)
                }
              }}
              className='p-2 border-[#2D5186] border-[1px]  focus:border-[#2D5186] focus:outline-none w-[100%]'
            />
          </div>}
          <h2 className='font-medium text-md'>{t("endHour")}</h2>
          <div className='flex gap-2 border-[1px] p-1 border-[#2D5186]'>
            <button
              className=''
              disabled={time.end <= 0}
              onClick={async () => onChangeTime(time.start, time.end -1)}
            >
              <img src={Down} width={30}/>
            </button>
            <h2>{time.end}:00</h2>
            <button
              className=''
              disabled={time.end >= 24}
              onClick={async () => onChangeTime(time.start, time.end +1)}
            >
              <img src={Up} width={30}/>
            </button>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default PlayListDisplayItem