import axios from "axios";

export const scanEslApi = async (token, img) => {
  const res = await axios.post(`https://backend.konekt.vip/camera/esl`,
    {
      img
    },
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const scanBarcodeApi = async (token, img) => {
  const res = await axios.post(`https://backend.konekt.vip/camera/barcode`,
    {
      img
    },
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const bindEsl = async (token, data) => {
  const res = await axios.post('https://backend.konekt.vip/zkong/bindEsl',
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );

  return res.data;
}

