import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Edit from '../../static/stylus.png';
import { useDispatch } from 'react-redux';
import { setTemplateState } from '../../store';


const ViewTemplate = ({template, selectTemplate}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <div className='flex flex-col justify-center items-center bg-white p-5 gap-3 shadow-md  border-2'>
      <div className='flex flex-col justify-center items-center gap-1'>
        <h2 className='text-black font-bold text-2xl'>{template.type}</h2>
        {/* <h2  className='text-black font-bold '>{t("structure")}: {template.structure}</h2> */}
        <h2 className='font-bold text-2xl'>Size: {template.size}</h2>
      </div>
      <div className='flex justify-center items-center gap-1'>
          <button 
            className=''
            onClick={() => {
              selectTemplate(template._id);
              dispatch(setTemplateState({...template, action: "edit"}));
              // navigate('/createViewTemplate')
            }}  
          >
            <img  src={Edit} width={24} alt=''/>
          </button>
      </div>
    </div>
  )
}

export default ViewTemplate