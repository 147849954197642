import React, { useEffect, useState } from 'react'
import Header from '../components/layout/Header'
import { useDispatch, useSelector } from 'react-redux';
import { getAndroidKeys, refreshAndroidDevice, updateAndroidControls } from '../api/AndroidKeyApi';
import AndroidKey from '../components/androidKey/AndroidKey';
import AndroidKeyBox from '../components/androidKey/AndroidKeyBox'
import AddAndroidKeyModal from '../components/androidKey/AddAndroidKeyModal';
import UnbindAndroidKeyModal from '../components/androidKey/UnbindAndroidKeyModal';
import { removeAndroidbind } from '../api/AndroidKeyApi';
import Loading from '../modals/Loading';
import { setLoadingState } from '../store';
import EditAndroidKeyModal from '../components/androidKey/EditAndroidKeyModal';
import DownArrowLogo from '../static/down-arrow.png';
import UpArrowLogo from '../static/upArrow.png';
import AddIcon from '../static/updatePlaylist.png';
import deleteIcon from '../static/delete.png';
import RefreshLogo from '../static/refresh.png';
import ListIcon from '../static/cells.png';
import CellsIcon from '../static/menu.png';
import MessageIcon from '../static/email.png';
import SearchIcon from '../static/search_black.png';
import DeviceBarcodesModal from '../modals/Device/DeviceBarcodesModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getStoreDetails } from '../api/storesApi';
import AndroidKeyBoxMain from '../components/androidKey/AndroidKeyBoxMain';
import AndroidDeviceGroupModal from '../components/androidKey/AndroidDeviceGroupModal';

const AndroidKeyPage = () => {
  const [keys, setKeys] = useState([]);
  const [store, setStore] = useState(null);
  const [macGroup, setMacGroup] = useState({}); // this is only a grouping for when you're selecting devices in a list. Not the device's actual group(s)
  // Code comments - the universal warning sign that you fucked up somewhere
  const [deleteCode, setDeleteCode] = useState("");
  const [showThumbnails, setShowThumbnails] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [groupModal, setGroupModal] = useState({
    show: false,
    androidKey: null
  });
  const selectAndroidGroup = (androidKey) => {
    setGroupModal({
      show:true,
      androidKey: androidKey
    });
  }

  const [pagination, setPagination] = useState({
    limit: 4,
    showMore: false
  })
  const [androidKeyToUpdate, setAndroidKeyToUpdate] = useState({
    deviceId:"",
    storeId:"",
    name:"",
    code:"",
    show:false
  });

  // This is a dogshit solution...
  const [searchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState("");

  const [sortParams, setSortParams] = useState({
    sortActive: "desc"
  });

  const setUpdateAndroidKey = (androidKey) => {
    setAndroidKeyToUpdate({
      deviceId:androidKey.deviceId,
      storeId:androidKey.storeId,
      name:androidKey.name,
      code:androidKey.code,
      show:true
    })
  }
  const closeUpdateAndroidKey = () => {
    setAndroidKeyToUpdate({
      deviceId:"",
      storeId:"",
      name:"",
      code:"",
      show:false
    });
  }

  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const loading = useSelector(state => state.loading.loading);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const deviceItemsModal = useSelector(state => state.deviceItemsModal);

  
  const fetchData = async (limit) => {
    try {
      const currSortParams = {...sortParams};
      if(searchTerm) {
        currSortParams.searchTerm = searchTerm;
      }
      currSortParams.limit = limit;
      currSortParams.storeId = selectedStore;
      const res = await getAndroidKeys(user.token, currSortParams);
      setKeys(res.keys);
      setPagination(pagination => ({...pagination, showMore: res.showMore}))
    } catch (err) {
      console.log(err);
    }
  }

  const addRemoveToList = async (mac, code) => {
    if(macGroup[mac] || macGroup[mac] !== null) {
     setMacGroup(macGroup => ({...macGroup, [mac]:null}))
    }
    if(!macGroup[mac]) {
      setMacGroup(macGroup => ({...macGroup, [mac]:code}))
    }
  }

  const unBindSelected = async () => {
    let toUnbind = Object.keys(macGroup).map(key => {
      if(macGroup[key] !== null) {
        return macGroup[key]
      }
    })
    toUnbind = toUnbind.filter(unbind => unbind != null);
    let promises = toUnbind.map(unbind => removeAndroidbind(user.token, unbind))
    try {
      await Promise.all(promises);
      fetchData(pagination.limit);
      setMacGroup({});
    } catch (err) {
      console.log(err)
    }
  }

  const refershSelected = async () => {
    dispatch(setLoadingState(true))
    let toRefresh = Object.keys(macGroup).map(key => {
      if(macGroup[key] !== null) {
        return macGroup[key]
      }
    })
    toRefresh = toRefresh.filter(mac => mac != null);
    let promises = toRefresh.map(mac => refreshAndroidDevice(user.token, mac))
    try {
      await Promise.all(promises);
      fetchData(pagination.limit);
      setMacGroup({});
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setLoadingState(false));
    }
  }

  const getStoreData = async () => {
    const res = await getStoreDetails(user.token, selectedStore);
    setStore(res);
  };

  useEffect(() => {
    if(!showAddModal && !showDeleteModal && !androidKeyToUpdate.show) {
      fetchData(pagination.limit);
      getStoreData();
      const intId = setInterval(async () => {
        fetchData(pagination.limit);
        getStoreData();
      }, 5000);

      return () => {
        clearInterval(intId);
      }
    }  
  }, [user, selectedStore, showAddModal, showDeleteModal, groupModal.show ,androidKeyToUpdate, sortParams, searchTerm, pagination.limit])

  return (
   <>
    <Header showBack={true} backTitle={""}/>
    {loading && <Loading/>}
    {deviceItemsModal.showModal && <DeviceBarcodesModal/>}
    {showAddModal && <AddAndroidKeyModal setShowModal={setShowAddModal}/>}
    {showDeleteModal && <UnbindAndroidKeyModal setShowModal={setShowDeleteModal} code={deleteCode}/>}
    {androidKeyToUpdate.show && <EditAndroidKeyModal androidKey={androidKeyToUpdate} closeModal={closeUpdateAndroidKey}/>}
    {groupModal.show && <AndroidDeviceGroupModal androidKey={groupModal.androidKey} setShowModal={setGroupModal}/>}
    <div className='w-full h-full flex flex-col items-center overflow-scroll mb-16 md:mb-0'>
      <div className='w-full flex gap-2 justify-end p-2'>
        <button>
          <img 
            src={ListIcon}
            onClick={() => setShowThumbnails(false)}
            className='w-4'
          />
        </button>
        <button>
          <img 
            src={CellsIcon}
            onClick={() => setShowThumbnails(true)}
            className='w-4'
          />
        </button>
      </div>
      <div className='w-full flex justify-center items-center p-2 gap-2'>
        
        <h2 className='font-bold text-lg'>Search Devices:</h2>
        <input
          type='text'
          value={searchText}
          className='p-1 rounded-md border-[#2D5186] border-2 focus:outline-none'
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <button
          onClick={() => {
            setSearchTerm(searchText);
          }}
        >
          <img
            src={SearchIcon}
            className='w-8'
          />
        </button>
      </div>
      <div className='w-[90%] flex flex-row items-center justify-center gap-5 mt-2'>
        <button
          onClick={() => setShowAddModal(true)} 
        >
          <img src={AddIcon} className='w-8'/>
        </button>
        {Object.values(macGroup).filter(val => val !== null).length > 0 &&  <>
          <button
            onClick={refershSelected}
          >
            <img src={RefreshLogo} className='w-8'/>
          </button>
          <button
            onClick={unBindSelected}
          >
            <img src={deleteIcon} className='w-8'/>
          </button>
        </>}
      </div>
      {store && !isNaN(store?.deviceLimit) && !isNaN(store?.deviceUsage) && <div className='w-full flex items-start justify-start p-2'>
          <div className='w-[50%] flex flex-col justify-start items-start'>
            <h2 className='text-black font-bold text-lg'>Used Devices: {store.deviceUsage}</h2>
            <h2 className='text-black font-bold text-lg'>Total devices Allowed: {store.deviceLimit}</h2>

            <div className='w-full flex flex-row justify-start items-center  gap-2'>
              <h2 className='text-black font-bold text-lg'>Avaliable Space:</h2>
              <progress value={ store.deviceUsage /store.deviceLimit } className='p-2'/>
            </div>
          </div>
      </div>}
    
      <div className='w-full flex flex-col justify-center items-center gap-2 p-2 md:hidden'>
        {keys && keys.length > 0 && keys.map(key => <AndroidKeyBox 
          androidKey={key}
          key={key._id}
          setUnbind={(code) => {
            setDeleteCode(code);
            setShowDeleteModal(true);
          }}
          setChangePlaylistId={setUpdateAndroidKey}
          addRemoveToList={addRemoveToList}
          selectAndroidGroup={selectAndroidGroup}
        />)}
      </div>
      {showThumbnails && <div className='hidden md:grid grid-cols-4 gap-2'>
        {keys && keys.length > 0 && keys.map(andKey => <AndroidKeyBoxMain 
          androidKey={andKey}
          key={andKey._id}
          setUnbind={(code) => {
            setDeleteCode(code);
            setShowDeleteModal(true);
          }}
          setChangePlaylistId={setUpdateAndroidKey}
          addRemoveToList={addRemoveToList}
          selectAndroidGroup={selectAndroidGroup}
        />)}
      </div>}
      {!showThumbnails && <table className="w-full items-center hidden md:table">
        <thead className="text-sm md:text-[16px] text-slate-700 font-bold border-b-2  border-gray-300">
          <tr>
            {<th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer" 
              onClick={() => {
                if(sortParams.sortMac === "desc") {
                  return setSortParams({
                    sortMac:"asc"
                  })
                }
                setSortParams({
                  sortMac: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>Mac</h2>
                {sortParams.sortMac !== "desc"
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div>
            </th>}
            <th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer" 
              onClick={() => {
                if(sortParams.sortStore === "desc") {
                  return setSortParams({
                    sortStore:"asc"
                  })
                }
                setSortParams({
                  sortStore: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>StoreId</h2>
                {sortParams.sortStore !== "desc"
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div>

            </th>
            <th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer" 
              onClick={() => {
                if(sortParams.sortName === "desc") {
                  return setSortParams({
                    sortName:"asc"
                  })
                }
                setSortParams({
                  sortName: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>Name</h2>
                {sortParams.sortName !== "desc"
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div>
            </th>
            <th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer" 
              onClick={() => {
                if(sortParams.sortCode === "desc") {
                  return setSortParams({
                    sortCode:"asc"
                  })
                }
                setSortParams({
                  sortCode: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>Code</h2>
                {sortParams.sortCode !== "desc"
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div>
            </th>
            <th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer " 
              onClick={() => {
                if(sortParams.sortPlaylist === "desc") {
                  return setSortParams({
                    sortPlaylist:"asc"
                  })
                }
                setSortParams({
                  sortPlaylist: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>Playlist</h2>
                {sortParams.sortPlaylist !== "desc"
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div>
            </th>
            <th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer" 
              onClick={() => {
                if(sortParams.sortActive === "desc") {
                  return setSortParams({
                    sortActive:"asc"
                  })
                }
                setSortParams({
                  sortActive: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>Online</h2>
                {sortParams.sortActive !== "desc" || sortParams.sortLastActive
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div>
            </th>
            <th 
              scope="col" 
              className="px-6 py-4 hover:cursor-pointer "
              onClick={() => {
                if(sortParams.sortLastActive === "desc") {
                  return setSortParams({
                    sortActive: "asc",
                    sortLastActive: "asc"
                  })
                }
                setSortParams({
                  sortActive: "desc",
                  sortLastActive: "desc"
                })
              }}
            >
              <div className='flex items-center justify-center'>
                <h2>Last Active</h2>
                {sortParams.sortLastActive !== "desc"
                  ? <img src={DownArrowLogo} className='mx-2 w-5'/> 
                  : <img src={UpArrowLogo} className='mx-2 w-5'/>
                }
              </div> 
            </th>
            <th scope="col" className="px-6 py-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys && keys.length > 0 && keys.map(key => <AndroidKey 
            androidKey={key}
            key={key._id}
            setUnbind={(code) => {
              setDeleteCode(code);
              setShowDeleteModal(true);
            }}
            addRemoveToList={addRemoveToList}
            checked={!!macGroup[key.mac]}
            setChangePlaylistId={setUpdateAndroidKey}
            selectAndroidGroup={selectAndroidGroup}
          />)}

        </tbody>
      </table>}
      <button 
        disabled={pagination.showMore}
        className='btn mt-2 hover:bg-blue-400 transition-all disabled:opacity-50'
        onClick={async () => {
          setPagination(pagination => ({...pagination, limit: pagination.limit + 4}));
        }}
      >
        Load More
      </button>
    </div>
   </>
  )
}

export default AndroidKeyPage