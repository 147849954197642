import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getDevices } from '../../api/deviceApi';
import Select from 'react-tailwindcss-select'
import { useTranslation } from 'react-i18next';
import { bindLicense } from '../../api/licenseApi';

const EditLicense = ({licenseId, deviceId, setShowModal, setDeviceEmit}) => {
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState({label: deviceId});
  const {t} = useTranslation()
  useEffect(() => {
    getDevices(user.token, url, storeSelection)
      .then(res => setDevices(res))
      .catch(err => console.log(err));  
  }, []);

  return (
      <div>
        <div className='modal'>
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-9 justify-center items-center">
               {/*header*/}
              <div className="flex items-center justify-center border-b border-solid border-slate-200 rounded-t p-9">
                <h3 className="text-2xl font-bold text-black">
                  {t("editLicenseProperties")}
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-3 flex-auto">
                <form className="flex flex-col gap-4">
                  <div className="flex flex-col justify-center items-center text-center gap-2">
                    <label
                      className="block text-2xl text-black font-bold"
                    >
                      {t("deviceProfiles")}
                    </label>
                    <Select
                      options={
                        devices.map(device => 
                          ({value:device.deviceId, label:device.deviceId}))
                      }
                      value={device}
                      onChange={(selection)=> setDevice(selection)}
                      classNames={{
                        menu:"absolute w-[250px] h-[250px]  bg-white border-2 border-black overflow-scroll",
                        menuButton:() => "flex flex-row w-[250px] bg-white border-2 border-black px-2 justify-between",
                        listItem: ({ isSelected }) => (
                          `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                              isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                          }`
                        )
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="flex items-center justify-center p-6 border-slate-200 rounded-b">
                <button
                  className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-400"
                  type="button"
                  onClick={async () => {
                    await bindLicense(user.token, {
                      deviceId: device.label,
                      webKey: licenseId
                    })
                    setDeviceEmit(device.label);
                    setShowModal(false)
                  }}
                >
                  {t("submit")}
                </button>
              </div>
              <div className="flex items-center justify-center p-6 border-slate-200 rounded-b">
                <button
                  className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditLicense