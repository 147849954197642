import axios from "axios";

export const getPromoByStore = async (token, params) => {

  const result = await axios.get(
    `https://backend.konekt.vip/Promos/promobystore`,
    {
      headers:{
        token: `bearer ${token}`
      },
      params:params
    }
  );
  return result.data;
}

export const updatePromoSettings = async (token, data) => {
  const result = await axios.post(
    `https://backend.konekt.vip/Promos`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      },
    }
  );
  return result.data;
}
