import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Device from '../../static/devices.png';
import Content from '../../static/content-writing.png';
import Material from '../../static/digital-content.png';
import Groups from '../../static/product-management.png';
import Licenses from '../../static/software-license.png';
import Display from '../../static/developer_mode_tv.png';
import Template from '../../static/data_table.png';
import Printer from '../../static/printer.png';
import PlayList from '../../static/playlistB.png';
import Dashboard from '../../static/dashboard.png';
import DisplaySettings from '../../static/Dsettings.png';
import TemplateSettings from '../../static/layout.png';
import SizeSettings from '../../static/measurement.png';
import Alerts from '../../static/risk-management.png';
import Promotion from '../../static/promotion.png';
import Trail from '../../static/server.png';
import User from '../../static/userB.png';
import Store from '../../static/retailer.png';
import Category from '../../static/categories.png';
import VersionManagment from '../../static/version-control.png';
import VersionUpload from '../../static/product-development.png';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {t} = useTranslation()
  return (
    <div className="border-r-2 h-screen hidden md:flex overflow-scroll">
    <div className="flex flex-col p-3 bg-white  w-20 md:w-80 h-screen border">
      <div className="flex flex-col justify-center items-center space-y-3 mt-5">
        <div className="flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
          <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Main</h2>
        </div>
        <div className="flex flex-col justify-center items-center text-gray-400">
          <ul className="pt-2 pb-2 space-y-1 text-sm justify-start">
            
            <li className="rounded-sm">
              <Link
                to="/androidKeys"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Device} width={30}/>
                <span className="hidden md:block font-bold">{t("androidDevices")}</span>
              </Link>
            </li>
            <li className="rounded-sm">
              <Link
                to="/"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={PlayList} width={30}/>
                <span className="hidden md:block font-bold">{t("deviceProfiles")}</span>
              </Link>
            </li>
            <li className="rounded-sm">
              <Link
                to="/products"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Content} width={30}/>
                <span className="hidden md:block font-bold">{t("products")}</span>
              </Link>
            </li>
            <li className="rounded-sm">
              <Link
                to="/uploads"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Material} width={30}/>
                <span className="hidden md:block font-bold">Material Management</span>
              </Link>
            </li>
            {user.superAdmin && <li className="rounded-sm">
              <Link
                to="/groupList"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Groups} width={30}/>
                <span className="hidden md:block font-bold">Group List</span>
              </Link>
            </li>}
            {user.superAdmin && <li className="rounded-sm">
              <Link
                to="/generalDashboard"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Dashboard} width={30}/>
                <span className="hidden md:block font-bold">Dashboard</span>
              </Link>
            </li>}
            
            
            
            
            
          </ul>
        </div>
      </div>
      <div className="p-2 flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
        <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Design</h2>
      </div>
      <div className="flex flex-col justify-start items-start text-gray-400">
        <ul className="pl-4 mt-2 flex flex-col w-full text-sm  items-start">
            <li className="rounded-sm">
              <Link
                to="/displays"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={DisplaySettings} width={30}/>
                <span className="hidden md:block font-bold">Display Editor</span>
              </Link>
            </li>
            <li className="rounded-sm">
              <Link
                to="/ViewTemplates"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={TemplateSettings} width={30}/>
                <span className="hidden md:block font-bold">Template Editor</span>
              </Link>
            </li>
            <li className="rounded-sm">
              <Link
                to="/TemplateSizes"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={SizeSettings} width={30}/>
                <span className="hidden md:block font-bold">Template Size Management</span>
              </Link>
            </li>
        </ul>
      </div>
      <div className="p-2 flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
        <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Monitor</h2>
      </div>
      <div className="flex flex-col justify-start items-start text-gray-400">
        <ul className="pl-4 mt-2 flex flex-col w-full text-sm  items-start">    
          <li className="rounded-sm">
            <Link
              to="/alertSettings"
              className="flex items-center p-2 space-x-3 rounded-md"
            >
              <img src={Alerts} width={30}/>
              <span className="hidden md:block font-bold">
                Alerts
              </span>
            </Link>
          </li>
          {user.omniAdmin && <li className="rounded-sm">
            <Link
              to="/trail"
              className="flex items-center p-2 space-x-3 rounded-md"
            >
              <img src={Trail} width={30}/>
              <span className="hidden md:block font-bold">Audit Trail</span>
            </Link>
          </li>}
        </ul>
      </div>
      <div className="p-2 flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
        <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Print</h2>
      </div>
      <div className="flex flex-col justify-start items-start text-gray-400">
        <ul className="pl-4 mt-2 flex flex-col w-full text-sm  items-start">    
          <li className="rounded-sm">
            <Link
              to="/templates"
              className="flex items-center p-2 space-x-3 rounded-md"
            >
              <img src={TemplateSettings} width={30}/>
              <span className="hidden md:block font-bold">{t("templates")}</span>
            </Link>
          </li>
          <li className="rounded-sm">
            <Link
              to="/print"
              className="flex items-center p-2 space-x-3 rounded-md"
            >
              <img src={Printer} width={30}/>
              <span className="hidden md:block font-bold">{t("printer")}</span>
            </Link>
          </li>
        </ul>
      </div>
    
      
      
      {<>
        <div className="p-2 flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
          <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Admin Settings</h2>
        </div>
        <div className="flex flex-col justify-start items-start text-gray-400">
          <ul className="pl-4 mt-2 flex flex-col w-full text-sm  items-start">
            <li className="rounded-sm">
              <Link
                to="/promoSettings"
                className="flex items-center p-2 space-x-3 rounded-md"
                 >
                <img src={Promotion} width={30}/>
                <span className="hidden md:block font-bold">Promotion Settings</span>
              </Link>
            </li>
            {user.omniAdmin && <li className="rounded-sm">
              <Link
                to="/adminManagement"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={User} width={30}/>
                <span className="hidden md:block font-bold">User Management</span>
              </Link>
            </li>}
            <li className="rounded-sm">
              <Link
                to="/licenses"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Licenses} width={30}/>
                <span className="hidden md:block font-bold">{t("licenses")}</span>
              </Link>
            </li>
            {user.omniAdmin && <li className="rounded-sm">
              <Link
                to="/stores"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Store} width={30}/>
                <span className="hidden md:block font-bold">Stores Management</span>
              </Link>
            </li>}
            
            {user.omniAdmin && <li className="rounded-sm">
              <Link
                to="/category"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={Category} width={30}/>
                <span className="hidden md:block font-bold">Category Management</span>
              </Link>
            </li>}
            
          </ul>
        </div>
      </>}
      {user.omniAdmin && <>
        <div className="p-2 flex flex-row items-center justify-start space-x-2 space-y-1 border-b-2 w-[90%] pb-4 border-gray-300  ">
          <h2 className="text-sm md:text-[16px] text-slate-700 font-bold">Version Control</h2>
        </div>
        <div className="flex flex-col justify-start items-start text-gray-400">
          <ul className="pl-4 mt-2 flex flex-col w-full text-sm  items-start mb-10">
            <li className="rounded-sm">
              <Link
                to="/version"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={VersionManagment} width={30}/>
                <span className="hidden md:block font-bold">Management</span>
              </Link>
            </li>
            <li className="rounded-sm">
              <Link
                to="/updateVersions"
                className="flex items-center p-2 space-x-3 rounded-md"
              >
                <img src={VersionUpload} width={30}/>
                <span className="hidden md:block font-bold">Upload</span>
              </Link>
            </li>
            
          </ul>
        </div>
      </>}
    </div>
  </div>
  )
}

export default Sidebar