import React, { useEffect, useState } from 'react'
import Header from '../components/layout/Header'
import { addCategory, getCategories } from '../api/categoryApi';
import { useSelector } from 'react-redux';

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  const user = useSelector(state => state.user);

  const fetchCategories = async () => {
    try {
      const data = await getCategories(user.token);
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchCategories();
  }, []);
  console.log(categories)
  return (
    <>
      <Header showBack={true} backTitle={""}/>
      <div className='w-full h-full flex flex-col items-center overflow-scroll mb-16 md:mb-0 gap-5 p-5'>
        <h2 className='font-bold text-4xl'>Category Management</h2>
        <div className='flex flex-col h-[40%] overflow-scroll w-[40%] bg-gray-200 rounded-xl border-4 gap-2 border-black p-2'>
          {categories.map((cat) => <div className='w-full p-2 bg-gray-100 flex items-center justify-center font-bold text-2xl  rounded-lg border-b-2 border-gray-300'>
             {cat.category}
          </div>)}
        </div>
        <div className='w-full flex flex-col items-center gap-2'>
          <h2 className='font-bold text-2xl'>add Category</h2>
          <input
            type='text'
            className='w-[40%] p-2 font-semibold bg-gray-100 rounded-lg border-2 border-black'
            onChange={(e) => {
              setNewCategory(e.target.value);
            }}
            value={newCategory}
          />
          <button className='btn'
            onClick={async () => {
              try {
                await addCategory(user.token, {category:newCategory});
                setCategories(cats => {
                  const catToAdd = newCategory;
                  setNewCategory("");
                  debugger;
                  console.log(catToAdd)
                  return [...cats, {category: catToAdd}]
                });
              } catch (err) {
                console.log(err)
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  )
}

export default CategoryPage