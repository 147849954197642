import React, { useEffect, useState } from 'react'
import { createUpdateGroupListCall, getGroupListCall } from '../api/GroupListApi';
import { useSelector } from 'react-redux';
import Header from '../components/layout/Header';
import OperationSuccesfulModal from '../components/layout/OperationSuccesfulModal';
import { Link } from 'react-router-dom';

const GroupListPage = () => {
  const [groupList, setGroupList] = useState([]);
  const [groupToAdd, setGroupToAdd] = useState("");
  const [storeNoCategory, setStoreNoCategory] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);

  const fetchGroupList = async () => {
    try {
      const data = await getGroupListCall(user.token,{storeId: storeSelection});
      if(data?.list) {
        setGroupList(data.list);
      }
      else {
        setGroupList([])
      }
    } catch (err) {
      if(err.response.data.msg == "Category is missing") {
        setStoreNoCategory(true);
      }
      console.log(err);
    }
  }

  useEffect(() => {
    fetchGroupList();
  }, [storeSelection]);

  const addGroup = (group) => {
    setGroupList(grps => {
      if(group && grps.findIndex(grp => grp == group) == -1) {
        return [...grps, group];
      }
      return grps;
    });
    setGroupToAdd("");
  }

  const removeGroup = (group) => {
    setGroupList(grps => {
      let newGrps = [...grps].filter(grp => grp != group);
      return newGrps;
    });
  }

  const updateGroup = async () => {
    try {
      await createUpdateGroupListCall(user.token, 
        {
          list: groupList,
          storeId: storeSelection
        }
      )
      setShowSuccessMessage(true);
      return setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Header/>
      {showSuccessMessage && <OperationSuccesfulModal/>}
      <div 
        className='h-screen w-full bg-white flex flex-col items-center 
        overflow-y-scroll pb-[100px]'
      >
        {storeNoCategory && <>
          <div className='w-full p-5 gap-5 items-center flex flex-col justify-center h-full'>
            <h2 className='font-extrabold text-5xl text-black'>
              You're Not allowed to add groups to stores without a category
            </h2>
            <Link to='/'>
              <button className='btn'>
                Back to Main Menu
              </button>
            </Link>
          </div>
        </>}
        {!storeNoCategory && <>
          <h2 className='text-black font-bold text-3xl p-2'>Category Groups</h2>
          <div className='w-[70%] flex flex-col p-4 gap-2 items-center'>
            {groupList && groupList.length > 0 && <div className='w-[60%] grid grid-cols-4 gap-2 items-center bg-gray-100 border-2 border-gray-200 p-2 rounded-md'>
              {groupList.map(grp => <div className='w-full flex items-center bg-gray-300 rounded-lg justify-between p-2'>
                  <h2 className='text-black font-bold text-2xl'>{grp}</h2>
                  <h2 
                    className='text-black font-extrabold text-2xl hover:text-gray-800 cursor-pointer'
                    onClick={() => {
                      removeGroup(grp);
                    }}
                  >
                    X
                  </h2>

              </div>)}
            </div>}
            {groupList.length == 0 && <h2 className='text-black font-bold text-3xl'>No Groups Found, Add one to create list</h2>}
            <input
              className='rounded-md border-2 border-gray-500 p-2 w-[25%]'
              onChange={(e) => setGroupToAdd(e.target.value)}
              value={groupToAdd}
              defaultValue={groupToAdd}
            />
            <button
              className='btn text-xl hover:bg-blue-500'
              onClick={() => {
                if(groupToAdd) {
                  addGroup(groupToAdd);
                }
              }}
            >
              add Group
            </button>
          </div>
          <button 
            className='p-2 text-white font-bold bg-[#2D5CC8] text-2xl rounded-lg hover:bg-blue-500'
            onClick={() => {
              updateGroup();
            }}
          >
              Save List
          </button>
        </>}
      </div>
    </>
  )
}

export default GroupListPage
