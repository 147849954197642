import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { removePlaylistAndroidDevice } from '../../api/AndroidKeyApi';


const UnbindAndroidKeyModal = ({setShowModal, code}) => {
  const {t} = useTranslation();
  const user = useSelector(state=> state.user);
  const onDelete = async () => {
    try {
     await removePlaylistAndroidDevice(user.token,{code});
     setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
    <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="flex flex-col justify-start items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%]">
              <div className="flex felx-col items-center justify-center p-4 rounded-t">
                  <h3 className="text-md md:text-xl font-semibold text-gray-900 dark:text-white">
                    {t("areyousureyouwantounbindthisDevice")}
                  </h3>
              </div>
              <div className="flex flex-row justify-center gap-10 p-10 w-[100%]">
                <button
                  className='btn text-xl !p-3'
                  onClick={() => onDelete()}
                  >
                  {t("yes")}
                </button>
                <button
                  className='btn text-xl !p-3'
                  onClick={() => setShowModal(false)}
                >
                  {t("no")}
                </button>
              </div>
          </div>      
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
  )
}

export default UnbindAndroidKeyModal