import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { previewTemplate } from '../api/templateApi'
import QRCode from 'react-qr-code'
import { getItem } from '../api/itemsApi'

const RenderTemplateHalf =forwardRef(({storeId, barcode, structure, name}, ref) => {
  const user = useSelector(state => state.user);
  const [item, setItem] = useState({});
  useEffect(() => {
    getItem(
        user.token,
        "",
        barcode,
        storeId
       
    )
    .then(res => setItem(res))
    .catch(err => console.log(err));
  }, [])
  if(!item) {
    return <div>Empty</div>
  }
  return <div className='w-full h-full flex flex-col items-start'   ref={ref}>
    <div className='w-full flex justify-end'>
      <div className='flex  gap-2 bg-[#3fab35]'>
        <h2 className='text-white' style={{fontFamily:"RagSans-regular"}}>{item.custFeature11}</h2>
        <h2 className='text-white' style={{fontFamily:"RagSans-regular"}}>: מספר מבצע</h2>
      </div>
    </div>
    <div className='w-full p-5 flex flex-row justify-center items-center'>
      <h2 style={{fontFamily:"RagSans-regular"}} className='text-[45px] text-green-800'>{item.name}</h2>
    </div>
    <div className=' bg-[#ffd000] flex flex-col items-center h-full w-full justify-center mb-[2%]'>
      <div className='flex w-full justify-center'>
        <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-[50px] mt-[50px] text-green-800'>
        ₪
        </h2>
        <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-[100px] text-green-800'>
          {item.price}
        </h2>
      </div>
      <div className='w-full flex flex-row justify-end'>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600 border-x-[5px] border-green-700'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            מחיר לפני הנחה
          </h2>
        </div>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600 border-x-[5px]  border-green-700'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            שווי ליחידה אחרי הנחה
          </h2>
        </div>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600 border-x-[5px]  border-green-700'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            מחיל לפני ל-יח
          </h2>
        </div>
      </div>
      <div className='flex w-full  bg-[#ffd000] justify-end'>
        <div className='align-bottom mr-[123px]  flex flex-row justify-center px-2 bg-green-600  border-green-700 border-x-[5px]'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            {item.originalPrice}
          </h2>
        </div>
        <div className='align-bottom mr-[70px] flex flex-row justify-center px-2 bg-green-600  border-green-700 border-x-[5px]'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            {item.originalPrice}
          </h2>
        </div>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600  border-green-700 border-x-[5px]'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            {item.originalPrice}
          </h2>
        </div>
      </div>
    </div>
    <div className='w-full flex justify-end mt-2 gap-1 pr-1'>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-md'>הזול/ים מבין כלל המוצרים שיירכשו על ידי הלקוח</h2>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-md'>,{item.custFeature12}</h2>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-md'>:מבצע בתוקף עד ה</h2>
    </div>
    <div dir='rtl' className='w-full flex justify-start mt-1 pr-1'>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-md'>
        הברקודים המשתתפים במבצע: {item.custFeature13} 
      </h2>
    </div>
  </div>
});

export default RenderTemplateHalf
