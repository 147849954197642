import axios from "axios";

export const getLicenses = async (token, url, storeId) => {
  const res = await axios.get(`https://backend.konekt.vip/webkey/getAllKeys?storeId=${storeId}`, {
    headers:{
      token: `bearer ${token}`
    }
  });
  return res.data;
}

export const bindLicense = async (token, data) => {
  const res = await axios.put(`https://backend.konekt.vip/webkey/pairDeviceId`,data, {
    headers:{
      token: `bearer ${token}`
    }
  });
  return res.data;
}