import React, { useState } from 'react'
import { addPlayListApi } from '../../../api/playlistApi';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AddPlaylistModal = ({setShowModal}) => {
  const [playlistId, setPlaylistId] = useState("");
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const {t} = useTranslation();
  const AddNewPlaylist = async () => {
    try {
      await addPlayListApi(
        user.token,
        {
          playListId: playlistId,
          stores: [],
          barcodes:[],
          displayIds:[],
          online: false,
          showDeviceId: false,
          displayDates: [],
          deviceRotation: [],
          displayTime: [],
          showUnBound: false,
          storeId: user.superAdmin && selectedStore ? selectedStore : user.storeId 
        }
      );
      setShowModal(false);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-start items-center w-full h-full max-w-2xl">
            <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%]">
                <div className="flex felx-col items-center justify-center p-4 rounded-t">
                  <h3 className=" font-bold text-gray-900 dark:text-white text-3xl">
                    {t("addPlaylist")}
                  </h3>
                </div>
                <div className="flex flex-col items-center justify-center gap-10 p-10 w-[100%]">
                  <input 
                    className='w-[70%] rounded-lg p-3 border-2 text-black font-bold'
                    type='text'
                    onChange={(e) => setPlaylistId(e.target.value)}
                  />
                  <button
                    className='bg-blue-400 rounded-lg text-white font-bold text-xl !p-3 w-full hover:bg-blue-300'
                    onClick={() => AddNewPlaylist()}
                    >
                    {t("submitPlaylist")}
                  </button>
                  <button
                    className='bg-blue-400 rounded-lg text-white font-bold text-xl !p-3 w-full hover:bg-blue-300'
                    onClick={() => setShowModal(false)}
                  >
                    {t("cancel")}
                  </button>
                </div>
            </div>      
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AddPlaylistModal