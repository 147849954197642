import React, { useEffect, useState } from 'react'
import AndroidRotateModal from './AndroidRotateModal'
import AndroidMessageModal from './AndroidMessageModal'
import AndroidDeviceDetailsModal from './AndroidDeviceDetailsModal'
import AndroidKeyControls from './AndroidKeyControls'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getScreenshot, refreshAndroidDevice } from '../../api/AndroidKeyApi';

import Delete from '../../static/delete.png';
import RefreshLogo from '../../static/refresh.png';
import GreenLogo from '../../static/green.png';
import RedLogo from '../../static/red.png';
import LoadingAnim from '../../static/loading.gif';
import playlistLogo from '../../static/playlistLogo.png';
import upldatePlaylistLogo from '../../static/updatePlaylist.png';
import ViewDetailsIcon from '../../static/view-details.png';
import PreviewIcon from '../../static/eye.png';
import ControlsIcon from '../../static/equalizer.png';
import MessageIcon from '../../static/email.png';
import RotationIcon from '../../static/rotate.png';
import KioskIcon from '../../static/kiosk.png';
import KioskModeModal from './KioskModeModal'
import { previewDevice } from '../../api/deviceApi'
import PlaylistElement from '../playlistElements/PlaylistElement'
import GroupIcon from '../../static/brackets.png';

const AndroidKeyBoxMain = ({androidKey, setUnbind, addRemoveToList, checked, setChangePlaylistId, selectAndroidGroup}) => {
  const [showControlsModal, setShowControlsModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showRotationModal, setShowRotationModal] = useState(false);
  const [showKioskModal, setShowKioskModal] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [preview, setPreview] = useState({
    type:"none",
    content:""
  });
  const [loading, setLoading] = useState(false);
  const [screenshotData, setScreenshotData] = useState("");
  const [keyControls, setKeyControls] = useState({
    volume: androidKey?.controls?.volume || 0,
    brightness: androidKey?.controls?.brightness || 0,
  });
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  
  const getPreviewData = async () => {
    try {
      
      const screenshotResponse = await getScreenshot(user.token, {mac: androidKey.mac});
      if(screenshotResponse.screenshot) {
        setScreenshotData("data:image/png;base64," + screenshotResponse.screenshot);
      }

      const prevData = await previewDevice(
        user.token,
        {deviceId: androidKey.deviceId}
      );
      if(!prevData || prevData.length  < 1) {
        return setPreview({type:"none"});
      }

      for(let i = 0; i < prevData.length; i++) {
        if(!prevData[i]) {
          console.log(i);
          debugger;
        }
        if(prevData[i].type !== "video" && prevData[i].type !== "img" && prevData[i].type !== "image") {
          return setPreview({type:"viewTemplate"});
        }
      }
      if(prevData[0].type == "video") {
        return setPreview({type:"video", content:prevData[0].content});
      }
      if(prevData[0].type == "image" || prevData[0].type == "img") {
        return setPreview({type:"image", content:prevData[0].content});
      }
      return setPreview({type:"viewTemplate"});
    
      
    } catch (err) {
      console.log(err);
      setPreview({type:"none"});

    } finally {
      setLoadingPreview(false);
    }
  }

  useEffect(() => {
    let intId;
    if(androidKey.deviceId) {
      getPreviewData();
      intId = setInterval(() => {
        getPreviewData();
      }, 1000 * 60 * 5) 
    }
    return () => {
      clearInterval(intId);
    }
  }, [])
  console.log(screenshotData)
  return (
    <div
    className='flex flex-col justify-end items-center text-center w-[100%] bg-[#fdfdfd] p-5 gap-1 shadow-md'
  >
    {showKioskModal && <KioskModeModal mac={androidKey.mac} setShowModal={setShowKioskModal} kioskM={androidKey.kioskMode}/>}
    {showRotationModal && <AndroidRotateModal mac={androidKey.mac} deviceRotation={androidKey.rotation} setShowModal={setShowRotationModal}/>}
    {showMessageModal && <AndroidMessageModal mac={androidKey.mac} setShowModal={setShowMessageModal}/>}
    {showDetailsModal && <AndroidDeviceDetailsModal setShowModal={setShowDetailsModal} mac={androidKey.mac} code={androidKey.code} androidkey={androidKey}/>}
    {showControlsModal && <AndroidKeyControls setShowModal={setShowControlsModal} code={androidKey.code} controls={keyControls} setControls={setKeyControls} mac={androidKey.mac}/>}
    
    <div className='w-full flex flex-col items-center justify-center p-5'>
      {loadingPreview && <img
        src={LoadingAnim}
        className='w-12'
      />}
      {!loadingPreview && !screenshotData &&  preview.type !== "none" &&
        <Link to={`/editDevice/${androidKey.deviceId}`} className='w-full h-full'>
          <PlaylistElement element={{content:preview.content, type: preview.type}}/>
        </Link>
      }
      {!loadingPreview &&  screenshotData != "" &&  <Link to={`/editDevice/${androidKey.deviceId}`} className='w-full h-full'>
        <img
          className='w-full h-full aspect-square'
          src={screenshotData}
        />
      </Link>}
    </div>
    
    <div
      className='w-full flex flex-row items-center justify-center gap-2 bg-slate-200 rounded-t-lg'
    >
      {androidKey.code && <h2 className='text-black font-bold '>Code: {androidKey.code}</h2>}
      {androidKey.connected 
        ? <img src={GreenLogo} className='w-2 mt-1'/>
        : <img src={RedLogo} className='w-2 mt-1'/>
      }
    </div>

    <div
      className='w-full flex flex-row items-center justify-center gap-1  rounded-b-lg bg-gray-200 p-2'
    >
      {loading 
      ? <div className='w-full flex flex-row justify-center items-center'>
          <img src={LoadingAnim} className='w-5'/>
      </div>
      :<>
        <div className='w-full flex items-center justify-center gap-4'>
          <button
            onClick={async () => {
              setLoading(true)
              await refreshAndroidDevice(user.token,{mac: androidKey.mac})
              setLoading(false)
            }}
          >
            <img src={RefreshLogo} className='w-5'/>
          </button>
          <button
            onClick={() => setShowControlsModal(true)}
          >
            <img src={ControlsIcon} className='w-5'/>
          </button>
          <button
            onClick={() => setShowDetailsModal(true)}
          >
            <img src={ViewDetailsIcon} className='w-5'/>
          </button>
          <button  
            onClick={()=> {setUnbind(androidKey.code)}}
          >
            <img src={Delete}/>
          </button>
        
          <img
            src={upldatePlaylistLogo} 
            className='w-5 cursor-pointer'
            onClick={() => setChangePlaylistId(androidKey)}
          />
          <img
            src={MessageIcon} 
            className='w-5 cursor-pointer'
            onClick={() => setShowMessageModal(true)}
          />
          <img
            src={RotationIcon} 
            className='w-5 cursor-pointer'
            onClick={() => setShowRotationModal(true)}
          />
          <img
            src={KioskIcon} 
            className='w-5 cursor-pointer'
            onClick={() => setShowKioskModal(true)}
          />
          <img
            src={GroupIcon} 
            className='w-5 cursor-pointer'
            onClick={() => selectAndroidGroup(androidKey)}
          />

        </div>
      </>}
    </div>
  </div>
  )
}

export default AndroidKeyBoxMain