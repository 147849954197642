import axios from "axios"

export const getViewTemplates = async (token, storeId) => {
  const res = await axios.get(
    `https://backend.konekt.vip/viewTemplates?storeId=${storeId}`, 
    {
      headers:{
        token: `bearer ${token}`   
      }
    }
  );
  return res.data;
}

export const createViewTemplate = async (token, data) => {
  const res = await axios.post(
    'https://backend.konekt.vip/viewTemplates/createTemplate',
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const updateViewTemplate = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/viewTemplates/updateTemplate`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  )
}