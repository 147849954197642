import React from 'react'

const Admin = ({admin}) => {
  return (
    <tr key={admin._id} className="border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
    <td className="text-black font-bold border-r-2 p-2 ">
      {admin.email}
    </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {admin.name}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {admin.storeId}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {admin.superAdmin ? "True" : "False"}
      </td>
      {
        admin.superAdmin 
        ? <td className="text-black font-bold border-r-2 p-2  flex w-full gap-2 justify-center">
          {admin.stores && admin.stores.length > 0 && admin.stores.map(store => <div>
            <h2>{store}</h2>
          </div>)}
          </td>
        :  <td className="text-black font-bold border-r-2 p-2 flex w-full justify-center ">
        {admin.storeId}
      </td>
      }
    </tr>
  )
}

export default Admin