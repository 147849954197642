import React, { forwardRef } from 'react'
import RenderTemplateQuarter from '../RenderTemplateQuarter'
import RenderTemplateHalf from '../RenderTemplateHalf'
import RenderTemplate from '../RenderTemplate'
import RenderTest from '../RenderTest'
import DynamicTemplate from './DynamicTemplate'

const sizeMapToIndex = {
  "quarter":4,
  "half": 2,
  "full": 1,
}

const Multiprint=forwardRef(({barcodes, storeId, size}, ref) => {
  const sizeCounter = sizeMapToIndex[size];

  return (
    <div className={`flex flex-col w-full h-screen ${size=='full' && "gap-[100px]"} ${size == "half" && "gap-[15%]"} ${size == "quarter" && "gap-[20px]"}`} ref={ref}>
      {barcodes.map((barcode, index) =>
        <div className='w-full pt-2 pr-5' style={(index + 1) % sizeCounter  == 0 ? {pageBreakAfter:"always"} : {}}>
          <DynamicTemplate 
            barcode={barcode}
            storeId={storeId}
            size={size}
            ref={null}
          /> 
        </div>
      )
      }
      
    </div>
  )
})

export default Multiprint