import React, { useState } from 'react'
import CloseIcon from '../../static/crossB.png';
import SaveIcon from '../../static/floppy-disk.png';
import checkmarkIcon from '../../static/checkmark.png';
import { useDispatch, useSelector } from 'react-redux';
import { setDisplayWeeks } from '../../store';
import { updateWeekdayApi } from '../../api/deviceApi';


const DeviceSchedulingModal = ({setModal, displayNumber}) => {
  const dispatch = useDispatch();
  const device = useSelector(state => state.device);
  const user = useSelector(state => state.user);
  const displayName = device?.displayIds[displayNumber];

  
  const [days, setDays] = useState({
    Sunday: device?.displayWeeks[displayNumber]?.Sunday || false,
    Monday: device?.displayWeeks[displayNumber]?.Monday || false,
    Tuesday: device?.displayWeeks[displayNumber]?.Tuesday || false,
    Wednesday: device?.displayWeeks[displayNumber]?.Wednesday || false,
    Thursday: device?.displayWeeks[displayNumber]?.Thursday || false,
    Friday: device?.displayWeeks[displayNumber]?.Friday || false,
    Saturday: device?.displayWeeks[displayNumber]?.Saturday || false,
  })
  const [daysCheck, setDaysCheck] = useState(device?.displayWeeks[displayNumber]?.check || false);
  
  return (
    <div className="modal bg-black bg-opacity-40">
      <div className="relative  w-full my-6 mx-auto  max-w-3xl overflow-scroll mt-[50px]">
        <div className=" border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white 
          outline-none focus:outline-none p-9 justify-center items-center overflow-scroll"
        >
          <div className="flex flex-row justify-end gap-10  w-[100%]">
            <button
              className=''
              onClick={() => setModal(mdl => ({
                ...mdl,
                show:false
              }))}
            >
              <img src={CloseIcon}/>
            </button>
          </div>
          <div className='w-full flex-row justify-start'>
            <h2 className='text-xl font-semibold text-gray-800'>
              Display Scheduling
            </h2>
          </div>
          <div className='w-full flex-row justify-start'>
            <h2 className='text-lg font-semibold text-gray-700'>
              {displayName}
            </h2>
          </div>
          <div className='mt-2 w-full flex-row justify-start'>
            <h2 className='text-md font-semibold text-gray-600'>
              Enable Weekdays
            </h2>
          </div>
          <div className='flex flex-col  w-full p-1'>
            <div 
              className={`transition-all flex relative hover:cursor-pointer w-[10%] h-[25px]
                ${daysCheck ? "bg-blue-500" : "bg-gray-500"} rounded-md `}
              onClick={() => setDaysCheck(!daysCheck)}
            >
              <div className={`${daysCheck ? "left-[60%]" :"left-2"} top-[2px] transition-all absolute rounded-full bg-white border-black border-2 p-2`}>
              </div>
            </div>

          </div>
          <div className=' w-full h-[120px] flex flex-row justify-center gap-5 p-2'>
            {Object.keys(days).map(d => <div 
              className={`hover:cursor-pointer p-2 min-w-[12%] max-w-[12%] transition-all 
                rounded-lg h-full flex flex-col  items-center ${days[d] ? "bg-blue-500" :"bg-gray-500"}`}
              onClick={() => {
                setDays(dys => ({...dys, [d]:!days[d]}));
              }}
            >
              <h2 className='text-white font-bold'>{d.substring(0,3)}</h2>
              {days[d] && <img
                src={checkmarkIcon}
              />}
            </div>)}
          </div>
          <div className='w-full p-2 flex justify-center '>
            <button 
              className='bg-blue-500 rounded-lg p-2 text-white font-bold hover:bg-blue-400 transition-all'
              onClick={async () => {
                await updateWeekdayApi(user.token,{
                  deviceId: device.deviceId,
                  displayWeek:{...days,check:daysCheck},
                  displayPosition: displayNumber,

                })
                dispatch(setDisplayWeeks({index: displayNumber, content:{...days, check:daysCheck}}));
                setModal(mdl => ({...mdl,show:false}));
              }
            }
            >
              Save
            </button>
          </div>


        </div>
      </div>
    </div>
  )
}

export default DeviceSchedulingModal