import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import ReactToPrint from 'react-to-print';
import PrinterWhite from '../static/printerWhite.png';
import Multiprint from '../components/prints/Multiprint';
import { useTranslation } from 'react-i18next';

const MultiplePrintPage = () => {
  const {state} = useLocation();
  const printRef = useRef();
  const {t} = useTranslation();
  return (
    <div className='flex flex-col justify-center items-center'>
      
      <ReactToPrint
        trigger={() => <button className='btn m-2 text-2xl !p-5'>
          {t("print")}
        </button>}
        content={() => printRef.current}
        pageStyle={`
          @page {
            size: portrait;
            margin: 0;
          }
        `}
        
      />
      <Multiprint
        storeId={state.storeId}
        barcodes={state.barcodes}
        size={state.size}
        ref={printRef}
      />
    </div>
  )
}

export default MultiplePrintPage