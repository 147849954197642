import React, { useEffect, useState } from 'react';
import Checkmark from '../../static/checkmark.png';
import Cross from '../../static/X.png';
import Preview from '../../static/eye.png';
import arrowLeft from "../../static/arrow-left.png";
import arrowRight from "../../static/arrow-right.png";
import Show from '../../static/eye.png';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';
import { getItem } from '../../api/itemsApi';
import { useDispatch, useSelector } from 'react-redux';
import { addDisplayModal, setCloseModal, setDisplaysModal, setLoadingState } from '../../store';
import { previewDevice } from '../../api/deviceApi';

const DeviceItem = ({deviceId, currentDisplay, online, showId, rotateAutomatically, displays, deviceRotation, barcodes}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const user = useSelector(state => state.user);
  const { t } = useTranslation();

  const updateItemsModal = async () => {
    dispatch(setLoadingState(true));
    dispatch(setCloseModal());
    try {
      const previewData = await previewDevice(user.token,{deviceId});
      dispatch(setDisplaysModal(previewData))
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
    
  }
  return (
    <div 
      dir={i18n.language == "he" ? "rtl" :"ltr"}
      className='relative flex flex-row justify-end items-start bg-white
        p-4 gap-3 border-b-2 border-gray-200 w-[90%] overflow-clip cursor-pointer'
      onClick={() => {
        navigate(`/editDevice/${deviceId}`);
      }}
    >
      <>
        <div className='flex flex-col justify-start items-start gap-2 w-full'>
          <h2 className='text-black font-bold text-2xl'>{t("profileId")} {deviceId}</h2>
    
          <h2 className='text-black  font-semibold flex justify-center items-center'>
            {t("showProfileId") + ": "}
            {showId ? t("yes") : t("no")}
          </h2>
          <div className='text-black  font-semibold flex justify-center items-center'>{t("webPlayerStatus")}
            {online ? <h2 className='text-green-500'> {t("active")} </h2> : <h2 className='text-red-500'>{t("notActive")}</h2>}
          </div>
          <div className='text-black  font-semibold flex justify-center items-center'>
            {i18n.t("displayStatus")}:{deviceRotation && deviceRotation.length > 0 ? <h2 className='text-green-500'> {t("active")} </h2> : <h2 className='text-red-500'>{t("notActive")}</h2>}
          </div>
          {deviceRotation?.length > 0 && <button 
            className='bg-blue-300 p-2 rounded-xl text-sm font-bold text-white shadow-md
            hover:bg-blue-400 transition-all'
            onClick={(e) => {
              e.stopPropagation();
              updateItemsModal();
            }}
          >
             {t("showItems")}
          </button>}

        </div>
          <button className='flex flex-col justify-center items-center mt-10'>
            {i18n.language == "he" ? (<img src={arrowLeft} width={25}/>) :(<img src={arrowRight} width={25}/>)}
          </button>
      </>
    </div>
  )
}

export default DeviceItem