import axios from "axios";

export const getDevices = async (token, url, selectedStore) => {
  try {
    const res = await axios.get(`https://backend.konekt.vip/device/getDevices?selectedStore=${selectedStore}`, {
      headers:{
        token: `bearer ${token}`
      }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const getDeviceAndPlaylist = async (token, selectedStore, sort) => {
  const res = await axios.get(`https://backend.konekt.vip/device/getPlaylistsAndDevices?selectedStore=${selectedStore}`, {
    headers:{
      token: `bearer ${token}`
    },
    params:{
      ...sort
    }
  });
  return res.data;
}

export const moveDisplayBackApi = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/device/moveDisplayBack`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}
export const moveDisplayForwardApi = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/device/moveDisplayForward`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const createDeviceApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/createDevice`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}
 
export const editDeviceNameApi = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/device/editPlaylistName`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}
export const getDeviceInfo = async (token, deviceId, url) => {
  try {
    const res = await axios.get(`https://backend.konekt.vip/device/getDeviceInfo?id=${deviceId}`, {
      headers:{
        token: `bearer ${token}`
      }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const getDeviceDisplayNumber = async (token, deviceId, url) => {
  try {
    const res = await axios.get(`https://backend.konekt.vip/device/getDeviceDisplayNumber?deviceId=${deviceId}`, {
      headers:{
        token: `bearer ${token}`
      }
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const setDeviceDisplayNumber = async (token, url, body) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/device/setDisplayNumber`,
      body,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
export const getDisplayList = async (token, url) => {
  try {
    const res = await axios.get(
      `https://backend.konekt.vip/display/getDisplays`,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const addDisplayToDevice = async (token, body, url) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/device/addDisplay`,
      body,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const RemoveDisplayFromDevice = async (token, body, url) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/device/removeDisplayByIndex`,
      body,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
export const replaceDisplayFromDevice = async (token, body, url) => {
  try {
    const res = await axios.put(
      `https://backend.konekt.vip/device/replaceDisplayByIndex`,
      body,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

//This try/catch bullshit is still here?
//what the fuck was I thinking here... 
export const previewDevice = async (token, body, url) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/device/previewDevice`,
      body,
      {
        headers:{
          token: `bearer ${token}`
        }
      }
    )
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const previewDisplayFromDevice = async (token, body) => {
    const res = await axios.post(
      `https://backend.konekt.vip/device/PreviewDisplayFromDevice`,
      body,
      {
        headers:{
          token: `bearer ${token}`
        }
      }
    )
    return res.data;
}

export const setShowDeviceIdApi = async (token, url, body) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/device/setShowDeviceId`,
      body,
      {
        headers:{
          token: `bearer ${token}`
        }
      }
    )
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const setRotateAutomaticallyApi = async (token, url, body) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/device/setDisplaySwitch`,
      body,
      {
        headers:{
          token: `bearer ${token}`
        }
      }
    )
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const deviceSetRotationTime = async (token, url, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/setRotationTime`,
    body,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const deviceSetRotation = async (token, url, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/setRotation`,
    body,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const deviceSetDates = async (token, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/setDates`,
    body,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const deviceSetShowUnBound = async (token, url, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/setUnbound`,
    body,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}
export const replaceBarcodeByIndex = async (token, url, body) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/replaceBarcodeInDisplay`,
    body,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const refreshPlaylistForDevices = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/refreshAllDevices`,
    data,
    {
      headers:{
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const addDurationApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/addDuration`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const deleteDurationApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/deleteDuration`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}

export const updateWeekdayApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/addWeekdays`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  );
  return res.data;
}