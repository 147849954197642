import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import CloseIcon from '../../static/crossB.png';
import SaveIcon from '../../static/floppy-disk.png';
import Select from 'react-tailwindcss-select';
import { useTranslation } from 'react-i18next';
import { switchKioskMode } from '../../api/AndroidKeyApi';

const commands = [
  {label:"Disable", value:"Disable"},
  {label:"Enable", value:"Enable"},
]

const KioskModeModal = ({setShowModal, mac, kioskM = false}) => {
  const [kioskMode, setKioskMode] = useState(kioskM ? "Enable" : "Disable");
  const [error, setError] = useState("");

  const {t} = useTranslation();
  const user = useSelector(state => state.user);

  const submit = async () => {
    try {
      await switchKioskMode(user.token, {
        mac,
        enabled: kioskMode == "Enable" ? true : false
      });
      setShowModal(false);
    } catch (err) {
      console.log(err);
      if(err?.response?.data?.msg) {
        setError(`Error: ${err.response.data.msg}`);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    }
  }
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] gap-2 p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className='w-full flex flex-col items-center gap-2 p-2'>
              <h2 className='text-black font-bold text-xl'>Set Kiosk Mode</h2>
              <Select
                options={commands}
                value={{label:kioskMode, value:kioskMode}}
                onChange={(selection)=> setKioskMode(selection.value)}
                classNames={{
                  menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                  menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
            <div className="flex items-center justify-center p-3 border-slate-200 rounded-b gap-2">
              <button
                className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
                type="button"
                onClick={submit}
              >
                {t("send")}
              </button>
              <h2 className='text-red-500 font-bold'>{error}</h2>
            </div>
          </div>
        </div>
      </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default KioskModeModal