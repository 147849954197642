import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addDisplayToDevice, getDisplayList } from '../api/deviceApi';
import Search from '../static/search_black.png';
import Select from 'react-tailwindcss-select';
import { addDeviceDisplay, addPlaylistDisplay, logoutUser } from '../store';
import { useTranslation } from 'react-i18next';
import { getDisplaysForUserApi } from '../api/displayApi';
import ItemInDevice from '../components/items/ItemInDevice';
import { getItem, searchItems } from '../api/itemsApi';
import ItemResult from '../components/items/ItemResult';
import i18n from '../i18n';
import { useNavigate } from 'react-router-dom';
import Header from '../components/layout/Header';
import { modifyPlayListApi } from '../api/playlistApi';

const PlaylistDisplayCreatePage = () => {
  const user = useSelector(state => state.user);
  const playlist  = useSelector(state => state.playlist);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [barcodeSearch, setBarcodeSearch] = useState(null);
  const inputRef = useRef();
  const [displays, setDisplays] = useState([]);
  const [display, setDisplay] = useState({label:"..."});
  const [barcodes, setBarcodes] = useState([]);
  const [barcodeError, setBarcodeError] = useState("");
  const {t} = useTranslation();

  useEffect(() => {
    if(!playlist.playListId) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getDisplaysForUserApi(user.token, "", playlist.storeId)
      .then(res => setDisplays(res))
      .catch(err => {
        console.log(err);
        dispatch(logoutUser());
      })
  }, []);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 50)
  }, []);

  const onPickItem = async (barcode) => {
    inputRef.current.value =""
    try {
      const itemResult = await getItem(user.token, "", barcode, playlist.storeId);
      if(itemResult){
        setBarcodes(barcodes => [...barcodes, barcode ]);
      } else {
        setBarcodeError(t("barcodeNotFound"));
        setTimeout(() => {
          setBarcodeError("");
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setBarcodeError("Unknown Error");
      setTimeout(() => {
        setBarcodeError("");
      }, 2000)
    } finally {
      setBarcodeSearch(null)
    }
  }

  const submitDisplay = async (e) => {
    e.preventDefault();
    if(!display.value) {
      return;
    }
    try {
      let newPlaylist = {...playlist};
      newPlaylist.displayIds = [...newPlaylist.displayIds, display.value];
      newPlaylist.barcodes = [...newPlaylist.barcodes, barcodes];

      await modifyPlayListApi(user.token, newPlaylist);
      dispatch(addPlaylistDisplay({displayId:display.value, barcodes}));
      navigate(-1);
      
    } catch (err) {
      console.log(err)
    }
  }

  const searchItemsCall = async () => {
    try {
      const result = await searchItems(user.token,"", inputRef.current.value,playlist.storeId);
      setBarcodeSearch(result)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <Header showBack={true} backTitle={playlist.playlistId}/>
      <div
        dir={i18n.language == "he" ? "rtl" :"ltr"}
        className="w-full my-6 overflow-scroll mt-[50px] pb-24 h-full"
      >
        <div className="flex flex-col w-full h-full bg-white justify-start items-center overflow-scroll">
          <div className="w-full  relative flex flex-col items-center">
            <div className="w-full  md:w-[50%] flex flex-col justify-start items-start text-center gap-2">
              <label
                className="block text-lg text-black font-bold mx-2"
              >
                {t("displayName")}
              </label>
              <Select
                options={
                  displays.map(display => 
                    ({value:display.displayId, label:display.displayId}))
                }
                value={display}
                onChange={(selection)=> setDisplay(selection)}
                classNames={{
                  menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                  menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
            <div className='flex flex-col h-full w-full md:w-[50%]  gap-2 p-2'>
              {barcodes && barcodes.length > 0 && <>
                <label
                  className="block text-md text-black font-bold"
                >
                  {t("barcodes")}
                </label>
                <div className='flex flex-col  gap-2 w-full overflow-scroll'>
                  {barcodes.map((barcode, barcodeIndex) => 
                    <ItemInDevice
                      barcode={barcode}
                      key={barcodeIndex}
                      storeId={playlist.storeId}
                      onDelete={() => setBarcodes(barcodes => {
                        let newBarcodes = barcodes.filter((barcode, innerIndex) => barcodeIndex != innerIndex);
                        return newBarcodes
                      })}
                    />
                  )}
                </div>
              </>}
              <label
                className="block text-md text-black font-bold mx-2"
              >
                {t("addAbarcode")}
              </label>
              <div className='relative w-full'>
                <input
                  type="text"
                  name="org"
                  ref={inputRef}
                  className="h-10 pl-8 w-full text-black bg-white border-[1px] border-[#e0e0e2]  focus:ring-none outline-none"
                />
                <div className='flex flex-col items-center relative w-full'>
                  {barcodeSearch && <ItemResult items={barcodeSearch} addItem={onPickItem}/>}
                </div>
                <h2 className='text-md text-red-500 font-bold'>{barcodeError}</h2>
                <button
                  className='absolute top-[10px] left-[1%]'
                  type="button"
                  onClick={() => searchItemsCall()}
                >
                  <img src={Search} width={25}/>
                </button>
              </div>
            </div>
            <div className="mt-6 flex flex-row justify-center">
              <button
                className="btn text-lg uppercase"
                onClick={submitDisplay}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlaylistDisplayCreatePage