import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sendCommandToDevice, updateAndroidControls } from '../../api/AndroidKeyApi';
import Select from 'react-tailwindcss-select';


const commands = [
  {label:"Restart", value:"restart"},
  {label:"Close", value:"close"},
  {label:"Launch", value:"launch"},
  {label:"Clear Cache", value:"clearcache"}
]

const commandDic = {
  "clearcache":"Clear Cache",
  "close":"Close",
  "launch":"Launch",
  "restart":"Restart"
}

const AndroidKeyControls = ({setShowModal, code, controls, setControls, mac}) => {
  const {t} = useTranslation();
  const [command, setCommand] = useState("");
  const [error, setError] = useState("");
  const user = useSelector(state=> state.user);
  
  const onSubmit = async () => {
    try {
      if(command) {
        await sendCommandToDevice(
          user.token,
          {
            mac,
            command
          }
        );
      }
  
      await updateAndroidControls(
        user.token,
        {
          code,
          volume: controls.volume,
          brightness: controls.brightness
        }
      );
      setShowModal(false);
    } catch (err) {
      if(err?.response?.data?.msg) {
        setError(`Error: ${err.response.data.msg}`);
        setTimeout(() => {
          setError("");
        }, 3000);

      }
      else {
        setError("Internal Server Error");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      console.log(err);
    }
  }
  return (
    <div>
    <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="flex flex-col justify-start items-center w-full h-full max-w-2xl">
        <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%]">
            <div className="flex felx-col items-center justify-center p-4 rounded-t">
                <h3 className="text-md md:text-xl font-semibold text-gray-900 dark:text-white">
                  {t("androidDeviceProperties")}
                </h3>
            </div>
            <div className="flex flex-col justify-center gap-10 p-10 w-[100%]">
              <h2>Volume</h2>
              <input 
                type='range' 
                min={0} 
                max={100} 
                value={controls.volume} 
                onChange={(e) =>{
                  setControls(androidControls => ({...androidControls, "volume": e.target.value}))
                }}
              />
              <h2>Brigtness</h2>
              <input 
                type='range' 
                min={0} 
                max={100} 
                value={controls.brightness} 
                onChange={(e) =>{
                  setControls(androidControls => ({...androidControls, "brightness": e.target.value}))
                }}
              />

              <h2>Commands</h2>
              <Select
                options={commands}
                value={command ? {label:commandDic[command], value:command} : {label:t("choiceCommand"), value:""}}
                onChange={(selection)=> setCommand(selection.value)}
                classNames={{
                  menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                  menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
              <div className='w-full flex flex-col items-center justify-center'>
                <h2 className='text-red-500 font-bold'>{error}</h2>
              </div>
              <div className="flex items-center justify-center p-3 border-slate-200 rounded-b">
                <button
                  className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
                  type="button"
                  onClick={onSubmit}
                >
                  {t("send")}
                </button>
              </div>
              <div className="flex items-center justify-center  border-slate-200 rounded-b">
                <button
                  className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
        </div>      
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
  )
}

export default AndroidKeyControls