import React, { useEffect, useState } from 'react'
import DeviceItem from '../components/device/DeviceItem'
import { getDeviceAndPlaylist, getDevices } from '../api/deviceApi';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../i18n';
import Header from '../components/layout/Header';
import { logoutUser, setLoadingState } from '../store';
import DeviceBarcodesModal from '../modals/Device/DeviceBarcodesModal';
import Loading from '../modals/Loading';
import AddIcon from '../static/addB.png'
import CreateDevice from '../modals/Device/CreateDevice';
import PlaylistTable from '../components/device/PlaylistTable';
import ListIcon from '../static/cells.png';
import CellsIcon from '../static/menu.png';

const Dashboard = () => {
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const loadingState = useSelector(state => state.loading.loading);
  const dispatch = useDispatch();
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const deviceItemsModal = useSelector(state => state.deviceItemsModal);
  const [devices, setDevices] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [sort, setSort] = useState({});
  const setSortingField = (column) => {
    setSort(srt => {
      if(srt && srt[column]) {
        srt[column] = srt[column] == "desc" ? "asc" : "desc"
        return srt;
      }
      else {
        return {[column]: "desc"}
      }
    });
  }

  const [showAddDevice, setShowAddDevice] = useState(false);
  useEffect(() => {
    if(!showAddDevice) {
      if(tableView) {
        dispatch(setLoadingState(true));
        getDeviceAndPlaylist(user.token, selectedStore, sort)
          .then((res) => {
            setDevices(res)
            dispatch(setLoadingState(false));
          })
          .catch(err => {
            console.error(err)
            dispatch(logoutUser());
          })
      } else {
        getDevices(user.token, url, selectedStore)
          .then((res) => setDevices(res))
          .catch(err => {
            console.error(err)
            dispatch(logoutUser());
          })
      }
    }
  }, [selectedStore, showAddDevice, tableView, sort])

  return (
    <>
      <Header/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"}
        className='h-screen w-full bg-white flex flex-col items-center
          overflow-y-scroll pb-[100px]'
      >
          {loadingState && <Loading/>}
          {deviceItemsModal.showModal && <DeviceBarcodesModal/>}
          {showAddDevice && <CreateDevice setShowModal={setShowAddDevice}/>}
          <div
          className='flex flex-row items-center justify-end w-full gap-3 p-5'
        >
          <button 
            className='border-2 border-[#2D5186] p-2 px-3 rounded-xl text-white font-bold hover:bg-gray-200 transition-all'
            onClick={() => {
              setShowAddDevice(true)
            }}
          >
            <img src={AddIcon} className='w-4'/>
          </button>
          <button 
            className='p-2 px-3 rounded-xl text-white font-bold hover:bg-gray-200 transition-all'
            onClick={() => {
              setTableView(true);
            }}
          >
            <img src={ListIcon} className='w-7'/>
          </button>
          <button 
            className='p-2 px-3 rounded-xl text-white font-bold hover:bg-gray-200 transition-all'
            onClick={() => {
              setTableView(false);
            }}
          >
            <img src={CellsIcon} className='w-6'/>
          </button>
        </div>
          {!tableView && <div className='flex flex-col justify-center items-center w-full gap-3 mt-5'>

            {devices.map((device) => 
              <DeviceItem 
                playlistName={device.playlistName}
                key={device.deviceId}
                deviceId={device.deviceId}
                currentDisplay={device.displayIds[device.DeviceDisplayCounter]}
                online={device.online}
                showId={device.showDeviceId}
                rotateAutomatically={device.rotateAutomatically}
                displays={device.displayIds}
                barcodes={device.barcodes}
                deviceRotation={device.deviceRotation}
              />)
            }
          </div>}
          {tableView && <PlaylistTable playlists={devices} setSortingField={setSortingField}/>}
      </div>
    </>
  )
}

export default Dashboard