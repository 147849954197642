import React, { useEffect, useState } from 'react'
import CloseIcon from '../../static/crossB.png';
import { getGroups } from '../../api/versionApi';
import { getDeviceVersion, updateDeviceVersion } from '../../api/AndroidKeyApi';
import { useSelector } from 'react-redux';
import Select from 'react-tailwindcss-select';

const AndroidKeyVersionGroupModal = ({mac, setShowModal}) => {
  const [deviceVersionGroup, setDeviceVersionGroup] = useState("");
  const [versionGroups, setVersionGroups] = useState([]);

  const user = useSelector(state => state.user);

  const fetchData = async () => {
    try {
      const groups = await getGroups(user.token);
      setVersionGroups(groups);

      const currVerGroup = await getDeviceVersion(user.token, {mac});
      setDeviceVersionGroup(currVerGroup.versionGroup);
      
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <h2 className='font-bold text-4xl'>Version Group Selection</h2>
            <div className='w-full flex flex-col items-center my-4'>
              <Select
                options={versionGroups.map(grp => ({label:grp.group, value:grp.group}))}
                onChange={(selection)=> {
                  return setDeviceVersionGroup(selection.value);
                }}
                value={deviceVersionGroup ? {label:deviceVersionGroup, value:deviceVersionGroup} : {value:"default", label:"default"}}
                classNames={{
                  menu:"absolute w-full h-[250px]  bg-white border-2 border-black overflow-scroll z-20",
                  menuButton:() => "flex flex-row w-full bg-white border-2 border-black px-2 justify-between",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>

            <button 
              className='btn'
              onClick={async () => {
                try {
                  await updateDeviceVersion(user.token, {mac, versionGroup: deviceVersionGroup});
                } catch (err) {
                  console.log(err)
                } finally {
                  setShowModal(false);
                }
              }}
            >
              Save Group
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AndroidKeyVersionGroupModal