import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { bindAndroidKey, removeAndroidbind } from '../../api/AndroidKeyApi';
import { useSelector } from 'react-redux';
import CloseIcon from '../../static/crossB.png';
import SaveIcon from '../../static/floppy-disk.png';


const EditAndroidKeyModal = ({androidKey, closeModal}) => {
  const [deviceId, setDeviceId] = useState("");
  const [error, setError] = useState("");

  const user = useSelector(state => state.user);
  const {t} = useTranslation();
  const submitKey = async () => {
    try {
      if(!deviceId) {
        setError("device Id is missing");
        return setTimeout(() => {
          setError("")
        }, 3000)
      }
      const res = await bindAndroidKey(
        user.token,
        {
          code:androidKey.code,
          deviceId: deviceId,
          storeId: androidKey.storeId,
          name: androidKey.name
        }
      );
      closeModal();
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => closeModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                {t("profileId")}
              </p>
              <input 
                className='w-[70%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                name="code"
                onChange={(e) => setDeviceId(e.target.value)}
              />
            </div>
          
            <div className='w-full flex-col flex justify-center items-center'>
              <h2 className='text-red-500 font-bold m-2'>{error}</h2>
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 ">
              <div className="flex flex-row justify-center gap-10 w-[100%]">
                <button
                  onClick={submitKey}
                  className='border-2 p-1 border-[#2D5186] rounded-lg hover:bg-gray-100 transition-all'
                >
                  <img src={SaveIcon} className='w-8'/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditAndroidKeyModal