import axios from "axios";

export const getPrintingList = async (token) =>  {
  const res = await axios.get(
    `https://backend.konekt.vip/printList/`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const updatePrintingList = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/printList/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

