import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const OnlineStats = ({deviceData}) => {
  return (
    <div className='p-2 md:p-5 w-full  h-[350px] md:h-[250px] flex flex-col justify-center items-center border-2 rounded-lg bg-white shadow-md'>
      <h2 className='font-bold text-black'>Online Devices</h2>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={(() => {
            let keys = Object.keys(deviceData);
            let data = keys.map(key => {
              return {...deviceData[key], name: key}
            })
            return data;
          })()}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="online" fill="#12ca0d" activeBar={<Rectangle fill="#12ca0d" stroke="blue" />} />
          <Bar dataKey="offline" fill="#FF0000" activeBar={<Rectangle fill="#FF0000" stroke="purple" />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default OnlineStats