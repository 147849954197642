import axios from "axios";

export const getCategories = async (token) => {
  const res = await axios.get(`https://backend.konekt.vip/category`,{
    headers:{
      token:`bearer ${token}`
    }
  });
  return res.data;
}

export const addCategory = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/category`,
    data,
    {
      headers: {
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}
