import QRCode from "react-qr-code"

const deepMapElements = (elements, deviceId, displayPosition, qrCodeIndex) => {
  if(elements.type === "view") {
    return <div style={elements.style}>
      {elements.elements.map(element => deepMapElements(element, deviceId, displayPosition, qrCodeIndex))}
    </div>
  }
  if(elements.type === "img") {
    return (
      <img 
        style={elements.style}
        src={elements.content}
      />
    )
  }
  if(elements.type === 'video') {
    if(elements.content.includes(".mp4") || elements.content.includes(".webm"))
      return <video style={elements.style} autoPlay muted loop>
         <source src={elements.content} type="video/webm" />
      </video>
  }
  if(elements.type === "text") {
    return <p dir='rtl' style={elements.style}>{elements.content}</p>
  }
  if(elements.type ==="qrcode_dyn") {
    return <div style={{backgroundColor:"white"}}>
      <QRCode
        size={256}
        style={elements.style}
        value={elements.content}
        viewBox={`0 0 256 256`}
      />
    </div>
  }

  if(elements.type === "qrCode") {
    return <div style={{backgroundColor:"white"}}>
      <QRCode
        size={256}
        style={elements.style}
        value={JSON.stringify({
          barcode:elements.barcode,
          itemPosition: elements.itemPosition,
          deviceId: deviceId,
          displayPosition: qrCodeIndex
        })}
        viewBox={`0 0 256 256`}
      />
    </div>
  }
}


const getBackground = (background) => {
  if(background && background.type === "video")  
    return <video style={{position:'absolute', width:"100%", height:"100%", aspectRatio:"16/9"}} autoPlay muted loop src={background.uri} type="video/webm">
    </video>
  else if(background && background.type === "img") {
    return  <img src={background.uri}  style={{position:'absolute', width:"100%", height:"100%", aspectRatio:"16/9"}} alt=''/>
  }
}


const PreviewComponent = ({display, deviceId,showDeviceId, displayPosition, qrCodeIndex}) => {
  return (
    <div className="w-full h-full relative">
      <div 
        style={{
          height:'100%',
          width:"100%",
          display:'flex',
          flexDirection:'row',
          backgroundColor:'#000',
          justifyContent:"start",
          alignItems:"start",
          gap:10,
          padding:10,
        }}  
      >
        {display.background && getBackground(display.background)}
        {display.elements && display.elements.map((element, index) => {
          if(Array.isArray(element)) {
            return <div 
              style={{
                borderWidth: 5,
                borderColor:"white",
                justifyContent:"center",
                alignItems:"center",
                display:'flex',
                flexDirection:"column",
                padding:10
              }} 
              key={index}
            >
                {element.map(asset => {
                  return deepMapElements(asset, deviceId, index, qrCodeIndex);
                })}
            </div>
          }
          else if(element.type === "video") {
            if(element.content.includes(".mp4") || element.content.includes(".webm")) {
              return <video style={element.style} autoPlay loop muted={true} src={element.content} >
              </video>
            }
          }
          else if(element.type === "img") {
            return <img 
                style={element.style}
                src={element.content}
              />
          }
          else if (element.type === "powerpoint") { 
            
            return <iframe
              src={`https://docs.google.com/gview?url=${element.content}&embedded=true&hl=Nl`}
              style={element.style}
            />
          }
          else if (element.type === "Iframe") {
            return <iframe
              src={element.content}
              style={element.style}
            />
          }
          else if(element.type === "view") {
            return <div
              style={element.style} 
              key={index}
            >
                {element.content.map(asset => {
                  return deepMapElements(asset, deviceId, displayPosition, qrCodeIndex);
                })}
            </div>
          }
          else if(element.type === "displaySwitchButton") {
            return <button 
              style={element.style}
              key={index}
              // onClick={() => {
              //   setDisplay(element.displaySwitch);
              // }}
            >
              {element.content}
            </button>
          }
          else if(element.type === "viewTemplate") {
            return <div style={element.style}>
              <div dangerouslySetInnerHTML={{__html:element.content}}>
              </div>
            </div>
          }
          else if(element.type === "backgroundView") {
            return <div style={element.style}>
              {Array.isArray(element.content) && element.content.map(asset => {
                  return deepMapElements(asset, deviceId, displayPosition, qrCodeIndex);
              })}
            </div>
          }
        })}
        {showDeviceId && <div 
          style={{
            height:"10%",
            width:"10%",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            position:"absolute",
            top: "89%",
            left:"1%",
            borderColor:"white",
            borderWidth: 2,
            backgroundColor:"#000",
            borderRadius:10,
            padding:15}}
        >
          <h2 className='text-white text-2xl'>{deviceId}</h2>
        </div>}
      </div>
    </div>
  )
}

export default PreviewComponent