import React, { useEffect, useState } from 'react'
import Title from '../components/Title'
import { getTemplates } from '../api/printTemplatesApi';
import { useDispatch, useSelector } from 'react-redux';
import PrintTemplate from '../components/printTemplate/PrintTemplate';
import { logoutUser } from '../store';
import Header from '../components/layout/Header';

const TemplatesPage = () => {
  const [templates, setTemplates] = useState([]);
  const user = useSelector(state => state.user);
  const store = useSelector(state => state.storeSelection.selectedStore);

  const dispatch = useDispatch();

  useEffect(() => {
    getTemplates(user.token, store)
      .then(res => setTemplates(res))
      .catch(err =>{
        dispatch(logoutUser());
        console.log(err);
      });
  }, [store]);
  console.log(templates)
  return (
    <div className='w-full h-full flex flex-col items-center'>
      <Header showBack={true} backTitle={""}/>
      <Title title={"templates"}/>
      <div className='grid grid-cols-1  md:grid-cols-3 gap-3 mt-5'>
        {templates.map(template => <PrintTemplate template={template} />)}
      </div>
    </div>
  )
}

export default TemplatesPage