import { useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-tailwindcss-select";
import { DisplayStructs } from "../../../staticData/DisplayStructures";
import { addDisplayApi } from "../../../api/displayApi";


const structures = [
  {value:"HorizontalFullHD1920x1080_OneTemplate", label:"HorizontalFullHD1920x1080_OneTemplate"},
  {value:"HorizontalFullHD1920x1080_4Templates", label:"HorizontalFullHD1920x1080_4Templates"},
  {value:"HorizontalFullHD1920x1080_6Templates", label:"HorizontalFullHD1920x1080_6Templates"},
  {value:"VerticalFullHD1920x1080_OneTemplate", label:"VerticalFullHD1920x1080_OneTemplate"},
  {value:"VerticalFullHD1920x1080_2ColumnTemplate", label:"VerticalFullHD1920x1080_2ColumnTemplate"},
  {value:"VerticalFullHD1920x1080_4Templates", label:"VerticalFullHD1920x1080_4Templates"},
  {value:"VerticalFullHD1920x1080_10RowPriceList", label:"VerticalFullHD1920x1080_10RowPriceList"},
  {value:"Stretch1920x158_OneTemplate", label:"Stretch1920x158_OneTemplate"},
]


const AddDisplayModal = ({setShowModal}) => {
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);

  const [display, setDisplay] = useState({
    structure: "HorizontalFullHD1920x1080_OneTemplate",
    name:""
  })
  const submitDisplay = async () => {
    try {
      await addDisplayApi(
        user.token, 
        "",
        {
          displayId: display.name,
          structure: display.structure,
          elements: DisplayStructs[display.structure],
          storeId: selectedStore,
          background: null,
          admin: user.email,
          styling:{
            "height": "100%",
            "width": "100%",
            "display": "flex",
            "flexDirection": "row",
            "backgroundColor": "#000",
            "justifyContent": "space-between",
            "alignItems": "center",
            "gap": 10,
            "padding": 0
          },
          containerStyling:{
            "height": "100%",
            "width": "100%",
            "display": "flex",
            "flexDirection": "column",
            "backgroundColor": "#000",
            "justifyContent": "start",
            "alignItems": "center",
            "gap": 10,
            "padding": 10
          }
        }
      );
      setShowModal(false);

    } catch (err) {
      console.log(err);
    }
  }

  const {t} = useTranslation();
  const navigate = useNavigate();
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                display Name
              </p>
              <input 
                className='w-[70%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                name="name"
                onChange={(e) => setDisplay((display) => ({...display, name: e.target.value}))}
              />
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                {t("structure")}
              </p>
              <div className='w-full p-2'>
                <Select
                  options={structures}
                  value={{label:display.structure, value: display.structure}}
                  onChange={(selection)=> setDisplay(display =>({...display, structure: selection.value}))}
                  classNames={{
                    menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-[#c7c7c8] overflow-scroll z-10",
                    menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-[#c7c7c8] px-2 justify-between z-10",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
              <div className="w-full flex flex-col items-center justify-start gap-2">
                <div className="flex flex-row justify-center gap-10 w-[100%]">
                  <button
                    className='btn text-xl !p-3'
                    onClick={submitDisplay}
                  >
                    {t("submit")}
                  </button>
                </div>
                <div className="flex flex-row justify-center gap-10  w-[100%]">
                  <button
                    className='btn text-xl !p-3'
                    onClick={() => setShowModal(false)}
                  >
                    {t("close")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AddDisplayModal