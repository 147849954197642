import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-tailwindcss-select';
import { setTemplateState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { getTemplateSizes } from '../../api/TempSizesApi';

const templates = [
  {value:"default", label:"default"},
  {value:"Temp1", label:"Temp1"},
  {value:"Temp2", label:"Temp2"},
  {value:"Temp3", label:"Temp3"},
  {value:"Temp4", label:"Temp4"},
  {value:"Temp5", label:"Temp5"},
  {value:"Temp6", label:"Temp6"},
  {value:"Temp7", label:"Temp7"}
]

const structures = [
  {value:"HorizontalFullHD1920x1080_OneTemplate", label:"HorizontalFullHD1920x1080_OneTemplate"},
  {value:"HorizontalFullHD1920x1080_2SideTemplate", label:"HorizontalFullHD1920x1080_2SideTemplate"},
  {value:"HorizontalFullHD1920x1080_4Templates", label:"HorizontalFullHD1920x1080_4Templates"},
  {value:"HorizontalFullHD1920x1080_6Templates", label:"HorizontalFullHD1920x1080_6Templates"},
  {value:"VerticalFullHD1920x1080_OneTemplate", label:"VerticalFullHD1920x1080_OneTemplate"},
  {value:"VerticalFullHD1920x1080_2ColumnTemplate", label:"VerticalFullHD1920x1080_2ColumnTemplate"},
  {value:"VerticalFullHD1920x1080_4Templates", label:"VerticalFullHD1920x1080_4Templates"},
  {value:"VerticalFullHD1920x1080_10RowPriceList", label:"VerticalFullHD1920x1080_10RowPriceList"},
  {value:"Stretch1920x158_OneTemplate", label:"Stretch1920x158_OneTemplate"},
  {value:"Stretch1920x158_4Templates", label:"Stretch1920x158_4Templates"},
  {value:"Stretch1920x158_6Templates", label:"Stretch1920x158_6Templates"},
  {value:"Stretch1920x532_OneTemplate", label:"Stretch1920x532_OneTemplate"},
  {value:"Stretch1920x532_2SideBySide", label:"Stretch1920x532woSideBySide"},
  {value:"Stretch1920x532_3SideBySide", label:"Stretch1920x532_3SideBySide"},
  {value:"Stretch2880x158_OneTemplate", label:"Stretch2880x158_OneTemplate"},
  {value:"Stretch2880x158_4Templates", label:"Stretch2880x158_4Templates"},
  {value:"Stretch2880x158_6Templates", label:"Stretch2880x158_6Templates"},
  {value:"Sparkle800x1280_OneTemplate", label:"Sparkle800x1280_OneTemplate"},
  {value:"Sparkle800x1280_2ColumnTemplate", label:"Sparkle800x1280_2ColumnTemplate"},
  {value:"Sparkle800x1280_4Templates", label:"Sparkle800x1280_4Templates"},
  {value:"Sparkle800x1280_10RowPriceList", label:"Sparkle800x1280_10RowPriceList"},
  {value:"Promotion", label: "Promotion"}
]


const CreateTemplateModal = ({setShowModal}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const [templateSizes, setTemplateSizes] = useState([]);

  const fetchTemplateSizes = async () => {
    try {
      const res = await getTemplateSizes(user.token, {storeId: storeSelection});
      setTemplateSizes(res);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => { 
    fetchTemplateSizes();
  }, [storeSelection]);

  const [template, setTemplate] = useState({
    type:"default",
    structure:"HorizontalFullHD1920x1080_4Templates",
    size:""
  });

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-start items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                {t("template")}
              </p>
              <div className='w-full p-2'>
                <Select
                  options={templates}
                  value={{label:template.type, value: template.type}}
                  onChange={(selection)=> setTemplate(template =>({...template, type: selection.value}))}
                  classNames={{
                    menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-[#c7c7c8] overflow-scroll z-10",
                    menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-[#c7c7c8] px-2 justify-between z-10",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
            </div>
            <div className="relative flex flex-col justify-start items-start text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Size
              </p>
              <div className='w-full p-2'>
                <Select
                  options={templateSizes.map(sz => ({value: sz.size, label:sz.size }))}
                  value={{label:template.size, value: template.size}}
                  onChange={(selection)=> setTemplate(template =>({...template, size: selection.value}))}
                  classNames={{
                    menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-[#c7c7c8] overflow-scroll z-10",
                    menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-[#c7c7c8] px-2 justify-between z-10",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
              <div className="flex flex-row justify-center gap-10 p-10 w-[100%]">
                <button
                  className='btn text-xl !p-3'
                  onClick={() => {
                    dispatch(setTemplateState({
                      action:"create",
                      structure: template.structure,
                      type: template.type,
                      html:"",
                      storeId: storeSelection,
                      size: template.size
                    }))
                    navigate('/createViewTemplate')
                    setShowModal(false)
                  }}
                >
                  {t("submit")}
                </button>
              </div>
            </div>
            <div className="flex flex-row justify-center gap-10 p-10 w-[100%]">
              <button
                className='btn text-xl !p-3'
                onClick={() => setShowModal(false)}
              >
                {t("close")}
              </button>
            </div>
          </div>      
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default CreateTemplateModal