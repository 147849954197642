import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getTemplateSizes } from '../../api/TempSizesApi';
import Select from 'react-tailwindcss-select';
import { setTemplateState } from '../../store';

const EditTemplateModal = ({setShowModal}) => {
  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const temp = useSelector(state => state.template);
  const [templateSizes, setTemplateSizes] = useState([]);
  const [templateDetails, setTemplateDetails] = useState({
    size: temp.size,
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchTemplateSizes = async () => {
    try {
      const res = await getTemplateSizes(user.token, {storeId: storeSelection});
      setTemplateSizes(res);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => { 
    fetchTemplateSizes();
  }, [storeSelection]);

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-start items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-col items-center justify-center gap-10 p-10 w-full">
              <p
                className="text-2xl  text-black font-bold"
              >
                Select Template Size
              </p>
              <Select
                options={templateSizes.map(sz => ({value: sz.size, label:sz.size }))}
                value={{label:templateDetails.size, value: templateDetails.size}}
                onChange={(selection)=> setTemplateDetails(template =>({...template, size: selection.value}))}
                classNames={{
                  menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-[#c7c7c8] overflow-scroll z-10",
                  menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-[#c7c7c8] px-2 justify-between z-10",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
              <div className='w-full flex items-center justify-center gap-5'>
                <button
                  className='btn text-xl !p-3'
                  onClick={() => {
                    if(!templateDetails.size) {
                      setError("Size must be selected");
                      return setTimeout(() => {
                        setError("");
                      }, 3000)
                    }
                    setShowModal({showModal:false, tempId:""});
                    dispatch(setTemplateState({...temp, size: templateDetails.size}));
                    navigate('/createViewTemplate')
                  }}
                >
                  Edit
                </button>
                <button
                  className='btn text-xl !p-3'
                  onClick={() => setShowModal({showModal:false, tempId:""})}
                >
                  close
                </button>
              </div>
              <h2 className='text-red-500 font-bold'>{error}</h2>
            </div>          
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditTemplateModal