import axios from "axios";

export const getGroups = async (token) => {
  const res = await axios.get(
    `https://backend.konekt.vip/playerVersion/`,
    { 
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const updateGroup = async (token,body) => {
  const res = await axios.put(
    `https://backend.konekt.vip/playerVersion/update`,
    body,
    { 
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getGroupDevices = async (token, params) => {
  console.log(params);
  const res = await axios.get(
    `https://backend.konekt.vip/playerVersion/devices`,
    {
      headers:{
        token:`bearer ${token}`,
      },
      params
    }
  );
  return res.data;
}

export const addGroupDevice = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/playerVersion/addDevice`,
    data,
    {
      headers:{
        token:`bearer ${token}`,
      },
    }
  );
  return res.data;
}

export const deleteGroupDevice = async (token,data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/playerVersion/removeDevice`,
    data,
    {
      headers:{
        token:`bearer ${token}`,
      },
    }
  );
  return res.data;
}