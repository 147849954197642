import axios from "axios";

export const getAlertSettings = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/alert`,
    {
      params,
      headers:{
        token:`bearer ${token}`
      },
    }
  );
  return res.data;
}

export const updateAlertSettings = async (token,data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/alert`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      },
    }
  );
  return res.data;
}