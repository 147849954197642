import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { previewTemplate } from '../api/templateApi'
import QRCode from 'react-qr-code'
import { getItem } from '../api/itemsApi'

const RenderTemplateQuarter =forwardRef(({storeId, barcode, structure, name}, ref) => {
  const user = useSelector(state => state.user);
  const [item, setItem] = useState({});
  useEffect(() => {
    getItem(
        user.token,
        "",
        barcode,
        storeId
       
    )
    .then(res => setItem(res))
    .catch(err => console.log(err));
  }, [])
  if(!item) {
    return <div>Empty</div>
  }
  return <div className='w-[100%] flex flex-row items-start'  ref={ref}>
    <div className='w-[45%] h-full bg-yellow-400'>
      <div className=' w-full flex justify-center items-center'>
        <div className='w-full flex justify-center items-center'>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='mt-[30px] text-[50px] text-green-700'>₪</h2>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-[120px] text-green-700'>{item.price}</h2>
        </div>
      </div>
      <div className='w-full flex justify-center bg-green-700 py-2 px-2 gap-1'>
        <div className=' flex flex-col bg-green-700  gap-1'>
          <div className='flex flex-row px-1 bg-green-600 justify-end'>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[9px] text-white '>מחיל לפני ל-יח</h2>
          </div>
          <div className='flex flex-row px-1 bg-green-600 justify-end gap-1'>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[12px] text-white '>₪</h2>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[12px] text-white '>{item.originalPrice}</h2>
          </div>
        </div>
        <div className=' flex flex-col bg-green-700  gap-1'>
          <div className='flex flex-row px-1 bg-green-600 justify-start items-start'>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[9px] text-white '>  שווי ליחידה אחרי הנחה</h2>
          </div>
          <div className='flex flex-row px-1 bg-green-600 justify-end gap-1'>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[12px] text-white '>₪</h2>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[12px] text-white '>{item.originalPrice}</h2>
          </div>
        </div>
        <div className=' flex flex-col bg-green-700  gap-1'>
          <div className='flex flex-row px-1 bg-green-600 justify-end'>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[9px] text-white '>מחיר לפני הנחה</h2>
          </div>
          <div className='flex flex-row px-1 bg-green-600 justify-end gap-1'>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[12px] text-white '>₪</h2>
            <h2 style={{fontFamily:"RagSans-extraBold"}} className=' text-[12px] text-white '>{item.originalPrice}</h2>
          </div>
        </div>
      </div>
    </div>
    <div className='w-[75%] h-[100%] flex flex-col gap-[30px] pr-1'>
      <div className='w-full flex flex-row justify-end'>
        <div className='flex w-[40%] bg-green-600 justify-end items-center p-1 gap-1'>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-white mt-1'>{item.custFeature11}</h2>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-white mt-1'>:מספר מבצע</h2>
        </div>
      </div>
      <div className='w-full flex flex-col justify-center items-end p-5'>
        <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-600 text-5xl'>{item.name}</h2>
      </div>
      <div className='w-full flex flex-col  items-end'>
        <div className='flex flex-row gap-1'>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-sm'>הזול/ים מבין כלל המוצרים שיירכשו על ידי הלקוח</h2>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-sm'> ,{item.custFeature12} :מבצע בתוקף עד ה</h2>
        </div>
        <div className='flex flex-row gap-1'>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-sm align-bottom'>{item.custFeature10}</h2>
          <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-sm align-bottom'>:הברקודים המשתתפים במבצע </h2>
        </div>
      </div>
    </div>  
  </div>
});

export default RenderTemplateQuarter;
