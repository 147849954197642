import LoadingIcon from '../static/loading.gif';

const Loading = () => {
  return (
    <div>
      <div className='modal'>
        <div className="relative w-auto my-6 mx-auto max-w-3xl ">
          <div className='relative flex flex-col w-full  outline-none focus:outline-none p-9 justify-center items-center'>
            <img src={LoadingIcon} className='w-[50%] h-[50%]'/>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default Loading