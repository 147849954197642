import React from 'react'

const ItemResult = ({items, addItem}) => {
  return (
    <div className='mt-2 absolute flex flex-col top-[-10px] bg-white w-[100%]  border-2 border-black z-1 !oveflow-scroll z-5'>
      {items.map(item => 
        <div className='relative bg-white hover:bg-blue-400 transition-colors overflow-clip p-2 z-5' onClick={() => addItem(item.barcode)}>
          <h2 className='text-black font-bold'>{item.name}</h2>
          <h2 className='text-black font-bold'>{item.barcode}</h2>
        </div>)}
    </div>
  )
}

export default ItemResult