import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getTemplate, updateTemplate } from '../api/printTemplatesApi';
import { useSelector } from 'react-redux';

const UpdatePrintableTemplate = () => {
  let refdiv=useRef(null);
  let rte;
  const {state} = useLocation();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  function btnclick(){
    alert(rte.getHTMLCode());
  }

  // setTimeout(function(){
  //   rte=new window.RichTextEditor(refdiv.current);
  //   rte.setHTMLCode("");
  // },0)

  useEffect(() => {
    rte=new window.RichTextEditor(refdiv.current);
    rte.setHTMLCode("");
    if(state?.template) {
      getTemplate(user.token, state?.template?.name)
        .then(res => {
          rte.setHTMLCode(res.html)
        })
        .catch(err => console.log(err));

    }
  }, [])

  const onSave = async () => {
    try {
      const updatedTemplate = state.template;
      updatedTemplate.html = rte.getHTMLCode();
      await updateTemplate(user.token, updatedTemplate);
      navigate('/templates');
    } catch (err) {
      console.log(err);
    }
    
  }

  return (
    <div className='w-full h-full justify-center items-center'>
      <header>
        <div ref={refdiv}></div>
        
        <hr/>
        <div className='p-5 flex flex-col w-full justify-center items-center'>
          <button className='btn' onClick={onSave}>
            Save template
          </button>
        </div>
        
      </header>
    </div>
  );
}

export default UpdatePrintableTemplate;