import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { addDisplayApi, getDisplayApi } from '../api/displayApi';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-tailwindcss-select';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { uploadImage, uploadVideo } from '../api/uploadApi';
import Upload from '../static/upload.png'
import Header from '../components/layout/Header';
import { logoutUser } from '../store';
import EditorPreviewModal from '../components/Editor/EditorPreviewModal';
import { getPromoByStore } from '../api/promoApi';
import UploadDisplayEditModal from '../modals/upload/UploadDisplayEditModal';

const types = [
  {label:'view(legacy)', value:'view'},
  {label:'img', value:'img'},
  {label:'video', value:'video'},
  {label:"powerpoint", value:"powerpoint"},
  {label:"Youtube", value:"Iframe"},
  {label:"url", value:"url"},
  {label:"dynamicVideo", value:"dynamicVideo"},
  {label:"dynamicImg", value:"dynamicImg"},
  {label:"Dynamic URL", value:"dynamicIframe"},
  {label:"dynamicPowerPoint", value:"dynamicPowerPoint"},
  {label:"dynamic PDF", value:"dynamicPDF"},
  {label:"viewTemplate", value:"viewTemplate"},
  {label:"Promotion", value:"promotion"}
]

const typeDic = {
  "img":"img",
  "Iframe":"Youtube",
  "video":"video",
  "view":"view(legacy)",
  "powerpoint":"powerpoint",
  "dynamicVideo":"dynamic Video",
  "dynamicImg":"dynamic Image",
  "dynamicIframe":"dynamic URL",
  "dynamicPowerPoint":"dynamic PowerPoint",
  "dynamicPDF":"dynamic PDF",
  "viewTemplate":"ViewTemplate",
  "url":"URL",
  "promotion":"Promotion"
}

const DisplayEditPage = () => {
  const {displayId} = useParams();
  
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);

  const dispatch = useDispatch();
  const [showUploadModal, setShowUploadModal] = useState({
    type:"",
    show: false
  });
  const setUploadedContent = (upd) => {
    setCurrElement(currElement => ({...currElement, ["content"]:upd}));
  }
  
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [currElement, setCurrElement] = useState(null);
  const [currElementIndex, setCurrElementIndex] = useState(null);
  // I should really finish that already
  const [showAddElement, setShowAddElement] = useState(false);

  const [error, setError] = useState("")
  const [display, setDisplay] = useState(null);
  const updateStyle = (attribute, value) => {
    setDisplay(display => {
      const newDisplay = JSON.parse(JSON.stringify(display));
      newDisplay.elements[currElementIndex].style[attribute] = value;
      setCurrElement(newDisplay.elements[currElementIndex]);
      return newDisplay;
    })
  }

  const updateAttribute = (attribute, value) => {
    setDisplay(display => {
      const newDisplay = JSON.parse(JSON.stringify(display)); 
      newDisplay.elements[currElementIndex][attribute] = value;
      setCurrElement(newDisplay.elements[currElementIndex]);
      return newDisplay;
    })
  }

  const EditOuterField = (title, attribute, type) => {
    const doActionDecrease = (newAtribute, amount, type = "value") => {
      if(type =="percent") {
        updateAttribute(attribute, `${parseFloat(newAtribute) - amount}%`);
      }
      if(type =="value") {
        updateAttribute(attribute, parseFloat(newAtribute) - amount);
      }
    }
    const doActionIncrease = (newAtribute, amount, type = "value") => {
      if(type =="percent") {
        updateAttribute(attribute, `${parseFloat(newAtribute) + amount}%`);
      }
      if(type =="value") {
        updateAttribute(attribute, parseFloat(newAtribute) + amount);
      }
    }

    return (
      <div className='flex w-[90%] gap-2 items-center justify-between'>
        <h2 className='font-bold text-md'>{title}:{" " + currElement[attribute]}</h2>
        <div className='flex gap-2'>
          <button
            className='btn'
            onClick={() => {
              const newAtribute = currElement[attribute];
              doActionDecrease(newAtribute, 1);
            }}
          >
            {`<`}
          </button>
          <button
            className='btn'
            onClick={() => {
              const newAtribute = currElement[attribute];
              doActionIncrease(newAtribute, 1);
            }}
          >
            {`>`}
          </button>
        </div>
    </div>)

  }

  const editField = (title, attribute, type) => {
    const doActionDecrease = (newAtribute, amount) => {
      if(type =="percent") {
        updateStyle(attribute, `${parseFloat(newAtribute) - amount}%`);
      }
      if(type =="value") {
        updateStyle(attribute, `${parseFloat(newAtribute) - amount}`);
      }
    }
    const doActionIncrease = (newAtribute, amount) => {
      if(type =="percent") {
        updateStyle(attribute, `${parseFloat(newAtribute) + amount}%`);
      }
      if(type =="value") {
        updateStyle(attribute, `${parseFloat(newAtribute) + amount}`);
      }
    }
    return (
      <div className='flex w-[90%] gap-2 items-center justify-between'>
        <h2 className='font-bold text-md'>{title}:{" " +currElement.style[attribute]}</h2>
        <div className='flex gap-2'>
          <button
            className='btn'
            onClick={() => {
              const newAtribute = currElement.style[attribute];
              doActionDecrease(newAtribute, 0.5);
            }}
          >
            {`<`}
          </button>
          <button
            className='btn'
            onClick={() => {
              const newAtribute = currElement.style[attribute];
              doActionIncrease(newAtribute, 0.5);
            }}
          >
            {`>`}
          </button>
        </div>
    </div>)
  }
  
  const {t} = useTranslation();
  
  const uploadfile = async (e, fileType) => {
    const file = e.target.files[0];
    console.log(file);
    console.log(fileType);
    debugger;
    if(fileType == "img" && file.type != "image/jpeg" && file.type != "image/png") {
     setError("Images must be .jpgs or .pngs only")
      setTimeout(() => {
       setError("");
      }, 3000);
      return;
    }

    if(fileType== "video" && file.type != "video/mp4") {
      setError("Videos can be mp4 only")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', display.admin);
    try {
      let result;
      if(fileType =="video") result = await uploadVideo(user.token, url, form);
      if(fileType == "img") result = await uploadImage(user.token, url, form);
      setCurrElement(currElement => ({...currElement, ["content"]:result.link}));
    } catch (err) {
      console.log(err);
    }
  }

  const selectElement = (index) => {
    setCurrElement(display.elements[index]);
    setCurrElement((element) => {
      if(element.type === "view") {
        element.content = "";
      }
      return element;
    })
    setCurrElementIndex(index);
  }

  const saveDisplayFunc = async (elementOverride) => {
    const displayToSave = display;
    // *Gorden Ramsey voice* FUCKING RAW!
    if(elementOverride) {
      displayToSave.elements[currElementIndex] = elementOverride;
    }
    if(!elementOverride) {
      displayToSave.elements[currElementIndex] = currElement;
    }
    try {
      setDisplay(null);
      const res = await addDisplayApi(user.token, url, displayToSave);
      setDisplay(res);
    } catch (err) {
      console.log(err)
    }
  }

  const deepMapElements = (elements, index) => {
    if(elements.type === "view") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("view")}</h2>
      </div>
    }
    if(elements.type === "viewTemplate") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("view")}</h2>
      </div>
    }
    if(elements.type === "dynamicImg") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Image</h2>
      </div>
    }
    if(elements.type === "dynamicVideo") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Video</h2>
      </div>
    }
    if(elements.type === "dynamicIframe") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Iframe</h2>
      </div>
    }
    if(elements.type === "dynamicPowerPoint") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic PowerPoint</h2>
      </div>
    }
    if(elements.type === "Iframe") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Iframe</h2>
      </div>
    }
    if(elements.type === "url") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Snap Link</h2>
      </div>
    }
    if(elements.type === "powerpoint") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Powerpoint</h2>
      </div>
    }
    if(elements.type === "promotion") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Promotion SlideShow</h2>
      </div>
    }
    if(elements.type === "dynamicPDF") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic PDF</h2>
      </div>
    }
    if(elements.type === "img") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <img src={elements.content} />
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("image")}</h2>
      </div>
    }
    if(elements.type === 'video') {
      return (
        <div 
          className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
          style={elements.style}
          onClick={() => selectElement(index)}
          key={index}
        >
          {<video key={index} onClick={() => selectElement(index)} style={{...elements.style, width:"100%", height:"100%", top:0, left:0}} autoPlay muted loop className={`${index === currElementIndex && `border-2 border-white`}`}>
            <source src={elements.content} type="video/webm" />
          </video>}
          <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("video")}</h2>
        </div>)
    }
    if(elements.type === "text") {
      return <p 
        dir='rtl'
        className={`${index === currElementIndex && `border-2 border-white`}`}
        onClick={() => selectElement(index)}
        style={elements.style}
      >
        {elements.content}
      </p>
    }
  }

  
  useEffect(() => {
    getDisplayApi(user.token,url, displayId, selectedStore)
      .then(res => {console.log(res); setDisplay(res)})
      .catch(err => {
        dispatch(logoutUser());
        console.log(err)
      });
  }, [])
  
  console.log(currElement);
  return (
    <>
      {showPreviewModal && <EditorPreviewModal setShowModal={setShowPreviewModal} displayId={displayId}/>}
      {showUploadModal.show && <UploadDisplayEditModal setElementContent={setUploadedContent} setShowModal={setShowUploadModal} type={showUploadModal.type}/>}
      <Header/>
      <div  className='relative bg-black w-full p-10 h-full flex flex-row justify-start gap-5'>
        <div className='p-10 bg-black relative flex flex-col w-[80%] h-full'>
          {display && display?.elements.map((element, index) => deepMapElements(element, index))}
        </div>
        <div className='absolute flex flex-col items-center bg-white shadow-md w-[22%] h-[100%] left-[79%] top-[0%] p-5 gap-5 overflow-scroll'>
          <h2 className='text-black font-bold text-2xl'>{t("elementDetails")}</h2>
          {currElement && <>
            <div className="flex flex-col justify-center text-center mt-5">
              <label
                className="block text-xl font-bold text-black"
              >
                {t("content")}
              </label>
              {currElement.type == "img" && <div className='relative'>
                <label
                  className='w-15 h-5 absolute top-[10px] left-[0%] cursor-pointer'
                  onClick={() => {
                    setShowUploadModal({
                      show:true,
                      type:"image"
                    })
                  }}
                >
                  <img src={Upload} width={20}/>
                </label>
              </div>}
              {currElement.type == "video" && <div className='relative'>
                <label
                  className='w-15 h-5 absolute top-[10px] left-[0%] cursor-pointer'
                  onClick={() => {
                    setShowUploadModal({
                      show:true,
                      type:"video"
                    })
                  }}
                >
                  <img src={Upload} width={20}/>
                </label>
              </div>}
              <input
                type="text"
                name="content"
                value={currElement.content}
                onChange={(e) => setCurrElement(currElement => ({...currElement, ["content"]:e.target.value}))}
                className="h-10 text-black bg-white border-b-2 border-black  focus:ring-none outline-none pl-6"
              />
            </div>
            <div className='w-full flex flex-col items-center justify-center gap-2'>
              <h2 className='font-bold text-lg'>Element Id</h2>
              <input
                type='text'
                name="id"
                value={currElement.id ? currElement.id : currElement.type+currElementIndex }
                onChange={(e) => setCurrElement(currElement => ({...currElement, ["id"]:e.target.value}))}
                className="h-10 text-black bg-white border-b-2 border-black  focus:ring-none outline-none pl-6"
              />
            </div>
            {display && <div className='w-full flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name='timedDisplay'
                className='p-2 mt-1'
                checked={display.timedDisplay}
                onChange={() => {
                  setDisplay((dsp) => {
                    const newDisplay = JSON.parse(JSON.stringify(dsp));
                    newDisplay.timedDisplay = newDisplay.timedDisplay ? false : true;
                    return newDisplay;
                  })
                 
                  
                }}
              />
              <h2 className='font-bold text-lg'>Timed Display</h2>
            </div>}
            
            <div className="flex flex-col justify-center text-center gap-5 mt-5">
              <label
                className="block text-xl font-bold text-black"
              >
                {t("type")}
              </label>
              <Select
                options={
                  types
                }
                value={(() => ({label:typeDic[currElement.type]}))()}
                onChange={async (selection)=> {
                  setCurrElement(currElement => ({...currElement, type: selection.value, content:""}))
                  if(selection.value == "promotion") {
                    const promo = await getPromoByStore(user.token, {storeId:display.storeId});
                    setCurrElement(element => ({...element, content:`http://188.166.77.5:3002/${promo?.promoLinkId}`}))
                  }  
                }}
                classNames={{
                  menu:"absolute w-[200px] h-[200px]  bg-white border-2 border-black overflow-scroll",
                  menuButton:() => "flex flex-row w-[200px] bg-white border-2 border-black px-2 justify-between",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
            {editField("Left Position", "left","percent")}
            {editField("Top Position", "top","percent")}
            {editField("Height", "height","percent")}
            {editField("Width", "width","percent")}
            {editField("Border Radius", "borderRadius","percent")}
            <div className='w-full flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name='timedElement'
                className=''
                checked={currElement.timedElement ? true : false}
                onChange={async () => {
                  
                  setCurrElement(elem => {
                    if(elem.timedElement) {
                      saveDisplayFunc({...currElement, "timedElement":false});
                      return {...elem, "timedElement": false}  
                    }
                    if(!elem.timedElement) {
                      saveDisplayFunc({
                        ...currElement,
                        "timedElement": true,
                        "display_seconds": 0,
                        "disappear_seconds": 60 
                      })
                      return {
                        ...elem, 
                        "timedElement": true,
                        "display_seconds": 0,
                        "disappear_seconds": 60
                      }
                    }
                  })
                }}
              />
              <h2 className='font-bold text-lg'>Timed Element</h2>
            </div>
            {currElement.timedElement && <>
              <div className='w-full flex flex-col items-center justify-center gap-2'>
                <input
                  type='text'
                  name='timedElement'
                  className="h-10 text-black bg-white border-b-2 border-black  focus:ring-none outline-none pl-6"
                  // value={currElement.video_id} 
                  value={currElement.video_id ? currElement.video_id : "" } // why the fuck does this fix the problem?
                  onChange={(e) => {
                    setCurrElement(elem => ({...elem, "video_id": e.target.value}))
                  }}
                />
                <h2 className='font-bold text-lg'>Controller Id</h2>
              </div>
              {EditOuterField("Start Time", "display_seconds","amount")}
              {EditOuterField("End Time", "disappear_seconds","amount")}
            </>}
            <button 
              className='btn mt-5'
              onClick={() => {
                saveDisplayFunc();
              }}
            >
              {t("save")}
            </button>
          </>}
          <button 
            className='btn mt-5'
            onClick={() => {
              setShowPreviewModal(true);
            }}
          >
            preview
          </button>
          {/* <button className='btn' onClick={() => setShowAddElement(true)}>
            Add new Element
          </button> */}
        </div>
      </div>
    </>
  )
}

export default DisplayEditPage