import React, { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print';
import PrintIcon from "../../static/printerB.png";
import AddIcon from "../../static/addB.png";
import RemoveIcon from '../../static/RemoveB.png';
import DeleteIcon from '../../static/delete.png';
import DynamicTemplate from './DynamicTemplate';
import SaleIcon from '../../static/sale.png';

const Print = ({item, barcodes, size, addBarcode, removeBarcode, displayingPrintList, deleteAllBarcodes}) => {

  const [showTemplate, setShowTemplate] = useState(false);

  const printRef = useRef();
  return (
    <div dir='ltr' className='relative flex flex-col w-full h-full z-10'>
      <div 
        onClick={(e) => {
          if(e.target === e.currentTarget) {
            setShowTemplate(!showTemplate)
          }
        }}
        className='flex flex-col lg:flex-row justify-between items-center text-center
          w-[100%] bg-white p-5 gap-3 shadow-md hover:bg-slate-100 transition-all'
      >
      
        <div className='flex flex-row justify-center gap-4'>
          {showTemplate && <ReactToPrint
            trigger={() => <button className=''>
              <img src={PrintIcon} width={20}/>
            </button>}
            content={(() => printRef.current)}
          />}
          {!showTemplate && 
            <button className=''>
              <img src={PrintIcon} width={20}/>
            </button>
          }
    
          <button 
            className=''
            onClick={() => {
              addBarcode(item.barcode);
            }}
          >
            <img src={AddIcon}/>
          </button>
        
          {barcodes.includes(item.barcode) &&
            <button 
            className=''
            onClick={() => {
              removeBarcode(item.barcode);
            }}
            >
              <img src={RemoveIcon}/>
            </button>
          }
          {displayingPrintList && <button className='' onClick={() => deleteAllBarcodes(item.barcode)}>
            <img src={DeleteIcon} width={20}/>
          </button>}
          {barcodes.includes(item.barcode) && 
            <div className='rounded-xl bg-green-400 px-[6px]'>
              <h2 className='text-black font-bold text-xs'>
                {(() => {
                  let count = 0;
                  barcodes.forEach((barcode) => barcode === item.barcode && count++);
                  return count;
                })()}
              </h2>
            </div>
          }
        </div>
        <div className='flex flex-row justify-center gap-2'>
          {item.attrName !== "default" && <img src={SaleIcon} className='w-[50px]'/>}
          <h2 className='hidden md:block text-black text-lg font-bold '>
            {item.barcode}
          </h2>
          {item.name && <h2 className='hidden lg:flex text-black text-lg font-bold '>
            -
          </h2>}
          <h2 className='text-black text-lg font-bold'>
            {item.name}
          </h2>
        </div>
      </div>
      {showTemplate && <div id='printable' className='relative h-screen overflow-scroll w-[100%]'>
        <DynamicTemplate 
          barcode={item.barcode}
          storeId={item.storeId}
          size={size}
          ref={printRef}
        />
      </div>}
    </div>
  )
}

// {showTemplate && <div id='printable' className='relative h-screen overflow-scroll w-[100%]'>
// {size === "full" && <RenderTemplate 
//   ref={printRef}
//   storeId={storeId}
//   structure={structure}
//   name={name}
//   barcode={item.barcode}
// />}
// {size === "half" && <RenderTemplateHalf
//   ref={printRef}
//   storeId={storeId}
//   structure={structure}
//   name={name}
//   barcode={item.barcode}
// />}
// {size === "quarter" && <RenderTemplateQuarter
//   ref={printRef}
//   storeId={storeId}
//   structure={structure}
//   name={name}
//   barcode={item.barcode}
// />}
// </div>}

export default Print