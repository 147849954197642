import { useSelector } from 'react-redux';
import CloseIcon from '../../static/crossB.png';
import SaveIcon from '../../static/floppy-disk.png';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import { editUploadName } from '../../api/uploadApi';

const EditUploadName = ({link, setShowModal}) => {
  const [name, setName] = useState("");
  const user = useSelector(state => state.user);
  const {t} = useTranslation();

  const submit = async () => {
    try {
      await editUploadName(
        user.token, 
        {
          link,
          name
        }
      );
      setShowModal({
        show:false,
        link:""
      });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal({
                  show:false,
                  link:""
                })}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Name
              </p>
              <input 
                className='w-[70%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 ">
              <div className="flex flex-row justify-center gap-10 w-[100%] mt-2">
                <button
                  onClick={submit}
                  className='border-2 p-1 border-[#2D5186] rounded-lg hover:bg-gray-100 transition-all'
                >
                  <img src={SaveIcon} className='w-8'/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditUploadName