import React from 'react';
import Edit from '../../static/stylus.png';
import { Link } from 'react-router-dom';
import Eye from '../../static/eye.png';

const Item = ({item}) => {
  return (
    <Link
      className='flex flex-col lg:flex-row justify-end items-center text-center w-[100%] bg-white p-5 gap-3 shadow-md'
      to={`/products/editProdcut/${item.barcode}`}
    >
      
        <h2 className='text-black text-lg font-bold '>
          {item.barcode}
        </h2>
        {item.name && <h2 className='hidden lg:flex text-black text-lg font-bold '>
          -
        </h2>}
        <h2 className='text-black text-lg font-bold'>
          {item.name}
        </h2>
        <button className=''>
          <img src={Edit}/>
        </button>
    </Link>
  )
}

export default Item