import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingIcon from '../../static/loading.gif';
const DynamicTemplate = forwardRef(({barcode, storeId , size}, ref) => {

  const user = useSelector(state => state.user);
  const [html, setHtml] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.post(
      "https://backend.konekt.vip/printTemplates/item",
      {
        barcode,
        size,
        storeId
      },
      {
        headers:{
          token: `bearer ${user.token}`
        }
      }
    )
    .then(res => {
      console.log(res);
      setHtml(res.data);
    })
    .catch(err => console.log(err))
    .finally(() => setLoading(false))
  }, [barcode, storeId, size])

  if(loading) {
    return <div className="flex flex-col w-full h-full justify-center items-center">
       <img src={LoadingIcon} className='w-[15%] h-[45%]'/>
    </div>
  }
  if(!html) {
    return <div className="flex flex-col w-full h-full justify-center items-center">
      <h2 className="text-black font-extrabold text-5xl">
         התבנית לא נמצאה 
      </h2>
    </div>
  }
  if(html.includes("<script>")) {
    <div className="flex flex-col w-full h-full">
      <h2 className="font-extrabold text-black">
        !Script Detected!
        No Scripts allowed
      </h2>
    </div>
  }

  return <div dangerouslySetInnerHTML={{__html:html}} ref={ref}>
  </div>
})

export default DynamicTemplate;