import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import AddIcon from '../static/updatePlaylist.png';
import { getStoresApi } from '../api/storesApi';
import Store from '../components/store/Store';
import CreateAdminModal from '../components/admin/CreateAdminModal';
import StoreAddModal from '../components/store/StoreAddModal';


const StoreManagmentPage = () => {
  const user = useSelector(state => state.user);
  const [stores, setStores] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);


  useEffect(() => {
    if(!showAddModal) {
      fetchData();
    }
  }, [showAddModal]);

  const fetchData = async () => {
    try {
      const res = await getStoresApi(user.token);
      setStores(res);
    } catch (err) {
      console.log(err);
    }
  } 

  return (
    <>
      {showAddModal && <StoreAddModal setShowModal={setShowAddModal}/>}
      <div className='w-full h-full flex flex-col items-center overflow-scroll'>
        <div className='w-full flex flex-row items-center justify-center gap-5 mt-2'>
          <button
            onClick={() => setShowAddModal(true)} 
          >
            <img src={AddIcon} className='w-8'/>
          </button>
        </div>
        <table className="w-full items-center">
          <thead className="text-sm md:text-[16px] text-slate-700 font-bold border-b-2 border-gray-300">
            <tr>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"              
              >
                StoreId
              </th>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"              
              >
                Store Name
              </th>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"              
              >
                ESL Integration Account
              </th>
              <th
                scope="col" 
                className="px-6 py-4 hover:cursor-pointer"              
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {stores.map(store => <Store key={store._id} store={store} />)}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default StoreManagmentPage