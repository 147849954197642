import axios from "axios";

export const getTemplateSizes = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/templateSize`,
    {
      headers:{
        token:`bearer ${token}`
      },
      params
    }
  );
  return res.data;
}
export const addTemplateSize = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/templateSize`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      },
    }
  );
  return res.data;
}

export const deleteTemplateSize = async (token, params) => {
  const res = await axios.delete(
    `https://backend.konekt.vip/templateSize`,
    {
      headers:{
        token:`bearer ${token}`
      },
      params
    }
  );
  return res.data;
}
