import React from 'react'
import PlaylistRow from './PlaylistRow'

const PlaylistTable = ({playlists, setSortingField}) => {

  return (
    <table className='w-full items-center hidden md:table'>
      <thead className="text-xs md:text-[16px] text-slate-700 font-bold border-b-2  border-gray-300">
        <tr>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer'
            onClick={() => {
              setSortingField("sortPlaylistName");
            }}
          >
            <div className='flex items-center justify-center'>
              <h2>
                Playlist Name
              </h2>
            </div>
          </th>
          
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[60px]'
            onClick={() => {
              setSortingField("sortActiveDisplays");
            }}
          >
            <div className='flex items-center justify-center'>
              <h2>
                Active Displays
              </h2>
            </div>
          </th>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[60px]'
            onClick={() => {
              setSortingField("sortTotalDisplays");
            }}
          >
            <div className='flex items-center justify-center'>
              <h2>
                Total Displays
              </h2>
            </div>
          </th>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[60px]'
            onClick={() => {
              setSortingField("sortOnlineDevices");
            }}
          >
            <div className='flex items-center justify-center'>
              <h3>
                Online Devices
              </h3>
            </div>
          </th>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[60px]'
            onClick={() => {
              setSortingField("sortOfflineDevices");
            }}
          >
            <div className='flex items-center justify-center'>
              <h2>
                Offline Devices
              </h2>
            </div>
          </th>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[60px]'
            onClick={() => {

            }}
          >
          <div className='flex items-center justify-center '>
            <h2>
              scheduled Displays
            </h2>
          </div>
          </th>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[200px]'
            onClick={() => {
              setSortingField("sortByDate");
            }}
          >
            Last Update Date
          </th>
          <th
            scope='col'
            className='px-6 py-4 hover:cursor-pointer w-[60px]'
            onClick={() => {
             
            }}
          >
            <div className='flex items-center justify-center'>
              <h2>
                Actions
              </h2>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {playlists && playlists.length > 0 && playlists.map(plylst => 
          <PlaylistRow playlist={plylst}/>)}
      </tbody>
    </table>
  )
}

export default PlaylistTable