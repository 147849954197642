import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Up from '../static/up.png';
import Down from '../static/down.png';
import deleteIcon from '../static/crossB.png';
import AddIcon from '../static/updatePlaylist.png';

import DeviceDisplay from '../components/device/DeviceDisplay';
import { addDevice, removeDevice, removeStore, setDevice, setDeviceShowId, setPlaylist, setPlaylistDeviceShowId, setRotateAutomatically, setShowUnBound, setShowUnBoundPlaylist, store } from '../store';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Header from '../components/layout/Header';
import { getPlaylistByIdApi, modifyPlayListApi, sendPlayListApi } from '../api/playlistApi';
import PlayListDisplayItem from '../components/playlist/PlayListDisplayItem';
import StoreAddModal from '../components/playlist/modals/StoreAddModal';
import DeviceAddModal from '../components/playlist/modals/DeviceAddModal';

const PlaylistEditPage = () => {
  const {playlistId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useSelector(state => state.user);
  const playlist = useSelector(state => state.playlist);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const [showAddStore, setShowAddStore] = useState(false);
  const [storeDeviceModal, setStoreDeviceModal] = useState({
    store:"",
    showModal:false
  });

  const [deviceRotationTime, setdeviceRotationTime] = useState(10);
  const getplaylist = async () => {
    try {
      const res = await getPlaylistByIdApi(user.token, playlistId);
      setdeviceRotationTime(res.deviceRotationTime)
      dispatch(setPlaylist(res));
    } catch (error) {
       console.log(error)
    }
  }

  useEffect(() => {
    getplaylist();
  }, []);

  const saveAndSubmit = async () => {
    const playlistToSubmit = {...playlist};
    console.log(playlistToSubmit);
    debugger;
    try {
      await modifyPlayListApi(user.token, playlistToSubmit);
      await sendPlayListApi(user.token, playlistToSubmit);
      navigate('/playlistDashboard');
    } catch (err) {
      console.log(err);
    }

  }

  if(playlist?.loading) {
    return <h2>loading...</h2>
  }

  
  return (
    <>
      <Header showBack={true} backTitle={"deviceProfiles"}/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='bg-white w-full  flex flex-col justify-start items-center  md:p-5 gap-2 overflow-scroll pb-24'>
        {showAddStore && <StoreAddModal setShowModal={setShowAddStore}/>}
        {storeDeviceModal.showModal && <DeviceAddModal storeModal={storeDeviceModal} setStoreModal={setStoreDeviceModal}/>}
        <div className='w-full bg-white px-2 py-4 flex flex-col  justify-center items-center'>
          <div className='w-full flex flex-col gap-2 justify-start items-start'>
            <div className="w-full pb-4 border-b border-gray-200 flex flex-row justify-between md:justify-center items-center">
              <h2 className='font-bold text-xl gap-2 mx-2'>{t("listId")}:</h2>
              <h2 className='text-black font-bold text-xl gap-2 mx-2'>{playlist.playListId}</h2>
            </div>
          </div>
          <div className='w-full flex flex-col gap-2 justify-start items-start'>
            <div className="w-full flex flex-row justify-start items-center">
              <h2 className='font-bold text-xl gap-2 mx-2'>{t("stores")}:</h2>
            </div>
            <div className='w-full flex flex-col gap-2 justify-start items-center'>
              {playlist.stores.map((store, index) => <div className='rounded-2xl w-full flex flex-row gap-2 justify-start items-center bg-gray-100 p-2'>
                {user.superAdmin && <div className='h-full flex flex-col p-1'>
                  <button 
                    className='text-black font-extrabold text-3xl hover:text-gray-300'
                    onClick={() => {
                      dispatch(removeStore(store.storeId));
                    }}  
                  >
                    <img src={deleteIcon}/>
                  </button>
                </div>}
                <div className='h-full flex flex-col gap-2 p-1 items-center'>
                  <h2 className='text-gray-700 font-bold text-xl'>{store.storeId}</h2>
                  <button 
                    onClick={() => {
                      setStoreDeviceModal({
                        store:store.storeId,
                        showModal:true
                      })
                    }}
                  >
                    <img src={AddIcon} className='w-8'/>
                  </button>
                </div>
                <div className='w-[90%] flex flex-row items-center justify-start overflow-scroll gap-2'>
                  {store?.devices?.map(device => <div className='p-2 gap-4 bg-gray-600 flex flex-row rounded-xl hover:bg-gray-800 transition-all'>
                    <h2 className='text-white text-xl'>{device}</h2>
                    <button 
                      className='text-white text-xl font-bold'
                      onClick={() => {
                        dispatch(removeDevice({index, device}))
                      }}
                    >
                      X
                    </button>
                  </div>)}
                </div>
              </div>)}
             {user.superAdmin && <button
                onClick={() => {
                  setShowAddStore(true)
                }} 
                className='bg-blue-500 p-2 text-white text-xl font-bold rounded-lg hover:bg-blue-400 transition-all'
              >
                {t("addStore")}
              </button>}
            </div>
          </div>
          <div dir='ltr' className="w-full p-2 flex flex-row gap-3 justify-between items-center text-center border-b border-gray-200 ">
            <input 
              type="checkbox"
              name="usesComax"
              checked={playlist.showDeviceId}
              className="w-5 h-5 text-blue-700 bg-blue-400 border-gray-300 rounded
              focus:ring-blue-700 dark:focus:ring-blue-700 dark:ring-offset-gray-800
                focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[3px]"
              onChange={async (e) => {
                try {
                  dispatch(setPlaylistDeviceShowId(!playlist.showDeviceId));
                } catch (err) {
                  console.log(err)
                }
              }}
            />
            <p className="font-bold text-black text-lg dark:text-gray-300">{t("showProfileId")}</p>
          </div>
          <div dir='ltr' className="w-full p-2 flex gap-3 justify-between border-b border-gray-200 items-center text-center my-2">
            <input 
              type="checkbox"
              name="usesComax"
              checked={playlist.showUnBound}
              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded
              focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-[3px]"
              onChange={async (e) => {
                try {
                  dispatch(setShowUnBoundPlaylist(!playlist.showUnBound));
                } catch (err) {
                  console.log(err)
                }
              }}
            />
            <p className="font-bold text-black text-lg dark:text-gray-300">{t("showUnboundItems")}</p>
          </div>
        </div>
        <div className="flex flex-col gap-3 justify-between  w-full px-3 ">
          <div className='flex justify-between md:justify-center items-center text-center align-middle gap-4' dir='ltr'>  
            <div className='border-[1px] rounded-md min-w-[25%] md:min-w-[0%] border-[#2D5186] p-2 flex flex-row gap-3 justify-between items-center text-center'>
              <button
                className=''
                disabled={deviceRotationTime < 2}
                onClick={async () => {
                  try {
                    setdeviceRotationTime(time => time -1);
                  } catch (err) {
                    console.log(err)
                  }
                }}
              >
                <img src={Down}/>
              </button>
              <h2>{deviceRotationTime}</h2>
              <button
                className=''
                disabled={deviceRotationTime > 98}
                onClick={async () => {
                  try {
                    setdeviceRotationTime(time => time +1);
                  } catch (err) {
                    console.log(err)
                  }
                }}
              >
                  <img src={Up}/>
              </button>
            </div>
            <p className="font-bold text-black text-lg dark:text-gray-300 mt-1">{t("rotationTime")}</p>
          </div>
        </div>
        <div dir={i18n.language == "he" ? "rtl" :"ltr"}   className='grid grid-cols-1 lg:grid-cols-3 w-full justify-start items-start gap-5 p-5'>
          {playlist.displayIds.map((value,index) => {
            let active = playlist.deviceRotation.includes(index);
            return <PlayListDisplayItem
              display={value}
              barcodes={playlist.barcodes[index]}
              displayIndex={index}
              active={active}
              deviceId={playlistId}
            />
          }
            
          )}
        </div>
        <div className='w-full flex flex-col justify-center items-center gap-2'>
          <Link
            to={'/addDisplayToPlaylist'}
          >
            <button 
              className=' btn text-lg shadow-lg p-4'
            >
              {t("addDisplay")}
            </button>
          </Link>
          <Link  
          >
            <button 
              className=' btn text-lg shadow-lg p-4'
              onClick={saveAndSubmit}
            >
              {t("savePlaylistAndSend")}
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default PlaylistEditPage