import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Search from '../../static/search_black.png'
import Scan from '../../static/scan.png'
import { replaceDisplayFromDevice } from '../../api/deviceApi';
import Select from 'react-tailwindcss-select';
import { editDeviceDisplay } from '../../store';
import { useTranslation } from 'react-i18next';
import { getDisplaysForUserApi } from '../../api/displayApi';
import ItemInDevice from '../../components/items/ItemInDevice';
import { getItem, searchItems } from '../../api/itemsApi';
import ItemResult from '../../components/items/ItemResult';

const EditDisplay = ({setShowModal, displayIndex}) => {
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const device = useSelector(state => state.device);
  const inputRef = useRef();

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [displays, setDisplays] = useState([]);
  const [display, setDisplay] = useState({
    label: device.displayIds[displayIndex],
    value: device.displayIds[displayIndex]
  });
  const [barcodeSearch, setBarcodeSearch] = useState(null);
  const [barcodeError, setBarcodeError] = useState("");
  const searchItemsCall = async () => {
    try {
      const result = await searchItems(user.token,url, inputRef.current.value,device.storeId);
      setBarcodeSearch(result)
    } catch (error) {
      console.log(error)
    }
  }

  const onPickItem = async (barcode) => {
    inputRef.current.value =""
    try {
      const itemResult = await getItem(user.token, url, barcode, device.storeId);
      if(itemResult){
        setBarcodes(barcodes => [...barcodes, barcode ]);
      } else {
        setBarcodeError(t("barcodeNotFound"));
        setTimeout(() => {
          setBarcodeError("");
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setBarcodeError("Unknown Error");
      setTimeout(() => {
        setBarcodeError("");
      }, 2000)
    } finally {
      setBarcodeSearch(null)
    }
  }

  useEffect(() => {
    getDisplaysForUserApi(user.token, url, device.storeId)
      .then(res => {setDisplays(res)})
      .catch(err => console.log(err))
  }, []);

  const [barcodes, setBarcodes] = useState(device.barcodes[displayIndex]);

  const submitDisplay = async (e) => {
    e.preventDefault();
    if(!display.value) {
      return;
    }
    try {
      const res = await replaceDisplayFromDevice(
        user.token,
        {
          deviceId: device.deviceId,
          index:  displayIndex,
          displayId: display.value,
          barcodes: barcodes
        },
        url
      );
      dispatch(editDeviceDisplay(
        {displayId:display.value, barcodes, index: displayIndex}
      ));
      setShowModal(false);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 50)
  }, [])

  return (
    <div>
      <div className='modal'>
        <div 
          className="relative w-auto my-6 mx-auto max-w-3xl overflow-scroll"
          onClick={() => setBarcodeSearch(null)}
        >
          {/*content*/}
          <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-9 justify-center items-center overflow-scroll">
            {/*body*/}
            <div className="relative p-3 flex flex-col items-center">
              <div className="flex flex-col justify-start items-start text-center gap-2">
                <label
                  className="block text-lg text-black font-bold"
                >
                  {t("displayName")}
                </label>
                <Select
                  options={
                    displays.map(display => 
                      ({value:display.displayId, label:display.displayId}))
                  }
                  value={display}
                  onChange={(selection)=> setDisplay(selection)}
                  classNames={{
                    menu:"absolute w-[250px] h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                    menuButton:() => "flex flex-row w-[250px] bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
                </div>
                <div className='flex flex-col justify-center items-center gap-2 p-2 max-h-[400px]'>
                  <label
                    className="block text-2xl text-black font-bold"
                  >
                    {t("barcodes")}
                  </label>
                  <div className='flex flex-col gap-2 p-4 w-full max-h-[400px] overflow-scroll'>
                    {barcodes.map((barcode, barcodeIndex) => 
                      <ItemInDevice
                        barcode={barcode}
                        displayIndex={displayIndex}
                        barcodeIndex={barcodeIndex}
                        allowReplace={true}
                        storeId={device.storeId}
                        onDelete={() => setBarcodes(barcodes => {
                          let newBarcodes = barcodes.filter((barcode, innerIndex) => barcodeIndex != innerIndex);
                          return newBarcodes
                        })}
                      />
                    )}
                  </div>
                  <label
                    className="block text-lg text-black font-bold"
                  >
                    {t("addAbarcode")}
                  </label>
                  <div className='relative'>
                    <input
                      type="text"
                      name="org"
                      ref={inputRef}
                      className="h-10 pl-8 text-black bg-white border-b-2 border-black  focus:ring-none outline-none"
                    />
                    <div className='flex flex-col items-center relative w-full'>
                      {barcodeSearch && <ItemResult items={barcodeSearch} addItem={onPickItem}/>}
                    </div>
                    <h2 className='text-md text-red-500 font-bold'>{barcodeError}</h2>
                    <button
                      className="absolute top-[10px] left-[1%]"
                      type="button"
                      onClick={() => searchItemsCall()}
                    >
                       <img src={Search} width={25}/>
                    </button>
                  </div>
                </div>
                <div className="mt-6 flex flex-row justify-center">
                  <button
                    className="btn text-lg uppercase"
                    onClick={submitDisplay}
                  >
                    {t("submit")}
                  </button>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-center p-6 border-slate-200 rounded-b">
                <button
                  className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditDisplay
