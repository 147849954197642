import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Device from '../../static/device.png';
import Licenses from '../../static/key.png';
import Display from '../../static/developer_mode_tv.png';
import Template from '../../static/data_table.png';
import Porduct from '../../static/product.png';
import PrintIcon from '../../static/printer.png';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const Bottombar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {t} = useTranslation();


  return (
    <div className="fixed bottom-0 left-0 w-full border border-gray-200 shadow-lg bg-white flex md:hidden p-2 z-20">
      <ul  dir={i18n.language == "he" ? "rtl" :"ltr"} className="flex flex-row text-sm justify-between items-center px-3 gap-2 w-full">
        <li className="rounded-sm flex flex-col">
          <Link
            to="/"
            className="flex flex-col items-center p-1  rounded-md"
          >
            <img src={Device} width={27}/>
            <span className="md:block font-bold text-gray-400">
              {t("deviceProfiles")}
            </span>
          </Link>
        </li>
        <li className="rounded-sm">
          <Link
            to="/products"
            className="flex flex-col justify-center items-center p-1  rounded-md"
          >
            <img src={Porduct} width={27}/>
            <span className="md:block font-bold text-gray-400">
              {t("products")}
            </span>
          </Link>
        </li>
        <li className="rounded-sm flex flex-col">
          <Link
            to="/androidkeys"
            className="flex flex-col items-center p-1  rounded-md"
          >
            <img src={Device} width={27}/>
            <span className="md:block font-bold text-gray-400">
              {t("androidDevices")}
            </span>
          </Link>
        </li>
        <li className="rounded-sm">
          <Link
            to="/displays"
            className="flex flex-col justify-center items-center p-1  rounded-md"
          >
            <img src={Display} width={27}/>
            <span className="md:block font-bold text-gray-400">
              {t("displays")}
            </span>
          </Link>
        </li>
        <li className="rounded-sm">
          <Link
            to="/uploads"
            className="flex flex-col justify-center items-center p-1  rounded-md"
          >
            <img src={Display} width={27}/>
            <span className="md:block font-bold text-gray-400">
              {t("uploads")}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Bottombar
