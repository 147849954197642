import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getStoresApi } from "../../../api/storesApi";
import { useState } from "react";
import { getDevices } from "../../../api/deviceApi";
import { addDevice, removeDevice, setAllDevices } from "../../../store";
import i18n from '../../../i18n'
import { useTranslation } from "react-i18next";

const DeviceAddModal = ({storeModal, setStoreModal}) => {
  const playlist = useSelector(state => state.playlist);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {t} = useTranslation();
  const [devices, setDevices] = useState([]);
  const [checkedDevices, setCheckedDevices] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    getDevices(user.token, "", storeModal.store)
      .then((res) => {
        let newDevices = {};
        const ind = playlist.stores.findIndex(store => store.storeId === storeModal.store);
        playlist.stores[ind].devices.forEach(device => {
          newDevices[device] = "checked";
        });
        setCheckedDevices(newDevices);
        setDevices(res);
      })
      .catch(err => console.log(err))
    
  }, []);
  //This code just keeps getting more and more cursed
  //ужас ёбаный
  return (
    <div className="modal bg-black bg-opacity-40">
      <div className="relative  w-full my-6 mx-auto h-[70%] max-w-3xl mt-[50px]">
        <div className="border-2 rounded-lg shadow-lg relative h-[70%] flex flex-col w-full bg-white outline-none focus:outline-none p-9 justify-between items-center">
          <div className="w-full flex flex-row justify-center items-center gap -2">
          <h2 className="text-black font-bold text-2xl">{t("selectAllDevices")}</h2>
          <input 
                type="checkbox"
                className="text-2xl w-[10%]"
                onClick={() => {
                  if(checkAll) {
                    setCheckAll(false);
                    const index = playlist.stores.findIndex(store => store.storeId === storeModal.store);
                    setCheckedDevices({});
                    return dispatch(setAllDevices({index, devices:[]}))
                  }
                  if(!checkAll) {
                    setCheckAll(true);
                    const index = playlist.stores.findIndex(store => store.storeId === storeModal.store);
                    devices.forEach(device => {
                      setCheckedDevices(ckDevices => ({...ckDevices,[device.deviceId]:"checked"}))
                    });
                    dispatch(setAllDevices({
                      index,
                      devices: devices.map(device => device.deviceId)
                    }));
                  }
                }}
                checked={checkAll}
              />
          </div>
          <div className="w-full flex flex-col justify-start items-start gap-2 overflow-scroll">
            {devices.map(device => {
              return <div key={device.deviceId} className="w-full flex flex-row items-center gap-5">
                <input
                  type="checkbox"
                  checked={checkedDevices[device.deviceId] === "checked" ? true : false}
                  className="text-2xl w-[10%]"
                  onClick={() => {
                    if(checkedDevices[device.deviceId] === "checked") {
                      setCheckAll(false);
                      setCheckedDevices(devices => ({...devices, [device.deviceId]: "unchecked"}));
                      const index = playlist.stores.findIndex(store => store.storeId === storeModal.store);
                      dispatch(removeDevice({index, device:device.deviceId}))
                    }
                    if(checkedDevices[device.deviceId] !== "checked") {
                      setCheckedDevices(devices => ({...devices, [device.deviceId]: "checked"}));
                      const index = playlist.stores.findIndex(store => store.storeId === storeModal.store);
                      dispatch(addDevice({index, device:device.deviceId}))
                    }

                  }}
                />
                <h2 className="text-black font-bold text-2xl">{device.deviceId}</h2>
              </div>
            })}
          </div>
          <div className="flex items-center justify-center p-6 border-slate-200 rounded-b">
            <button
              className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
              type="button"
              onClick={() => setStoreModal(modal => ({...modal, showModal: false}))}
            >
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceAddModal