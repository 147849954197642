import React from 'react'
import Edit from '../../static/stylus.png'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const PrintTemplate = ({template}) => {
  const {t} = useTranslation();
  return (
    <div className='flex flex-col justify-center items-center bg-white p-5 gap-3 shadow-md'>
      <div className='flex flex-col justify-center items-center gap-1'>
        <h2 className='text-black font-bold'>{template.name}</h2>
        <h2  className='text-black font-bold'>{t("structure")}: {template.size}</h2>
      </div>
      <div className='flex justify-center items-center gap-1'>
      <Link
          to={`/editprintable`}
          state={{
            template
          }}
        >
          <button className='btn'>
            <img  src={Edit} width={24} alt=''/>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default PrintTemplate