import React from 'react'

const AdminStoreBox = ({store,onRemoveStore}) => {
  return (
    <div  className="flex flex-row p-2 gap-2 rounded-lg shadow-lg bg-slate-600 w-full">
      <button onClick={() => onRemoveStore(store)} className="text-white font-bold hover:cursor-pointer hover:text-slate-200 transition-all">X</button>
      <h2 className={`${"text-white" } font-bold`}>{store}</h2>
    </div>
  )
}

export default AdminStoreBox