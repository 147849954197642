import React, { useEffect, useState } from 'react'
import DeviceItem from '../components/device/DeviceItem'
import { getDevices } from '../api/deviceApi';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../i18n';
import Select from 'react-tailwindcss-select';
import UserSelect from '../components/admin/UserSelect';
import Title from '../components/Title';
import Header from '../components/layout/Header';
import { clearDevice, logoutUser } from '../store';
import DeviceBarcodesModal from '../modals/Device/DeviceBarcodesModal';
import Loading from '../modals/Loading';
import AddIcon from '../static/addB.png'
import CreateDevice from '../modals/Device/CreateDevice';

const Dashboard = () => {
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const loadingState = useSelector(state => state.loading.loading);
  const dispatch = useDispatch();
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const deviceItemsModal = useSelector(state => state.deviceItemsModal);
  const [devices, setDevices] = useState([]);
  const [showAddDevice, setShowAddDevice] = useState(false);
  useEffect(() => {
    if(!showAddDevice) {
      getDevices(user.token, url, selectedStore)
        .then((res) => setDevices(res))
        .catch(err => {
          console.error(err)
          dispatch(logoutUser());
        })
    }
  }, [selectedStore, showAddDevice])

  return (
    <>
      <Header/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"}
        className='h-screen w-full bg-white flex flex-col items-center
          overflow-y-scroll pb-[100px]'
      >
          {loadingState && <Loading/>}
          {deviceItemsModal.showModal && <DeviceBarcodesModal/>}
          {showAddDevice && <CreateDevice setShowModal={setShowAddDevice}/>}
          <div
          className='flex flex-row items-center justify-end w-full gap-3 p-5'
        >
          <button 
            className='border-2 border-[#2D5186] p-2 px-3 rounded-xl text-white font-bold hover:bg-gray-200 transition-all'
            onClick={() => {
              setShowAddDevice(true)
            }}
          >
            <img src={AddIcon}/>
          </button>
        </div>
          <div className='flex flex-col justify-center items-center w-full gap-3 mt-5'>

            {devices.map((device) => 
              <DeviceItem 
                key={device.deviceId}
                deviceId={device.deviceId}
                currentDisplay={device.displayIds[device.DeviceDisplayCounter]}
                online={device.online}
                showId={device.showDeviceId}
                rotateAutomatically={device.rotateAutomatically}
                displays={device.displayIds}
                barcodes={device.barcodes}
                deviceRotation={device.deviceRotation}
              />)
            }
          </div>
      </div>
    </>
  )
}

export default Dashboard