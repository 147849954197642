import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Select from 'react-tailwindcss-select';
import Header from '../components/layout/Header';
import { getPromoByStore, updatePromoSettings } from '../api/promoApi';
import DeleteLogo from '../static/delete.png';
import OperationSuccesfulModal from '../components/layout/OperationSuccesfulModal';

const animationOptions = [
  {label:"Fade In", value:"FadeIn"},
  {label:"Drop In", value:"DropIn"},
  {label:"Slide From The Right", value:"slideInRight"},
  {label:"Slide From The Left", value:"slideInLeft"},
  {label:"None", value:"none"}
]

  const TimeOptions = [
  {label:"30 Minutes", value:0.5},
  {label:"1 Hour", value:1},
  {label:"6 Hours", value:6},
  {label:"12 Hours", value:12},
  {label:"24 Hours", value:24},
  {label:"2 days", value:48},
]



const PromotionSettingsPage = () => {
  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const [filterToAdd, setFilterToAdd] = useState({
    field:"",
    value:""
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [settings, setSettings] = useState({
    animType:"none",
    itemLimit: 50,
    intervalUpdate: 0.5,
    slideInterval: 5,
    filters: {
    }
  });
  const {t} = useTranslation();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const result = await getPromoByStore(user.token, {
        storeId:storeSelection
      })
      setSettings({
        animType: result?.slideType || "none",
        itemLimit:result?.itemAmount || 50,
        intervalUpdate: result?.intervalToUpdate || 0.5,
        filters: result?.filters || {},
        slideInterval: result?.slideInterval || 5
      });
    } catch (err) {
      console.log(err);
    }
  }

  const submitData = async () => {
    try {
      const result = await updatePromoSettings(user.token, {
        slideType: settings.animType,
        itemAmount: settings.itemLimit,
        intervalToUpdate: settings.intervalUpdate,
        storeId: storeSelection,
        filters: settings.filters,
        slideInterval: settings.slideInterval
      });
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData();
  }, [storeSelection]);

  const addFilter = () => {
    setSettings(settings => {
      let newSettings = {...settings}
      newSettings.filters[filterToAdd.field] = filterToAdd.value;
      setFilterToAdd({
        field:"",
        value:""
      })
      return newSettings;
    })
  }

  const onChangeAmount = (e) => {
    if(!isNaN(e.target.value) && parseInt(e.target.value) > 0) {
      setSettings(settings => ({...settings, itemLimit:parseInt(e.target.value)}))
    }
  }

  return (
    <>
      <Header showBack={true} backTitle={""}/>
      {showSuccessMessage && <OperationSuccesfulModal/>}
      <div className='w-full h-full flex flex-col items-center overflow-scroll gap-5 p-2'>
        <h2 className='text-black font-bold text-2xl'>Promo slideshow Settings</h2>
        <div className='w-[50%] flex flex-col gap-2'>
          <h2 className='text-black font-bold text-xl'>Transition Type:</h2>
          <Select
            value={animationOptions.find(anim => anim.value === settings.animType)}
            options={animationOptions}
            onChange={(selection)=> setSettings(settings => ({...settings, animType: selection.value}))}
            classNames={{
              menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
              menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
        </div>
        <div className='w-[50%] flex flex-col gap-2'>
          <h2 className='text-black font-bold text-xl'>Update Time:</h2>
          <Select
            value={TimeOptions.find(time => time.value === settings.intervalUpdate)}
            options={TimeOptions}
            onChange={(selection)=> setSettings(settings => ({...settings, intervalUpdate: selection.value}))}
            classNames={{
              menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
              menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
        </div>
        <div className='w-[50%] flex flex-col gap-2'>
          <h2 className='text-black font-bold text-xl'>slide Switch Time (in Seconds)</h2>
          <input
            className='w-[100%] rounded-lg p-2   border-2'
            value={settings.slideInterval}
            type='number'
            onChange={(e) => {
              if(isNaN(e.target.value) || parseInt(e.target.value) <= 0) {
                return;
              }
              setSettings(settings => ({...settings, "slideInterval":parseInt(e.target.value)}))
            }}
          />
        </div>
        <div className='w-[50%] flex flex-col gap-2'>
          <h2 className='text-black font-bold text-xl'>Item Amount Limit:</h2>
          <input
            type='Number'
            value={settings.itemLimit}
            onChange={onChangeAmount}
            className='w-full p-2 rounded-lg border-[1px] border-gray-400'
          />
        </div>
        <div className='w-[50%] flex flex-col gap-2'>
          <h2 className='text-black font-extrabold text-xl'>Add Filter:</h2>
          <div className='w-full flex gap-2'>
            <div className='w-full flex flex-col'>
              <h2>Field To Filter</h2>
              <input
                value={filterToAdd.field}
                type='text'
                className='w-full p-2 rounded-lg border-[1px] border-gray-400'
                onChange={(e) => setFilterToAdd(filterToAdd => ({...filterToAdd, field:e.target.value})) }
              />
            </div>
            <div className='h-full flex flex-col justify-center'>
              <h2 className='text-black font-extrabold text-3xl mt-[50%]'>=</h2>
            </div>
            <div className='w-full flex flex-col'>
              <h2>Filter Value</h2>
              <input
                value={filterToAdd.value}
                type='text'
                className='w-full p-2 rounded-lg border-[1px] border-gray-400'
                onChange={(e) => setFilterToAdd(filterToAdd => ({...filterToAdd, value:e.target.value})) }
              />  
            </div>
            <button 
              className='btn mt-5 hover:bg-blue-600 transition-all !px-5'
              onClick={addFilter}
            >
              +
            </button>
          </div>
          <h2 className='text-black font-extrabold text-xl'>Current Filters:</h2>
          <div className='w-full flex flex-col gap-2 items-center'>
            {settings.filters && Object.keys(settings.filters).map(filter =>  <div className='bg-gray-200 p-2 w-full  rounded-lg flex items-center justify-center gap-2'>
            <div className='flex-1  flex items-center p-2 rounded-lg'>
              <h2 className='font-bold'>{filter}</h2>
            </div>
            <div className='flex-1/2 h-full flex flex-col justify-center'>
              <h2 className='text-black font-extrabold text-xl '>=</h2>
            </div>
            <div className='flex-1 flex items-center p-2 rounded-lg'>
              <h2 className='font-bold'>{settings.filters[filter]}</h2>
            </div>
            <button 
              className='flex-1/2'
              onClick={() => {
                setSettings(settings => {
                  let newSettings = {...settings}
                  delete newSettings.filters[filter];
                  return newSettings;
                })
              }}
            >
              <img
                src={DeleteLogo}
              />
            </button>
          </div>)}
          </div>
        </div>
        <button 
          className='btn mt-5 w-[51%] hover:bg-blue-600 transition-all'
          onClick={submitData}
        >
          {t("save")}
        </button>
      </div>
    </>
  )
}

export default PromotionSettingsPage