import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { createDeviceApi } from '../../api/deviceApi';
import CloseIcon from '../../static/crossB.png';
import SaveIcon from '../../static/floppy-disk.png';

const CreateDevice = ({setShowModal}) => {
  const [id, setId] = useState("");
  const [error, setError] = useState("");
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  
  const submitNewDevice = async () => {
    try {
      await createDeviceApi(user.token, id, selectedStore);
      setShowModal(false);
    } catch (err) {
      setError("error creating new playlist");
      setTimeout(() => {
        setError("");
      }, 3000);
      console.log(err);
    }
  }

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Playlist Name
              </p>
              <input 
                className='w-[70%] rounded-lg p-3  text-black font-bold border-2'
                type='text'
                name="playlistName"
                onChange={(e) => setId(e.target.value)}
              />
            </div>
            <div className='w-full flex-col flex justify-center items-center'>
              <h2 className='text-red-500 font-bold m-2'>{error}</h2>
            </div>
            <div className="w-full flex flex-col items-center justify-start gap-2 ">
              <div className="flex flex-row justify-center gap-10 w-[100%]">
                <button
                  onClick={submitNewDevice}
                  className='border-2 p-1 border-[#2D5186] rounded-lg hover:bg-gray-100 transition-all'
                >
                  <img src={SaveIcon} className='w-8'/>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default CreateDevice