import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import ViewDetailsIcon from '../../static/view-detailsW.png';
import OnlineIcon from '../../static/green.png';
import OfflineIcon from '../../static/red.png';
import RefershIcon from '../../static/refreshW.png';
import LoadingAnimation from '../../static/loading.gif';
import { refreshAndroidDevice } from '../../api/AndroidKeyApi';
import { useSelector } from 'react-redux';

const AndroidDeviceBox = ({androidDevice, deleteFunc}) => {

  const user = useSelector(state => state.user);
  const [refreshLoading,setRefreshLoading] = useState(false);

  return (
    <div className='px-2 flex items-center justify-center bg-gray-400 rounded-md gap-2  x overflow-clip'>
      <img
        src={androidDevice.connected ? OnlineIcon : OfflineIcon}
        className='w-4 mt-1'
      />
      <button 
        onClick={deleteFunc}
        className='text-gray-100 font-bold text-2xl hover:text-gray-300 transition-all'
      >
        X
      </button>
      <div className='flex flex-col w-[50%] overflow-clip'>
        <h2 className='text-white '>{androidDevice.code}</h2>
        <h2 className='text-white '>{androidDevice.name}</h2>
      </div>
      <Link to={`/androidDevice/${androidDevice.code}`}>
        <img src={ViewDetailsIcon} className='w-5' />
      </Link>
      {!refreshLoading ? <button
        onClick={async () => {
          setRefreshLoading(true);
          await refreshAndroidDevice(user.token,{mac: androidDevice.mac})
          setRefreshLoading(false);
        }}
      >
        <img src={RefershIcon} className='w-6'/>
      </button>: <img src={LoadingAnimation} className='w-6' />}
    </div>
  )
}

export default AndroidDeviceBox