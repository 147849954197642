import axios from "axios";

export const getPlaylistApi = async (token, selectedStore) => {
  const res = await axios.get(
    `https://backend.konekt.vip/playlist?storeId=${selectedStore}`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const getPlaylistByIdApi = async (token, playlistId) => {
  const res = await axios.get(
    `https://backend.konekt.vip/playlist/getPlaylistInfo?id=${playlistId}`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const addPlayListApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/playlist/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const modifyPlayListApi = async (token, data) => {
  const res = await axios.put(
    `https://backend.konekt.vip/playlist/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const sendPlayListApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/playlist/sendPlaylist`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const deletePlaylistApi = async (token, playListId) => {
  const res = await axios.delete(
    `https://backend.konekt.vip/playlist/deletePlaylist?id=${playListId}`,
    {
      headers: {
        token: `bearer ${token}`
      }
    }
  )
  return res.data;
}