import React, { useState } from 'react'
import Edit from '../../static/stylus.png'
import EditLicense from '../../modals/License/EditLicense';
import { useTranslation } from 'react-i18next';
const LicenseItem = ({licenseId, deviceId}) => {
  const [device, setDevice] = useState(deviceId);
  const [showLicenseEdit, setShowLicenseEdit] = useState(false);
  const {t} = useTranslation()
  return (
    <div className='flex flex-col justify-center items-center bg-white p-10 gap-3 shadow-md'>
      {showLicenseEdit && <EditLicense  deviceId={deviceId} licenseId={licenseId} setShowModal={setShowLicenseEdit} setDeviceEmit={setDevice}/>}
      <div className='flex flex-col justify-center items-center gap-1'>
        <h2 className='text-black font-bold'>{t("licenseId")}: {licenseId}</h2>
        <h2 className='text-black font-bold'>{t("pairedTo")}: {device ? device : "Unpaired"}</h2>
      </div>
      <div>
        <button 
          className='p-2'
          onClick={() => setShowLicenseEdit(true)}
        >
          <img src={Edit}/>
        </button>
      </div>
    </div>
  )
}

export default LicenseItem