import React, { useEffect, useState } from 'react'
import Search from '../static/search.png'
import { searchItems } from '../api/itemsApi';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../modals/Loading';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import PrintWhiteLogo from '../static/printerWhite.png';
import Header from '../components/layout/Header';
import Select from 'react-tailwindcss-select';
import Print from '../components/prints/Print';
import { Link } from 'react-router-dom';
import File from '../static/file.png';

import { getPrintingList, updatePrintingList } from '../api/printingListApi';
import { logoutUser } from '../store';
const sizes = [
  {label:"טמפלייט 122 רבע דף", value:"quarter"},
  {label:"טמפלייט 122 חצי דף", value:"half"},
  {label:"טמפלייט 122 דף שלם", value:"full"},
]

const templatesList = [
  {label:"ללא סינון", value:undefined},
  {label:"Temp1", value:"Temp1"},
  {label:"Temp2", value:"Temp2"},
  {label:"Temp3", value:"Temp3"},
  {label:"Temp4", value:"Temp4"},
  {label:"Temp5", value:"Temp5"},
  {label:"Temp6", value:"Temp6"},
  {label:"Temp7", value:"Temp7"},
  {label:"Temp8", value:"Temp8"},
  {label:"Temp9", value:"Temp9"},
]

const departmentList = [
  {label:"פירות וירקות", value:"fruits&Vegtables"},
  {label:"חלבי", value:"dairy"},
  {label:"בשרי", value:"meat"},
  {label:"אחר", value:"bakedgoods"},
  {label:"ללא סינון", value:undefined}
]
const sizesLookup = {
  "quarter":"טמפלייט 122 רבע דף",
  "half":"טמפלייט 122 חצי דף",
  "full":"טמפלייט 122 דף שלם"
}

const departmentLookup = {
  "fruits&Vegtables":"פירות וירקות",
  "dairy":"חלבי",
  "meat":"בשרי",
  "bakedgoods":"מאפים",
  "other":"אחר",
  "all":""
}

const templateFilterLookup = {
  "Temp1":"Temp1",
  "Temp2":"Temp2",
  "Temp3":"Temp3",
  "Temp4":"Temp4",
  "Temp5":"Temp5",
  "Temp6":"Temp6",
  "Temp7":"Temp7",
  "Temp8":"Temp8",
  "Temp9":"Temp9",
}

const PrintPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [barcodes, setBarcodes] = useState([]);
  const dispatch = useDispatch();
  const [size, setSize] = useState("quarter");
  const [items, setItems] = useState([]);
  const [displayingPrintList, setDisplayingPrintList] = useState(false);
  const [additionalParams, setAdditionalParams] = useState({});
  const storeFilter = useSelector(state => state.storeSelection.selectedStore);
  const {t} = useTranslation()
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  
  useEffect(() => {
    getPrintingList(user.token)
      .then((res) => {
        console.log(res)
        setBarcodes(res)
      })
      .catch((err) => console.log(err));
  }, []);

 
  

  const addBarcode = async (barcode) => {
    try {
      const newList = await updatePrintingList(
        user.token,
        {
          list:[...barcodes, barcode]
        }
      );
      setBarcodes(newList);
      if(displayingPrintList) {
        setItems(items => items.filter(item => newList.includes(item.barcode) > 0))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const removeBarcode = async (barcodeToRemove) => {
    try {
      let indexToRemove = barcodes.indexOf(barcodeToRemove);
      const newList = await updatePrintingList(
        user.token,
        {
          list: barcodes.filter((barcode, index) => index !== indexToRemove)
        }
      )
      setBarcodes(newList);
      if(displayingPrintList) {
        setItems(items => items.filter(item => newList.includes(item.barcode) > 0))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const removeAllInstancesOfBarcode = async(barcodeToRemove) => {
    try {
      const newList = await updatePrintingList(
        user.token,
        {
          list: barcodes.filter((barcode) => barcode !== barcodeToRemove)
        }
      )
      setBarcodes(newList);
      if(displayingPrintList) {
        setItems(items => items.filter(item => newList.includes(item.barcode) > 0))
      }
    } catch (err) {
      console.log(err);
    }
  }

  
  const onDisplayPrintList = async () => {
    setLoading(true);
    setDisplayingPrintList(true);
    try {
      const res = await searchItems(
        user.token,
        url,
        "",
        storeFilter
      );
      const listItems = res.filter((item) => {
        return barcodes.includes(item.barcode)
      });
      setItems(listItems);
      setLoading(false);
    } catch (err) {
      console.log(err);
      dispatch(logoutUser());
    }
  }

  const onSearch = async () => {
    setLoading(true);
    setDisplayingPrintList(false);
    try {
      const res = await searchItems(
        user.token,
        url,
        searchTerm,
        storeFilter,
        additionalParams.temp,
        additionalParams.department
      );
      setItems(res);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <Header showBack={true} backTitle={""}/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='h-full w-full items-center bg-gray-100 flex flex-col items- overflow-clip gap-4 p-2 '>
        {loading && <Loading/>}
        <div className='p-5 flex flex-col  shadow-md w-[100%] md:w-[100%] justify-center gap-4 items-start rounded-lg bg-[#2D5186]'>
          <h2 className='font-bold text-white text-2xl'>{t("smartPrint")}</h2>
          <Select
            options={sizes}
            value={{"label":sizesLookup[size], value:size}}
            onChange={(selection)=> {
              setSize(selection.value);
            }}
            classNames={{
              menu:"absolute w-[100%] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
              menuButton:() => "flex flex-row w-[100%] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />
          
          <div className='flex flex-row justify-start items-center w-[100%] gap-2'>
            <div className='flex flex-row justify-start items-center w-[25%] gap-2'>
              <h2 className='text-white font-bold text-xl'>תבנית:</h2>
              <Select
                options={templatesList}
                value={{"label":templateFilterLookup[additionalParams.temp], value:additionalParams.temp}}
                onChange={(selection)=> {
                  setAdditionalParams(params => ({...params, temp:selection.value}))
                }}
                classNames={{
                  menu:"absolute w-[80%] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[80%] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
            <div className='flex flex-row justify-start items-center w-[25%] gap-2'>
              <h2 className='text-white font-bold text-xl'>מחלקה:</h2>
              <Select
                options={departmentList}
                value={{"label":departmentLookup[additionalParams.department], value:additionalParams.department}}
                onChange={(selection)=> {
                  setAdditionalParams(params => ({...params, department:selection.value}))
                }}
                classNames={{
                  menu:"absolute w-[80%] h-[200px]  bg-white border-2 border-black overflow-scroll z-50",
                  menuButton:() => "flex flex-row w-[80%] h-[40px] bg-white border-2 border-black px-2 justify-center z-50",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-50 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between w-[100%] gap-5'>
            <input 
              className=" rounded-md block w-full h-10 pl-8  text-black bg-white
              border-b-2 border-black  focus:ring-none outline-none"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if(e.key == "Enter") {
                  onSearch();
                }
              }}
            />
            <button className='btn' onClick={onSearch}>
              <img src={Search} className='w-[25px]'/>
            </button>
            <button className='btn' onClick={onDisplayPrintList}>
              <img src={File} className='w-[25px]'/>
            </button>
            {barcodes && barcodes.length > 0 && <Link to="/multiplePrint" state={{barcodes:barcodes, size, storeId:storeFilter}}>
              <button className='btn' onClick={onSearch}>
                <img src={PrintWhiteLogo} className='w-[30px]'/>
              </button>
            </Link>}
          </div>
        </div>
        {items && items.length > 0 && 
          <div 
            className='bg-white p-5 flex flex-col 
              shadow-md  w-[100%] justify-start gap-4 items-center rounded-lg overflow-scroll'
          >
          {items.map(item => 
            <Print 
              item={item}
              storeId={storeFilter}
              size={size}
              addBarcode={addBarcode}
              removeBarcode={removeBarcode}
              deleteAllBarcodes={removeAllInstancesOfBarcode}
              displayingPrintList={displayingPrintList}
              barcodes={barcodes}
            />
            )}
        </div>}
      </div>
    </>
  )
}

export default PrintPage;