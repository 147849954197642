import axios from 'axios';

export const getUsersApi = async (token) => {
  const res = await axios.get(
    `https://backend.konekt.vip/user/`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}

export const createAdminApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/user/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  )
  return res.data;
}