import React, { useEffect, useState } from 'react'
import Header from '../components/layout/Header'
import { addTemplateSize, deleteTemplateSize, getTemplateSizes } from '../api/TempSizesApi';
import { useSelector } from 'react-redux';
import DeleteIcon from '../static/delete_black.png';
import DeleteDialog from '../modals/deleteDialog/DeleteDialog';

const TemplateSizePage = () => {
  const [templateSizes, setTemplateSizes] = useState([]);
  const [newTemplateSize, setNewTemplateSize] = useState("");
  const [templateDelete,setTemplateDelete] = useState({
    size:"",
    showModal: false
  });

  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);
  const fetchTemplateSizes = async () => {
    try {
      const res = await getTemplateSizes(user.token, {storeId: storeSelection});
      setTemplateSizes(res);
    } catch (err) {
      console.log(err);
    }
  }

  const deleteTemplate = async () => {
    try {
      const res = await deleteTemplateSize(user.token, {storeId: storeSelection, size: templateDelete.size});
      setTemplateDelete({size:"", showModal:false});

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if(!templateDelete || !templateDelete.showModal) {
      fetchTemplateSizes();
    }
  }, [storeSelection, templateDelete])
  return (
    <>
      {templateDelete && templateDelete.showModal &&  
        <DeleteDialog 
          text={"Are you sure you want To delete this Size? This cannot be undone."} 
          setShowModal={setTemplateDelete}
          deleteFunc={deleteTemplate} 
      />}
      <Header showBack={true} backTitle={""}/>
      <div className='w-full h-full flex flex-col items-center overflow-scroll mb-16 md:mb-0 gap-5 p-5'>
        <h2 className='font-bold text-4xl'>Template Sizes</h2>
        <div className='flex flex-col h-[40%] overflow-scroll w-[40%] bg-gray-200 rounded-xl border-4 gap-2 border-black p-2'>
          {templateSizes.map((t) => <div className='w-full p-2 bg-gray-100 flex items-center 
            justify-between font-bold text-2xl  rounded-lg border-b-2 border-gray-300'
          >
            <h2>{t.size}</h2>
            <img 
              src={DeleteIcon} 
              onClick={() => {
                setTemplateDelete({
                  showModal: true,
                  size: t.size
                });
              }}
            />
          </div>)}
        </div>
        <div className='w-full flex flex-col items-center gap-2'>
          <h2 className='font-bold text-2xl'>add Size</h2>
          <input
            type='text'
            className='w-[40%] p-2 font-semibold bg-gray-100 rounded-lg border-2 border-black'
            onChange={(e) => {
              setNewTemplateSize(e.target.value);
            }}
            value={newTemplateSize}
          />
          <button className='btn'
            onClick={async () => {
              try {
                await addTemplateSize(
                  user.token, 
                  {
                    storeId: storeSelection,
                    size: newTemplateSize
                  }
                );
                setTemplateSizes(szs => {
                  const szToAdd = newTemplateSize;
                  setNewTemplateSize("");
                  return [...szs, {size:szToAdd, storeId: storeSelection}]
                });
              } catch (err) {
                console.log(err)
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  )
}

export default TemplateSizePage