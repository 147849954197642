import axios from "axios";
import { forwardRef, useEffect, useState } from "react";

const RenderTest = forwardRef(({barcode, storeId , size}, ref) => {
  const [html, setHtml] = useState(null);
  console.log(html)
  useEffect(() => {
    axios.get("http://localhost:8000/template/example")
      .then(res => setHtml(res.data))
      .catch(err => console.log(err))
  }, [])

  if(!html) {
    return <div>Empty</div>
  }
  return <div dangerouslySetInnerHTML={{__html:html}} ref={ref}>
  </div>
})

export default RenderTest;