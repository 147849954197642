import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { getTemplate } from '../api/printTemplatesApi';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../store';

const CreateNewPrintableTemplate = () => {
  let refdiv=useRef(null);
  let rte;
  const {state} = useLocation();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  function btnclick(){
    alert(rte.getHTMLCode());
  }

  // setTimeout(function(){
  //   rte=new window.RichTextEditor(refdiv.current);
  //   rte.setHTMLCode("");
  // },0)

  useEffect(() => {
    rte=new window.RichTextEditor(refdiv.current);
    rte.setHTMLCode("");
    if(state?.template) {
      getTemplate(user.token, state?.template?.name)
        .then(res => {
          rte.setHTMLCode(res.html)
        })
        .catch(err => {
          dispatch(logoutUser());
          console.log(err)
        });

    }
  }, [])

  return (
    <div>
      <header>
        <div ref={refdiv}></div>
        
        <hr/>
        <div><button onClick={btnclick}>Show HTML Code</button></div>
        
      </header>
    </div>
  );
}

export default CreateNewPrintableTemplate