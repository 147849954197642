import axios from "axios";

export const addImageToZkong = async (token, data) => {
  const result = await axios.post(`https://backend.konekt.vip/zkong/uploadImageZkong`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return result.data;
}