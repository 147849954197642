//This editor page is a huge fucking mess

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { addDisplayApi, getDisplayApi } from '../api/displayApi';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-tailwindcss-select';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { uploadImage, uploadVideo } from '../api/uploadApi';
import Upload from '../static/upload.png'
import Header from '../components/layout/Header';
import { logoutUser } from '../store';
import EditorPreviewModal from '../components/Editor/EditorPreviewModal';
import { getPromoByStore } from '../api/promoApi';
import UploadDisplayEditModal from '../modals/upload/UploadDisplayEditModal';
import { getTemplateSizes } from '../api/TempSizesApi';

const types = [
  {label:'view(legacy)', value:'view'},
  {label:'img', value:'img'},
  {label:'video', value:'video'},
  {label:"powerpoint", value:"powerpoint"},
  {label:"Youtube", value:"Iframe"},
  {label:"url", value:"url"},
  {label:"dynamicVideo", value:"dynamicVideo"},
  {label:"dynamicImg", value:"dynamicImg"},
  {label:"Dynamic URL", value:"dynamicIframe"},
  {label:"dynamicPowerPoint", value:"dynamicPowerPoint"},
  {label:"dynamic PDF", value:"dynamicPDF"},
  {label:"viewTemplate", value:"viewTemplate"},
  {label:"Promotion", value:"promotion"},
  {label:"PriceChecker", value:"priceChecker"}
]

const typeDic = {
  "img":"img",
  "Iframe":"Youtube",
  "video":"video",
  "view":"view(legacy)",
  "powerpoint":"powerpoint",
  "dynamicVideo":"dynamic Video",
  "dynamicImg":"dynamic Image",
  "dynamicIframe":"dynamic URL",
  "dynamicPowerPoint":"dynamic PowerPoint",
  "dynamicPDF":"dynamic PDF",
  "viewTemplate":"ViewTemplate",
  "url":"URL",
  "promotion":"Promotion",
  "priceChecker":"PriceChecker"
}

const DisplayEditPage = () => {
  const {displayId} = useParams();
  
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);

  const dispatch = useDispatch();
  const [showUploadModal, setShowUploadModal] = useState({
    type:"",
    show: false
  });
  

  const setUploadedContent = (upd) => {
    setCurrElement(currElement => ({...currElement, ["content"]:upd}));
  }
  const [tempSizes, setTempSizes] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [currElement, setCurrElement] = useState(null);
  const [currElementIndex, setCurrElementIndex] = useState(null);
  const [display, setDisplay] = useState(null);
  
  const fetchTemplateSizes = async () => {
    try {
      const res = await getTemplateSizes(user.token, {storeId: selectedStore});
      setTempSizes(res);
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    fetchTemplateSizes();
  }, [])
  
  // I should really finish that already
  const [error, setError] = useState("")
  const [showAddElement, setShowAddElement] = useState(false);
  const updateStyle = (attribute, value) => {
    setDisplay(display => {
      const newDisplay = JSON.parse(JSON.stringify(display));
      newDisplay.elements[currElementIndex].style[attribute] = value;
      setCurrElement(newDisplay.elements[currElementIndex]);
      return newDisplay;
    })
  }

  const updateAttribute = (attribute, value) => {
    setDisplay(display => {
      const newDisplay = JSON.parse(JSON.stringify(display)); 
      newDisplay.elements[currElementIndex][attribute] = value;
      setCurrElement(newDisplay.elements[currElementIndex]);
      return newDisplay;
    })
  }

  const EditOuterField = (title, attribute, type) => {
    const doActionDecrease = (newAtribute, amount, type = "value") => {
      if(type =="percent") {
        updateAttribute(attribute, `${parseFloat(newAtribute|| 0) - amount}%`);
      }
      if(type =="value") {
        updateAttribute(attribute, parseFloat(newAtribute || 0) - amount);
      }
    }
    const doActionIncrease = (newAtribute, amount, type = "value") => {
      if(type =="percent") {
        updateAttribute(attribute, `${parseFloat(newAtribute || 0) + amount}%`);
      }
      if(type =="value") {
        updateAttribute(attribute, parseFloat(newAtribute || 0) + amount);
      }
    }
    return (
      <div className='flex w-[90%] gap-2 items-center justify-between'>
        <h2 className='font-bold text-md'>{title}:{" " + currElement[attribute]} {type == "percent" && "%"}</h2>
        <div className='flex gap-2'>
          <button
            className='btn disabled:opacity-40'
            disabled={currElement[attribute] && currElement[attribute] <= 0}
            onClick={() => {
              const newAtribute = currElement[attribute];
              doActionDecrease(newAtribute, 1);
            }}
          >
            {`<`}
          </button>
          <button
            className='btn disabled:opacity-40'
            disabled={currElement[attribute] && currElement[attribute] >= 100}
            onClick={() => {
              const newAtribute = currElement[attribute];
              doActionIncrease(newAtribute, 1);
            }}
          >
            {`>`}
          </button>
        </div>
    </div>)

  }

  const editField = (title, attribute, type) => {
    const doActionDecrease = (newAtribute, amount) => {
      if(type =="percent") {
        updateStyle(attribute, `${parseFloat(newAtribute) - amount}%`);
      }
      if(type =="value") {
        updateStyle(attribute, `${parseFloat(newAtribute) - amount}`);
      }
    }
    const doActionIncrease = (newAtribute, amount) => {
      if(type =="percent") {
        updateStyle(attribute, `${parseFloat(newAtribute) + amount}%`);
      }
      if(type =="value") {
        updateStyle(attribute, `${parseFloat(newAtribute) + amount}`);
      }
    }
    return (
      <div className='flex w-[90%] gap-2 items-center justify-between'>
        <h2 className='font-bold text-md'>{title}:{" " +currElement.style[attribute]}</h2>
        <div className='flex gap-2'>
          <button
            className='btn'
            onClick={() => {
              const newAtribute = currElement.style[attribute];
              doActionDecrease(newAtribute, 0.5);
            }}
          >
            {`<`}
          </button>
          <button
            className='btn'
            onClick={() => {
              const newAtribute = currElement.style[attribute];
              doActionIncrease(newAtribute, 0.5);
            }}
          >
            {`>`}
          </button>
        </div>
    </div>)
  }
  
  const {t} = useTranslation();

  const selectElement = (index) => {
    setCurrElement(display.elements[index]);
    setCurrElement((element) => {
      if(element.type === "view") {
        element.content = "";
      }
      return element;
    })
    setCurrElementIndex(index);
  }

  const setElementAsFocus = () => {
    setDisplay(dsp => {
      dsp.elements = dsp?.elements?.map((elem, index) => {
        if(index == currElementIndex && elem) {
          elem.focus = true;
        }
        else if(elem) {
          elem.focus = false;
        }
        return elem;
      });
      return dsp;
    });
    setCurrElement(elem => ({...currElement, ["focus"]: true}))
  }

  const setElementAsNoneFocus = () => {
    setCurrElement(currElement => ({...currElement, ["focus"]:false}));
  }

  const saveDisplayFunc = async (elementOverride) => {
    const displayToSave = display;
    // *Gorden Ramsey voice* FUCKING RAW!
    if(elementOverride) {
      displayToSave.elements[currElementIndex] = elementOverride;
    }
    if(!elementOverride) {
      displayToSave.elements[currElementIndex] = currElement;
    }
    try {
      setDisplay(null);
      const res = await addDisplayApi(user.token, url, displayToSave);
      setDisplay(res);
    } catch (err) {
      console.log(err)
    }
  }

  const deepMapElements = (elements, index) => {
    if(elements.type === "view") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("view")}</h2>
      </div>
    }
    if(elements.type === "viewTemplate") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("view")}</h2>
      </div>
    }
    if(elements.type === "dynamicImg") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Image</h2>
      </div>
    }
    if(elements.type === "priceChecker") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Price Checker</h2>
      </div>
    }
    if(elements.type === "promotion") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Price Checker</h2>
      </div>
    }
    if(elements.type === "dynamicVideo") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Video</h2>
      </div>
    }
    if(elements.type === "dynamicIframe") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Iframe</h2>
      </div>
    }
    if(elements.type === "dynamicPowerPoint") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic PowerPoint</h2>
      </div>
    }
    if(elements.type === "Iframe") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Iframe</h2>
      </div>
    }
    if(elements.type === "url") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Snap Link</h2>
      </div>
    }
    if(elements.type === "powerpoint") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic Powerpoint</h2>
      </div>
    }
    if(elements.type === "promotion") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Promotion SlideShow</h2>
      </div>
    }
    if(elements.type === "dynamicPDF") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>Dynamic PDF</h2>
      </div>
    }
    if(elements.type === "img") {
      return <div 
        className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
        style={elements.style}
        onClick={() => selectElement(index)}
        key={index}
      >
        <img src={elements.content} />
        <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("image")}</h2>
      </div>
    }
    if(elements.type === 'video') {
      return (
        <div 
          className={`bg-gray-800 opacity-75 flex justify-center ${index === currElementIndex && `border-2 border-white`}`}
          style={elements.style}
          onClick={() => selectElement(index)}
          key={index}
        >
          {<video key={index} onClick={() => selectElement(index)} style={{...elements.style, width:"100%", height:"100%", top:0, left:0}} autoPlay muted loop className={`${index === currElementIndex && `border-2 border-white`}`}>
            <source src={elements.content} type="video/webm" />
          </video>}
          <h2 className='absolute  top-[50%] text-white text-2xl font-bold'>{t("video")}</h2>
        </div>)
    }
    if(elements.type === "text") {
      return <p 
        dir='rtl'
        className={`${index === currElementIndex && `border-2 border-white`}`}
        onClick={() => selectElement(index)}
        style={elements.style}
      >
        {elements.content}
      </p>
    }
  }

  
  useEffect(() => {
    getDisplayApi(user.token,url, displayId, selectedStore)
      .then(res => {console.log(res); setDisplay(res)})
      .catch(err => {
        dispatch(logoutUser());
        console.log(err)
      });
  }, [])
  

  
  return (
    <>
      {showPreviewModal && <EditorPreviewModal setShowModal={setShowPreviewModal} displayId={displayId}/>}
      {showUploadModal.show && <UploadDisplayEditModal setElementContent={setUploadedContent} setShowModal={setShowUploadModal} type={showUploadModal.type}/>}
      <Header/>
      <div  className='relative bg-black w-full p-10 h-full flex flex-row justify-start gap-5'>
        <div className='p-10 bg-black relative flex flex-col w-[80%] h-full'>
          {display && display?.elements.map((element, index) => deepMapElements(element, index))}
        </div>
        <div className='absolute flex flex-col items-center bg-white shadow-md w-[22%] h-[100%] left-[79%] top-[0%] p-5 gap-5 overflow-scroll'>
          <h2 className='text-black font-bold text-2xl'>{t("elementDetails")}</h2>
          {currElement && <>
            <div className="flex flex-col justify-center text-center mt-5">
              <label
                className="block text-xl font-bold text-black"
              >
                {t("content")}
              </label>
              {currElement.type == "img" && <div className='relative'>
                <label
                  className='w-15 h-5 absolute top-[10px] left-[0%] cursor-pointer'
                  onClick={() => {
                    setShowUploadModal({
                      show:true,
                      type:"image"
                    })
                  }}
                >
                  <img src={Upload} width={20}/>
                </label>
              </div>}
              {currElement.type == "video" && <div className='relative'>
                <label
                  className='w-15 h-5 absolute top-[10px] left-[0%] cursor-pointer'
                  onClick={() => {
                    setShowUploadModal({
                      show:true,
                      type:"video"
                    })
                  }}
                >
                  <img src={Upload} width={20}/>
                </label>
              </div>}
              <input
                type="text"
                name="content"
                value={currElement.content}
                onChange={(e) => setCurrElement(currElement => ({...currElement, ["content"]:e.target.value}))}
                className="h-10 text-black bg-white border-b-2 border-black  focus:ring-none outline-none pl-6"
              />
            </div>
            
            <div className='w-full flex flex-col items-center justify-center gap-2'>
              <h2 className='font-bold text-lg'>Element Id</h2>
              <input
                type='text'
                name="id"
                value={currElement.id ? currElement.id : currElement.type+currElementIndex }
                onChange={(e) => setCurrElement(currElement => ({...currElement, ["id"]:e.target.value}))}
                className="h-10 text-black bg-white border-b-2 border-black  focus:ring-none outline-none pl-6"
              />
            </div>
            {display && <div className='w-full flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name='timedDisplay'
                className='p-2 mt-1'
                checked={display.timedDisplay}
                onChange={() => {
                  setDisplay((dsp) => {
                    const newDisplay = JSON.parse(JSON.stringify(dsp));
                    newDisplay.timedDisplay = newDisplay.timedDisplay ? false : true;
                    return newDisplay;
                  })
                 
                  
                }}
              />
              <h2 className='font-bold text-lg'>Timed Display</h2>
            </div>}
            
            <div className="flex flex-col justify-center text-center gap-5 mt-5">
              <label
                className="block text-xl font-bold text-black"
              >
                {t("type")}
              </label>
              <Select
                options={
                  types
                }
                value={(() => ({label:typeDic[currElement.type]}))()}
                onChange={async (selection)=> {
                  setCurrElement(currElement => ({...currElement, type: selection.value, content:""}))
                  if(selection.value == "promotion") {
                    setCurrElement(element => ({...element, content: currElement.size 
                      ? `http://188.166.77.5:3002/${display?.storeId}/${currElement.size}` 
                      : `http://188.166.77.5:3002/${display?.storeId}`}));
                  }
                  if(selection.value == "priceChecker") {
                    setCurrElement(element => ({...element, content:`http://206.189.111.216:3005/${display?.storeId}`}));

                  }
                }}
                classNames={{
                  menu:"absolute w-[200px] h-[200px]  bg-white border-2 border-black overflow-scroll z-10",
                  menuButton:() => "flex flex-row w-[200px] bg-white border-2 border-black px-2 justify-between z-10",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                        isSelected
                            ? `text-white bg-blue-500`
                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
              {/* This is such dogshit, this thing is already too fucking long */}
              {currElement.type == "promotion" && <>
                <Select
                  options={tempSizes.map(t => ({label: t.size, value: t.size}))}
                  value={currElement.size ? {label:currElement.size, value: currElement.size} : {label: "...", value: "..."}}
                  onChange={async (selection)=> {  
                    setCurrElement(element => ({...element,
                      content:`http://188.166.77.5:3002/${display?.storeId}/${selection.value}`}));
                    setCurrElement(ce =>({...ce, size: selection.value}));
                  }}
                  classNames={{
                    menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-black overflow-scroll z-10",
                    menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-black px-2 justify-between z-10",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </>}
              {currElement.type == "viewTemplate" &&
                <>
                  <h2 className='font-bold text-xl'>View Size</h2>
                  <Select
                    options={tempSizes.map(t => ({label: t.size, value: t.size}))}
                    value={currElement.size ? {label:currElement.size, value: currElement.size} : {label: "...", value: "..."}}
                    onChange={(selection)=> setCurrElement(ce =>({...ce, size: selection.value}))}
                    classNames={{
                      menu:"absolute w-[100%]  h-[250px]  bg-white border-[1px] border-black overflow-scroll z-10",
                      menuButton:() => "flex flex-row w-[100%]   bg-white border-[1px] border-black px-2 justify-between z-10",
                      listItem: ({ isSelected }) => (
                        `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                            isSelected
                                ? `text-white bg-blue-500`
                                : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                        }`
                      )
                    }}
                  />
                </>
              }
            </div>
            {editField("Left Position", "left","percent")}
            {editField("Top Position", "top","percent")}
            {editField("Height", "height","percent")}
            {editField("Width", "width","percent")}
            {editField("Border Radius", "borderRadius","percent")}
            <div className='w-full p-2 flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name="showOpacity"
                checked={currElement.transparencyEnabled ? true : false}
                onChange={() => {
                  if(currElement.transparencyEnabled) {
                    setCurrElement(elem => {
                      saveDisplayFunc({...currElement, "transparencyEnabled":false, "transparency":0});
                      return {...elem, "transparencyEnabled": false, "transparency":0};
                    })
                  }
                  if(!currElement.transparencyEnabled) {
                    setCurrElement(elem => {
                      saveDisplayFunc({...currElement, "transparencyEnabled":true, "transparency":100});
                      return {...elem, "transparencyEnabled": true, "transparency":100};  
                    })
                  }
                }}
              />
              <h2 className='font-bold text-lg'>Transparent element</h2>
            </div>
            {currElement.transparencyEnabled  && EditOuterField("Transparency", "transparency","percent")}

            <div className='w-full flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name='timedElement'
                className=''
                checked={currElement.timedElement ? true : false}
                onChange={async () => {
                  
                  setCurrElement(elem => {
                    if(elem.timedElement) {
                      saveDisplayFunc({...currElement, "timedElement":false});
                      return {...elem, "timedElement": false}  
                    }
                    if(!elem.timedElement) {
                      saveDisplayFunc({
                        ...currElement,
                        "timedElement": true,
                        "display_seconds": 0,
                        "disappear_seconds": 60 
                      })
                      return {
                        ...elem, 
                        "timedElement": true,
                        "display_seconds": 0,
                        "disappear_seconds": 60
                      }
                    }
                  })
                }}
              />
              <h2 className='font-bold text-lg'>Timed Element</h2>
            </div>
            {currElement.type == "url" && <div className='w-full flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name='focus'
                className=''
                checked={currElement.focus ? true : false}
                onChange={async () => {
                  if(currElement.focus) {
                    setElementAsNoneFocus();
                  }
                  if(!currElement.focus) {
                    setElementAsFocus();
                  }
                  
                }}
              />
              <h2 className='font-bold text-lg'>Focus</h2>
            </div>}
            {(currElement.type == "url"
              || currElement.type == "promotion"
              || currElement.type == "priceChecker"
            ) && <div className='w-full flex flex-row items-center justify-center gap-2'>
              <input
                type='checkbox'
                name='focus'
                className=''
                checked={currElement.cache ? true : false}
                onChange={async () => {
                  setCurrElement(currElement => ({...currElement, ["cache"]: !currElement.cache}))
                }}
              />
              <h2 className='font-bold text-lg'>Cache Element</h2>
            </div>}
            {currElement.timedElement && <>
              <div className='w-full flex flex-col items-center justify-center gap-2'>
                <input
                  type='text'
                  name='timedElement'
                  className="h-10 text-black bg-white border-b-2 border-black  focus:ring-none outline-none pl-6"
                  // value={currElement.video_id} 
                  value={currElement.video_id ? currElement.video_id : "" } // why the fuck does this fix the problem?
                  onChange={(e) => {
                    setCurrElement(elem => ({...elem, "video_id": e.target.value}))
                  }}
                />
                <h2 className='font-bold text-lg'>Controller Id</h2>
              </div>
              {EditOuterField("Start Time", "display_seconds","amount")}
              {EditOuterField("End Time", "disappear_seconds","amount")}
            </>}
            <button 
              className='btn mt-5'
              onClick={() => {
                saveDisplayFunc();
              }}
            >
              {t("save")}
            </button>
          </>}
          <button 
            className='btn mt-5'
            onClick={() => {
              setShowPreviewModal(true);
            }}
          >
            preview
          </button>
          {/* <button className='btn' onClick={() => setShowAddElement(true)}>
            Add new Element
          </button> */}
        </div>
      </div>
    </>
  )
}

export default DisplayEditPage