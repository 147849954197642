import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sendCommandToDevice, updateAndroidControls } from '../../api/AndroidKeyApi';
import Select from 'react-tailwindcss-select';


const commands = [
  {label:"Restart", value:"restart"},
  {label:"Close", value:"close"},
  {label:"Launch", value:"launch"},
  {label:"Clear Cache", value:"clearcache"}
]
const commandDic = {
  "clearcache":"Clear Cache",
  "close":"Close",
  "launch":"Launch",
  "restart":"Restart"
}

const CommandComponent = ({androidkey}) => {
  const {t} = useTranslation();
  const [command, setCommand] = useState("");
  const [controls, setControls] = useState({
    volume: androidkey?.controls?.volume,
    brightness: androidkey?.controls?.brightness
  });
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const user = useSelector(state => state.user);
  const onSubmit = async () => {
    try {
      await updateAndroidControls(
        user.token,
        {
          code: androidkey.code,
          volume: controls.volume,
          brightness: controls.brightness
        }
      );
      if(command) {
        await sendCommandToDevice(
          user.token,
          {
            mac:androidkey.mac,
            command
          }
        );
        setMessage("Command Successfully Sent");
        setTimeout(() => {
          setMessage("")
        }, 3000);
      }
    } catch (err) {
      if(err?.response?.data?.msg) {
        setError(`Error: ${err.response.data.msg}`);
        setTimeout(() => {
          setError("");
        }, 3000);

      }
      else {
        setError("Internal Server Error");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      console.log(err);
    }
  }


  return (
    <>
      <div className="flex flex-col justify-center gap-10 p-10 w-[100%]">
        <h2>Volume</h2>
        <input 
          type='range' 
          min={0} 
          max={100} 
          value={controls.volume} 
          onChange={(e) =>{
            setControls(androidControls => ({...androidControls, "volume": e.target.value}))
          }}
        />
        <h2>Brigtness</h2>
        <input 
          type='range' 
          min={0} 
          max={100} 
          value={controls.brightness} 
          onChange={(e) =>{
            setControls(androidControls => ({...androidControls, "brightness": e.target.value}))
          }}
        />
      </div>
      <div className='w-full flex justify-center'>
        {message && <h2 className='text-green-500 font-bold'>{message}</h2>}
      </div>
      <h2>Commands</h2>
      <Select
        options={commands}
        value={command ? {label:commandDic[command], value:command} : {label:t("choiceCommand"), value:""}}
        onChange={(selection)=> setCommand(selection.value)}
        classNames={{
          menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
          menuButton:() => "flex flex-row w-full bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
          listItem: ({ isSelected }) => (
            `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                isSelected
                    ? `text-white bg-blue-500`
                    : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
            }`
          )
        }}
      />
      <div className='w-full flex flex-col items-center justify-center'>
        {error && <h2 className='text-red-500 font-bold'>{error}</h2>}
      </div>
      <div className="flex items-center justify-center p-3 border-slate-200 rounded-b">
        <button
          className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
          type="button"
          onClick={onSubmit}
        >
          {t("send")}
        </button>
      </div>
    </>
  )
}

export default CommandComponent;