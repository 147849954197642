import { forwardRef } from "react"

const PreviewTemplate = forwardRef(({item}, ref) => {
  return (
    <div ref={ref} style={{fontFamily:"RagSans-extraBold"}} className="relative w-full h-full bg-white flex flex-col justify-start items-center">
      <div className="flex flex-row p-5 justify-center items-center bg-green-400 w-full rounded-t-lg">
        <h2 className="text-white font-bold text-[70px]">{item.name}</h2>
      </div>
      <div className="w-full h-[80%] flex flex-row justify-start items-center">
        <div className="w-[50%] flex flex-col justify-center items-center">
          {item.imageLink && <img src={item.imageLink} className="w-[50%]"/>}
        </div>
        <div className="relative w-[50%] flex flex-col justify-center items-center">
          <div className="flex  justify-center items-center w-full gap-[20px]">
            <h2 className="text-[30px] md:text-[75px] mt-[20px]">{item.unit}</h2>
            <div className="flex items-center">
              <h2 className="text-[30px] md:text-[75px] mt-[20px]">₪</h2>
              <h2 className="text-[75px] md:text-[120px]">{item.price}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-end items-center gap-3 p-5">
        <h2 className="text-[30px] ">{item.unit}</h2>
        <div className="flex items-center">
          <h2 className="text-[30px] mt-[5px]">₪</h2>
          <h2 className="text-[40px]">{item.originalPrice}</h2>
        </div>
        <h2 className="text-black text-3xl">:מחיר קודם</h2>
      </div>
    </div>
  )
})

export default PreviewTemplate