import React, { useEffect, useState } from 'react'
import { getLicenses } from '../api/licenseApi';
import { useDispatch, useSelector } from 'react-redux';
import LicenseItem from '../components/licenses/LicenseItem';
import i18n from '../i18n';
import Title from '../components/Title';
import { logoutUser } from '../store';
import Header from '../components/layout/Header';

const LicensePage = () => {
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const storeSelection = useSelector(state => state.storeSelection)
  const dispatch = useDispatch();

  const [licenses, setLicenses] = useState([]);
  useEffect(() => {
    getLicenses(user.token, url, storeSelection.selectedStore)
      .then(res => {
        console.log(res);
        setLicenses(res)
      })
      .catch(err => {
        dispatch(logoutUser());
        console.log(err)
      });
  }, [storeSelection]);
 
  return (
    <>
      <Header/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='h-full w-full bg-gray-100 flex flex-col items-center overflow-scroll'>
        <Title title={"licenses"}/>
        <div className='grid grid-cols-1  md:grid-cols-3 gap-3 mt-5'>
          {licenses.map((license) => 
            <LicenseItem 
              licenseId={license.key}
              deviceId={license.deviceId}
              key={license.key}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default LicensePage