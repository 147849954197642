import React, { useState } from 'react'
import CloseIcon from '../../static/crossB.png';
import { useTranslation } from 'react-i18next';
import { sendMessageToDevice } from '../../api/AndroidKeyApi';
import { useSelector } from 'react-redux';

const AndroidMessageModal = ({mac, setShowModal}) => {
  const {t} = useTranslation();

  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(1);
  const user = useSelector(state => state.user);

  const submit = async () => {
    try {
      await sendMessageToDevice(user.token, {message, duration, mac});
      setShowModal(false);
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div>
     <div className="mt-24 justify-center items-center fle x overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className='w-full flex flex-col items-center p-2 gap-5'>
              <h2 className='font-bold text-lg'>{t("message")}</h2>
              <textarea
                rows={10}
                cols={4}
                type='text'
                className='rounded-xl border-gray-200 border-2 p-2 w-full'
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              />
              <h2 className='font-bold text-lg'>{t("duration")}</h2>
              <input
                type='number'
                className='rounded-xl border-gray-200 border-2 p-2 w-full'
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;

                  if (e.target.value === '' || re.test(e.target.value)) {
                     setDuration(e.target.value);
                  }
                }}
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key) && event.key != "Backspace" &&  event.key != "Delete") {
                    event.preventDefault();
                  }
                  if(isNaN(event.target.value)) {
                    event.preventDefault();
                
                  }
                }}
                value={duration}
              />
              <button
                className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
                type="button"
                onClick={submit}
              >
                {t("send")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AndroidMessageModal