import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../store';
import { getVersions } from '../api/versionUploadApi';
import Header from '../components/layout/Header';
import DeleteIcon from '../static/delete.png';
import VersionUploadModal from '../components/version/VersionUploadModal';

const VersionUploadPage = () => {
  const [versions, setVersions] = useState([]);
  const [showAddVersion, setShowAddVersion] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.user);

  const fetchData = async () => {
    try {
      const res = await getVersions(user.token);
      setVersions(res);
    } catch (err) {
      console.log("internal Server error");
      dispatch(logoutUser());
    }
  }

  useEffect(() => {
    if(!showAddVersion){
      fetchData();
    }
  }, [showAddVersion]);

  return (
    <>
      <Header showBack={true} backTitle={""}/>
      {showAddVersion && <VersionUploadModal setShowModal={setShowAddVersion}/>}
      <div className='w-full h-full flex flex-col items-center overflow-scroll mb-16 md:mb-0'>
        <h2 className='text-black font-bold text-2xl'>Version Uploads</h2>
        <button
          className='btn mt-5'
          onClick={() => {
            setShowAddVersion(true);
          }}
        >
          Add Version
        </button>
        <table className="w-full items-center">
          <thead className="text-sm md:text-[16px] text-slate-700 font-bold border-b-2  border-gray-300">
            <tr>
              <th
                className="px-6 py-4 hover:cursor-pointer" 
                scope='col'
              >
                <h2>Version</h2>
              </th>
              <th
                className="px-6 py-4 hover:cursor-pointer" 
                scope='col'
              >
                <h2>Link</h2>
              </th>
              {/* <th>
                <h2>Actions</h2>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {versions.map(version => <tr
              className="border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
            >
              <td className="text-black font-bold border-r-2 p-2 ">
                {version.version}
              </td>
              <td className="text-black font-bold border-r-2 p-2 ">
                {version.link}
              </td>
              {/* <td className='flex items-center justify-center p-2'>
                <button>
                  <img src={DeleteIcon}/>
                </button>
              </td> */}
            </tr>)}
            
          </tbody>
        </table>
      </div> 
    </>
  )
}

export default VersionUploadPage