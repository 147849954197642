import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Scanner from "../components/scanner/Scanner";
import i18n from "../i18n";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { getDeviceInfo } from "../api/deviceApi";
import { useDispatch, useSelector } from "react-redux";
import { setDevice, setSelectedStore } from "../store";

const QrScannerPage = () => {
  const [qrCode, setQrCode] = useState("");
  const [startCamera, setStartCamera] = useState(true);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  const scanQrCode = async (qrCode) => {
    let qr;
    try {
      qr = JSON.parse(qrCode);
      console.log(qr);
      if(!qr.deviceId) {
        return;
      }
      const device = await getDeviceInfo(user.token, qr.deviceId, "");
      dispatch(setSelectedStore(device.StoreId));
      dispatch(setDevice(device));
      navigate(`/device/editDisplay/${qr.displayPosition}`);

    } catch (error) {
      console.log(error);
      return;
    }

  }

  return (<div 
    dir={i18n.language == "he" ? "rtl" :"ltr"}
    className="bg-gray-100 w-full h-full flex flex-col
      justify-start items-center p-5 gap-2 overflow-scroll"
  >
    <div className='bg-white flex flex-col w-full shadow-md p-5 items-center'>
      <h2 className='text-black font-bold text-lg'>
        Scan Qr Code
      </h2>
      <Scanner
        startCamera={startCamera}
        setStartCamera={setStartCamera}
        setData={scanQrCode}
      />
      <h2 className="text-black font-bold text-lg">{qrCode}</h2>
    </div>
  </div>)
}

export default QrScannerPage