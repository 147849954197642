import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useEffect, useState } from 'react';

const Scanner = ({setData, startCamera, setStartCamera, style, d}) => {
    let html5QrCode;
    useEffect(() => {
      if(!html5QrCode?.getState()) {
        html5QrCode = new Html5Qrcode("qrCodeContainer", {formatsToSupport:[
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.EAN_13,
          Html5QrcodeSupportedFormats.EAN_8,
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.CODE_93,
          Html5QrcodeSupportedFormats.AZTEC,
          Html5QrcodeSupportedFormats.CODABAR,
          Html5QrcodeSupportedFormats.ITF,
          Html5QrcodeSupportedFormats.PDF_417,
          Html5QrcodeSupportedFormats.DATA_MATRIX,

        ]});
        let config;

        if(window.screen.height > window.screen.width) {
            config = {fps: 100, qrbox: {width: "150", height: `150`}}
        }
        if(window.screen.height < window.screen.width) {
            config = {fps: 100, qrbox: {width: window.screen.width/4, height: window.screen.height/4}}
            
        }
    
        if(startCamera) {
            html5QrCode.start({facingMode: "environment"} , config, (decodedText, decodedResult) => {
                console.log(decodedText)
                console.log(decodedResult)
                setData(decodedText)
                
            })
        }
        else {
            html5QrCode.clear()
        }
      }

      return async () => {
        if(html5QrCode.getState() === 2) {
          await html5QrCode.stop();
          html5QrCode.clear();
        }
      }
    }, [startCamera]);
   
    
  
    return (
        <div className={`flex flex-col w-full h-[${style?.height ? style.height : "40"}%] md:h-[70%] md:w-[50%]  p-5 bg-white shadow-md`} >
          <div id="qrCodeContainer" className="h-auto"/>
          {!startCamera && <button className="btn" onClick={() => setStartCamera(!startCamera)}> start camera</button>}
        </div>
    
    )
};

export default Scanner;