import React, { useEffect, useState } from 'react'
import CloseIcon from '../../static/crossB.png';
import plusGroupIcon from '../../static/brackets+.png';
import { useSelector } from 'react-redux';
import { groupsToDevice } from '../../api/AndroidKeyApi';
import { getGroupListCall } from '../../api/GroupListApi';
import Select from 'react-tailwindcss-select';

const AndroidDeviceGroupModal = ({androidKey, setShowModal}) => {

  const [groups, setGroups] = useState(androidKey.groups || []);
  const [fetchedGroups, setFetchedGroups] = useState([]);

  const user = useSelector(state => state.user);
  const storeSelection = useSelector(state => state.storeSelection.selectedStore);

  const addGroup = (group) => {
    setGroups(grps => {
      if(group && grps.findIndex(grp => grp == group) == -1) {
        return [...grps, group];
      }
      return grps;
    });
  }

  const removeGroup = (group) => {
    setGroups(grps => {
      let newGrps = [...grps].filter(grp => grp != group);
      return newGrps;
    });
  }

  const fetchGroups = async () => {
    try {
      const data = await getGroupListCall(user.token, {storeId: storeSelection});
      if(data?.list) {
        setFetchedGroups(data.list);
      }
      else {
        setFetchedGroups([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  const sendGroupsToDevice = async () => {
    try {
      const res = await groupsToDevice(user.token, {groups, mac: androidKey.mac});
      setShowModal(false);

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchGroups();
  }, [])

  return (
    <div className=''>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[95%] md:w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className='w-full flex flex-col items-center m-2'>
              <h2 className='font-bold text-3xl'>Depolyment Group Selection</h2>
            </div>
            <div className="relative flex flex-col justify-start items-center text-center w-full gap-2">
              {groups && groups.length > 0 && <div className='p-4 w-[90%] grid grid-cols-2 md:grid-cols-3 rounded-2xl gap-2 bg-gray-500'>
                {groups.map(grp => <div className='rounded-lg p-2 flex gap items-center justify-center bg-gray-400 overflow-scroll'>
                  <h2 className='text-white font-bold text-md'>{grp}</h2>
                  <button 
                    className='font-extrabold text-xl mx-2 text-white hover:text-gray-200'
                    onClick={() => {
                      removeGroup(grp);
                    }}
                  >
                    X
                  </button>
                </div>)}
              </div>}
              <div className='w-full flex items-center justify-center gap-2 relative'>
                <Select
                  options={fetchedGroups?.length > 0 ?
                  fetchedGroups.map(grp => ({label:grp, value:grp}))
                  : []
                  }
                  value={{label:"Choose a group", value:"Choose a group"}}
                  onChange={(selection) => {
                    addGroup(selection.value);
                  }}
                  classNames={{
                    menu:"absolute w-full h-[250px]  bg-white border-2 border-black overflow-scroll z-20",
                    menuButton:() => "flex flex-row w-full bg-white border-2 border-black px-2 justify-between",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                          isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}

                />

              </div>
              <button className='btn' onClick={sendGroupsToDevice}>
                Update Groups
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AndroidDeviceGroupModal