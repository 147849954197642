import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { previewTemplate } from '../api/templateApi'
import QRCode from 'react-qr-code'
import { getItem } from '../api/itemsApi'
import { logoutUser } from '../store'

const RenderTemplate =forwardRef(({storeId, barcode, structure, name}, ref) => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [item, setItem] = useState({});
  useEffect(() => {
    getItem(
        user.token,
        "",
        barcode,
        storeId
    )
    .then(res => setItem(res))
    .catch(err => {

      console.log(err)
    });
  }, [])
  if(!item) {
    return <div>Empty</div>
  }
  return <div className='w-full h-full flex flex-col items-start'   ref={ref}>
    <div className='w-full flex justify-end'>
      <div className='flex  gap-2 bg-[#3fab35] p-2'>
        <h2 className='text-white' style={{fontFamily:"RagSans-regular"}}>{item.custFeature11}</h2>
        <h2 className='text-white' style={{fontFamily:"RagSans-regular"}}>: מספר מבצע</h2>
      </div>
    </div>
    <div className='w-full p-5 flex flex-row justify-center items-center'>
      <h2 style={{fontFamily:"RagSans-regular"}} className='text-[75px] text-green-800'>{item.name}</h2>
    </div>
    <div className='mt-[50px] bg-[#ffd000] flex flex-col items-center h-full w-full justify-center mb-[15%] pt-[10%]'>
      <div className='flex w-full justify-center'>
        <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-[100px] mt-[200px] text-green-800'>
        ₪
        </h2>
        <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-[300px] text-green-800'>
          {item.price}
        </h2>
      </div>
      <div className='w-full flex flex-row justify-end'>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600 border-x-[5px] border-green-700'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            מחיר לפני הנחה
          </h2>
        </div>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600 border-x-[5px]  border-green-700'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            שווי ליחידה אחרי הנחה
          </h2>
        </div>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600 border-x-[5px]  border-green-700'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            מחיל לפני ל-יח
          </h2>
        </div>
      </div>
      <div className='flex w-full  bg-[#ffd000] justify-end'>
        <div className='align-bottom mr-[123px]  flex flex-row justify-center px-2 bg-green-600  border-green-700 border-x-[5px]'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            {item.originalPrice}
          </h2>
        </div>
        <div className='align-bottom mr-[70px] flex flex-row justify-center px-2 bg-green-600  border-green-700 border-x-[5px]'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            {item.originalPrice}
          </h2>
        </div>
        <div className='align-bottom  flex flex-row justify-center px-2 bg-green-600  border-green-700 border-x-[5px]'> 
          <h2
            style={{fontFamily:"RagSans-extraBold"}}
            className='text-lg text-white bg-green-600 px-5 '
          >
            {item.originalPrice}
          </h2>
        </div>
      </div>
    </div>
    <div className='w-full flex justify-end mt-2 gap-1 pr-1'>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-xl'>הזול/ים מבין כלל המוצרים שיירכשו על ידי הלקוח</h2>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-xl'>,{item.custFeature12}</h2>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-xl'>:מבצע בתוקף עד ה</h2>
    </div>
    <div dir='rtl' className='w-full flex justify-start mt-1 pr-1'>
      <h2 style={{fontFamily:"RagSans-extraBold"}} className='text-green-700 text-xl'>
      הברקודים המשתתפים במבצע:  {item.custFeature5}
      </h2>
    </div>
  </div>
});

export default RenderTemplate




/**
 *
 * 
 * 

const deepMapElements = (elements) => {
  if(elements.type === "view") {
    return <div style={elements.style}>
      {elements.elements.map(element => deepMapElements(element))}
    </div>
  }
  if(elements.type === "img") {
    return (
      <img 
        style={elements.style}
        src={elements.content}
      />
    )
  }
  if(elements.type === 'video') {
    if(elements.content.includes(".mp4") || elements.content.includes(".webm"))
      return <video style={elements.style} autoPlay muted loop>
         <source src={elements.content} type="video/webm" />
      </video>
  }
  if(elements.type === "text") {
    return <p dir='rtl' style={elements.style}>{elements.content}</p>
  }
  if(elements.type ==="qrcode_dyn") {
    return <div style={{backgroundColor:"white"}}>
      <QRCode
        size={256}
        style={elements.style}
        value={elements.content}
        viewBox={`0 0 256 256`}
      />
    </div>
  }
}



import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { previewTemplate } from '../api/templateApi'
import QRCode from 'react-qr-code'
const deepMapElements = (elements) => {
  if(elements.type === "view") {
    return <div style={elements.style}>
      {elements.elements.map(element => deepMapElements(element))}
    </div>
  }
  if(elements.type === "img") {
    return (
      <img 
        style={elements.style}
        src={elements.content}
      />
    )
  }
  if(elements.type === 'video') {
    if(elements.content.includes(".mp4") || elements.content.includes(".webm"))
      return <video style={elements.style} autoPlay muted loop>
         <source src={elements.content} type="video/webm" />
      </video>
  }
  if(elements.type === "text") {
    return <p dir='rtl' style={elements.style}>{elements.content}</p>
  }
  if(elements.type ==="qrcode_dyn") {
    return <div style={{backgroundColor:"white"}}>
      <QRCode
        size={256}
        style={elements.style}
        value={elements.content}
        viewBox={`0 0 256 256`}
      />
    </div>
  }
}

const RenderTemplate = ({storeId, barcode, structure, name}) => {
  const user = useSelector(state => state.user);
  const [display, setDisplay] = useState([]);
  useEffect(() => {
    previewTemplate(
        user.token,
        {
          storeId,
          barcode,
          structure,
          name
        }
    )
    .then(res => setDisplay(res))
    .catch(err => console.log(err));
  }, [])
  if(!display[0]) {
    return <div>Empty</div>
  }
  return <div style={display[0].style}>
    {display && display[0]  &&  display.map(disp => {
      return disp.elements && disp.elements.map(elem => deepMapElements(elem))
    })}
  </div>
}

export default RenderTemplate
 */