import React, { useState } from 'react'
import CloseIcon from '../../static/crossB.png';
import Select from 'react-tailwindcss-select';
import { useTranslation } from 'react-i18next';
import { sendRotationToDevice } from '../../api/AndroidKeyApi';
import { useSelector } from 'react-redux';

const rotations = [
  {label:"0", value:"0"},
  {label:"90", value:"90"},
  {label:"180", value:"180"},
  {label:"270", value: "270"}
]

const AndroidRotateModal = ({mac, deviceRotation, setShowModal}) => {
  const {t} = useTranslation();
  const user = useSelector(state => state.user);
  const [rotation, setRotation] = useState(deviceRotation || "0");
  
  const onSubmit = async () => {
    try {
      await sendRotationToDevice(user.token, {mac, rotation});
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[80%] p-5">
            <div className="flex flex-row justify-end gap-10  w-[100%]">
              <button
                className=''
                onClick={() => setShowModal(false)}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            <div className='w-full flex flex-col items-center gap-2'>
              <h2 className='font-bold text-2xl'>Rotation</h2>
              <Select
                options={rotations}
                value={{label:rotation, value:rotation}}
                onChange={(selection)=> setRotation(selection.value)}
                classNames={{
                  menu:"absolute w-full h-[250px]  bg-white border-[1px] border-[#D4D4D4] rounded-md overflow-scroll z-10",
                  menuButton:() => "font-bold flex flex-row w-full items-center text-center bg-white border-[1px] border-[#D4D4D4] rounded-md px-2 justify-between z-10",
                  listItem: ({ isSelected }) => (
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded z-10 ${
                        isSelected
                            ? `font-bold text-white bg-blue-500`
                            : `font-bold hover:bg-blue-100 hover:text-blue-500`
                    }`
                  )
                }}
              />
            </div>
            <div className="flex items-center justify-center p-3 border-slate-200 rounded-b">
              <button
                className="text-white bg-blue-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-blue-300"
                type="button"
                onClick={onSubmit}
              >
                {t("send")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AndroidRotateModal