import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getStoresApi } from "../../../api/storesApi";
import { useState } from "react";
import { addStore, removeStore, setAllStores } from "../../../store";
import i18n from '../../../i18n'
import { useTranslation } from "react-i18next";

const StoreAddModal = ({setShowModal}) => {
  const playlist = useSelector(state => state.playlist);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useSelector(state => state.user);
  const [stores, setStores] = useState([]);
  const [checkedStores, setCheckedStores] = useState({});
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    getStoresApi(user.token)
      .then(res => {
        setStores(res.map(store => store.storeId))
        let arr = {}
        playlist.stores.forEach(store => {
          arr[store.storeId] = "checked";
        });
        setCheckedStores(arr);
      })
      .catch(err => console.log(err))
  }, []);

  return (
    <div className="modal bg-black bg-opacity-40">
       <div className="relative  w-full my-6 mx-auto max-w-3xl overflow-scroll mt-[50px]">
          <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-9 justify-center items-center overflow-scroll">
            <div className="w-full flex flex-row justify-center items-center gap -2">
              <h2 className="text-black font-bold text-2xl">{t("selectAllStores")}</h2>
              <input 
                type="checkbox"
                className="text-2xl w-[10%]"
                onClick={() => {
                  if(checkAll) {
                    setCheckAll(false);
                    setCheckedStores({});
                    return dispatch(setAllStores([]))
                  }
                  if(!checkAll) {
                    setCheckAll(true);
                    let currStores = [...playlist.stores];
                    stores.forEach(store => {
                      if(currStores.findIndex(st => st.storeId == store) < 0) {
                        currStores.push({storeId: store, devices: []})
                      }
                      setCheckedStores(stores => ({...stores,[store]:"checked"}))
                    });
                    dispatch(setAllStores(currStores));
                  }
                }}
                checked={checkAll}
              />
            </div>
            <div className="w-full flex flex-col justify-start items-start">
            {stores.map(store => {
              return <div key={store} className="w-full flex flex-row items-center gap-5">
              <input
                type="checkbox"
                checked={checkedStores[store] === "checked" ? true : false}
                className="text-2xl w-[10%]"
                onClick={() => {
                  if(checkedStores[store] === "checked") {
                    setCheckAll(false);
                    setCheckedStores(stores => ({...stores,[store]:"unchecked"}));
                    return dispatch(removeStore(store));
                  }
                  if(checkedStores[store] !== "checked") {
                    setCheckedStores(stores => ({...stores,[store]:"checked"}));
                    return dispatch(addStore(store));
                  }
                }}
              />
              <h2 className="text-black font-bold text-2xl">{store}</h2>
            </div>})}
            </div>
            <div className="flex items-center justify-center p-6 border-slate-200 rounded-b">
              <button
                className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default StoreAddModal