import { configureStore, createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  loading: false,
  token: null,
  email: null,
  superAdmin: false,
  omniAdmin: false,
  storeId:""
}

const deviceItemsModalSlice = createSlice({
  name:"deviceItemsModal",
  initialState: {
    showModal: false,
    displays: []
  },
  reducers: {
    setDisplaysModal(state, action) {
      state.displays = action.payload;
      state.showModal = true;
    },
    addDisplayModal(state, action) {
      state.displays = [...state.displays, action.payload];
      state.showModal = true;
    },
    setCloseModal(state) {
      state.displays = [];
      state.showModal = false;
    }
  }
})
const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUserLoading(state) {
      state.loading = true;
    },

    loginUser(state, action) {
      state.token = action.payload.token;
      state.loading = false;
      state.email = action.payload.email;
      state.superAdmin = action.payload.superAdmin;
      state.storeId = action.payload.storeId;
      state.omniAdmin = action.payload.omniAdmin;
    },

    logoutUser(state) {
      state = initialUserState;
      localStorage.removeItem("token");
      window.location.reload();
    }
  }
});

const loadingSlice = createSlice({
  name:"loading",
  initialState:{
    loading: false
  },
  reducers:{
    setLoadingState(state, action) {
      state.loading = action.payload;
    }
  }
});

const urlSlice = createSlice({
  name:"url",
  initialState: {
    url:""
  },
  reducers: {
    setUrl(state, action) {
      state.url = action.payload;
    }
  }
});

const deviceInitialState = {
  loading: true,
  deviceId:"",
  barcodes:[],
  displayIds:[],
  online: false,
  showDeviceId: false,
  displayDates: [],
  duration: null,
  override: [],
  displayWeeks: []
}
const storeSelectionSlice = createSlice({
  name:"storeSelection",
  initialState: {
    selectedStore:"",
    selectedCategory:""
  },
  reducers: {
    setSelectedStore(state, action) {
      state.selectedStore = action.payload;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    }
  }
});


const deviceSlice = createSlice({
  name:"device",
  initialState: deviceInitialState,
  reducers: {
    setDeviceLoading(state, action) {
      state.loading = action.payload;
    },
    addDuration(state, action) {
      if(state.duration) {
        const existingIndex = state.duration.findIndex(dur => dur.index == action.payload.index);
        if(existingIndex > -1) {
          state.duration[existingIndex] = {index: action.payload.index, duration: action.payload.duration}; 
        }
        state.duration.push({index: action.payload.index, duration: action.payload.duration});
      }
      if(!state.duration) {
        state.duration = [{index:action.payload.index, duration: action.payload.duration}];
      }
    },
    removeDuration(state, action) {
      if(state.duration) {
        state.duration = state.duration.filter(dur => dur.index != action.payload.index);
      }
    },
    setOverride(state, action) {
      if(state.override && state.override.length > 0)    
      {
        const ovrIndex = state.override.findIndex(ovr => ovr.index == action.payload.index);
        if(ovrIndex > -1) {
          state.override[ovrIndex].override = action.payload.override;
        }
        else {
          state.override.push({override: action.payload.override, index: action.payload.index});
        }
      } 
      if(!state.override) {
        state.override = [{override: action.payload.override, index: action.payload.index}]
      }
    },
    setDevice(state, action) {
      state.loading = false;
      state.deviceId = action.payload.deviceId;
      state.playlistName = action.payload.playlistName;
      state.barcodes = action.payload.barcodes;
      state.displayIds = action.payload.displayIds;
      state.online = action.payload.online;
      state.showDeviceId = action.payload.showDeviceId;
      state.rotateAutomatically = action.payload.rotateAutomatically;
      state.deviceRotationTime = action.payload.deviceRotationTime || 10;
      state.deviceRotation = action.payload.deviceRotation;
      state.displayTime = action.payload.displayTime;
      state.displayDates = action.payload.displayDates || [];
      state.showUnBound = action.payload.showUnBound;
      state.admin = action.payload.admin;
      state.storeId = action.payload.storeId;
      state.duration = action.payload.duration;
      state.displayWeeks = action.payload.displayWeeks || [];
      state.override = action.payload.override;
    },
    setDeviceShowId(state, action) {
      state.showDeviceId = action.payload;
    },
    setRotateAutomatically(state, action) {
      state.rotateAutomatically = action.payload;
    },
    addDeviceDisplay(state, action) {
      state.displayIds.push(action.payload.displayId);
      state.barcodes.push(action.payload.barcodes);
    },
    removeDeviceDisplay(state, action) {
      const rotInd = state.deviceRotation.indexOf(action.payload.index);
      if(rotInd > -1) {
        state.deviceRotation.splice(rotInd,1);
      }
      state.displayIds.splice(action.payload.index, 1);
      state.barcodes.splice(action.payload.index, 1);
      state.displayTime.splice(action.payload.index, 1);
      state.displayDates.splice(action.payload.index, 1);
      state.deviceRotation = state.deviceRotation.map((value, ind) => {
        if(value < action.payload.index) {
          return value;
        }
        if(value > action.payload.index) {
          return value-1;
        }
      });
      let newDuration = [];
      if(state.duration && state.duration.length >= 1) {
        state.duration.forEach(dur=> {
          if(!isNaN(dur.index) && parseInt(dur.index) < action.payload.index) {
            newDuration.push({index: parseInt(dur.index), duration: dur.duration}); 
          }
          if(!isNaN(dur.index) && parseInt(dur.index) > action.payload.index) {
            newDuration.push({index: parseInt(dur.index) -1, duration: dur.duration})
          }
        })
      }
      state.duration = newDuration;
      let newOverride = [];
      if(state.override && state.override.length >= 1) {
        state.override.forEach(ovr => {
          if(!isNaN(ovr.index) && parseInt(ovr.index) < action.payload.index) {
            newOverride.push({index: parseInt(ovr.index), override: ovr.override})
          }
          if(!isNaN(ovr.index) && parseInt(ovr.index) > action.payload.index) {
            newOverride.push({index: parseInt(ovr.index) -1, override: ovr.override})
          }
        })
      }
      state.override = newOverride;
    },
    editDeviceDisplay(state, action) {
      state.displayIds[action.payload.index] = action.payload.displayId;
      state.barcodes[action.payload.index] = action.payload.barcodes;
    },
    clearDevice(state) {
      state = deviceInitialState;
    },
    setNewRotation(state, action) {
      state.deviceRotation = action.payload;
    },
    setNewDisplayTime(state, action) {
      state.displayTime = action.payload;
    },
    setNewDisplayDates(state, action) {
      state.displayDates = action.payload;
    },
    setShowUnBound(state, action) {
      state.showUnBound = action.payload;
    },
    setDisplayWeeks(state, action) {
      state.displayWeeks[action.payload.index] = action.payload.content;
    }
  }
});


export const templateSlice = createSlice({
  initialState: {
    type: "",
    structure:"",
    action:"",
    html:"",
    storeId:"",
    size: ""
  },
  name:"template",
  reducers: {
    setTemplateState(state, action) {
      state.action = action.payload.action;
      state.structure = action.payload.structure;
      state.type = action.payload.type;
      state.html = action.payload.html;
      state.storeId = action.payload.storeId;
      state.size = action.payload.size;
    },
    clearTemplateState(state, action) {
      state.action = "";
      state.structure = "";
      state.type = "";
      state.html = "";
      state.storeId = "";
    }
  }
})

export const {
  loginUser,
  logoutUser,
  setUserLoading
} = userSlice.actions;

export const {
  setDeviceLoading,
  setDevice,
  clearDevice,
  addDeviceDisplay,
  removeDeviceDisplay,
  editDeviceDisplay,
  setDeviceShowId,
  setRotateAutomatically,
  setNewRotation,
  setShowUnBound,
  setNewDisplayTime,
  setNewDisplayDates,
  addDuration,
  removeDuration,
  setOverride,
  setDisplayWeeks
} = deviceSlice.actions;

export const {
  setSelectedStore,
  setSelectedCategory
} = storeSelectionSlice.actions;

export const {
  setUrl
} = urlSlice.actions;

export const {
  setDisplaysModal,
  setCloseModal,
  addDisplayModal
} = deviceItemsModalSlice.actions;

export const {
  setTemplateState,
  clearTemplateState
} = templateSlice.actions;

export const {
  setLoadingState
} = loadingSlice.actions;

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    url: urlSlice.reducer,
    device: deviceSlice.reducer,
    storeSelection: storeSelectionSlice.reducer,
    deviceItemsModal: deviceItemsModalSlice.reducer,
    template: templateSlice.reducer,
    loading: loadingSlice.reducer
  }
});