
import CloseIcon from '../../static/crossB.png'
import { useSelector } from 'react-redux';
import { getUploadsApi, uploadImage, uploadVideo } from '../../api/uploadApi';
import React, { useEffect, useState } from 'react'
import AddImage from '../../static/gallery-add.png';
import AddVideo from '../../static/video-horizontal.png';
import LoadingSpinner from '../../static/loading.gif';
import PlaylistElement from '../../components/playlistElements/PlaylistElement';


const UploadDisplayEditModal = ({type, setShowModal, setElementContent}) => {

  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  
  const [uploadedItems, setUploadedItems] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 10,
    showMore: false
  });

  const fetchData = async (limit) => {
    try {
      const res = await getUploadsApi(
        user.token,
        {
          storeId: selectedStore,
          limit,
          type
        } 
      );
      setUploadedItems(res.uploads);
      setPagination(pagination => ({...pagination, showMore: res.showMore}));

    } catch (err) {
      console.log(err)
    }
  }

  const increasePageLimit = () => {
    fetchData(pagination.limit +10);
    setPagination(pagination => ({...pagination, limit: pagination.limit +10}))
  }

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];
    console.log(file)
    if(file.type != "video/mp4") {
      setError("Videos can be mp4 only")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', user);
    form.append('selectedStore', selectedStore);
    try {
      setLoading(true)
      const result = await uploadVideo(user.token, "", form);
      fetchData();
    } catch (err) {
      if(err.response.data.msg == "not enough space") {
        setError("You have insufficent space - Free up space or Request Additonal Space");
      }
      else {
        setError("Unknown Error - trying again later");
      }
      setTimeout(() => {
        setError("");
      }, 5000)
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    if(file.type != "image/jpeg" && file.type != "image/png") {
      setError("Images must be .jpgs or .pngs only")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const form = new FormData();
    form.append('file', file);
    form.append('admin', user);
    form.append('selectedStore', selectedStore);
    try {
      setLoading(true)
      const result = await uploadImage(user.token, "", form);
      fetchData();
    } catch (err) {
      if(err.response.data.msg == "not enough space") {
        setError("You have insufficent space - Free up space or Request Additonal Space");
      }
      else {
        setError("Unknown Error - trying again later");
      }
      setTimeout(() => {
        setError("");
      }, 5000)
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const pickUpload = async (upload) => {
    setElementContent(upload.link);
    setShowModal({show:false, type:""}); 
  }

  useEffect(() => {
    fetchData(pagination.limit);
  }, [pagination, type]);

  return (
    <div>
      <div className="mt-24 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full md:w-[80%] h-full ">
          <div className="relative flex flex-col justify-start items-center bg-white rounded-lg shadow w-full md:w-[70%] h-[80%] p-2">
            <div className="flex flex-row justify-end gap-10 w-[100%] mb-10 p-2">
              <button
                className=''
                onClick={() => setShowModal({show:false, type:""})}
              >
                <img src={CloseIcon}/>
              </button>
            </div>
            {type == "image" && <div className='w-full flex items-center justify-start p-2'>
              <input id="imageInput" hidden type="file" accept='image/*' onChange={handleUploadImage} />
              <label
                className='flex w-full justify-end cursor-pointer gap-2'
                htmlFor="imageInput"
                
              >
                {!loading ? <>
                  <img htmlFor="imageInput"  src={AddImage} width={30}/>
                  <h2 className='text-black font-bold text-xl'>Add Image +</h2>
                </> :  <img src={LoadingSpinner} width={20} />}

              </label>
            </div>}
            {type == "video" && <div className='w-full flex items-center justify-start p-2'>
              <input id="videoInput" hidden type="file" accept='video/*' onChange={handleUploadVideo} />
              <label
                className='flex w-full justify-end cursor-pointer gap-2'
                htmlFor="videoInput"  
              >
                {!loading ? <>
                  <img htmlFor="videoInput" src={AddVideo} width={30}/>
                  <h2 className='text-black font-bold text-xl'>Add video +</h2>
                </> : <img src={LoadingSpinner} width={10} />}

              </label>
            </div>}
            
            {error && <h2 className='text-red-500 font-bold text-xl'>{error}</h2>}
            <div className='w-full overflow-scroll flex flex-col md:p-2'>
              <div className='grid grid-cols-3 md:p-2'>
                {uploadedItems.map(upItem => <div
                  key={upItem._id} 
                  className='w-full h-full cursor-pointer' onClick={() => pickUpload(upItem)}>
                    <PlaylistElement element={{type: type, content: upItem.link}}/>
                </div>)}
              </div>
              <button
                disabled={!pagination.showMore}
                className='btn disabled:opacity-50'
                onClick={increasePageLimit}
              >
                  Load more
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default UploadDisplayEditModal