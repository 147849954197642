import React, { useEffect, useState } from 'react'
import { getPlaylistApi } from '../api/playlistApi';
import PlaylistItem from '../components/playlist/PlaylistItem';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/layout/Header';
import i18n from '../i18n';
import PlusIcon from '../static/add.png';
import { useTranslation } from 'react-i18next';
import DeletePlaylistModal from '../components/playlist/modals/DeletePlaylistModal';
import AddPlaylistModal from '../components/playlist/modals/AddPlaylistModal';
import { logoutUser } from '../store';
import AddIcon from '../static/addB.png'

const PlaylistPage = () => {

  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    playListId: ""
  })
  const [addModal, setAddModal] = useState(false)

  const setDeletePlaylistId = (playListId) => {
  setDeleteModal({
    show: true,
    playListId: playListId
  });
  }
  
  const [playlists, setPlaylists] = useState([]);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  
  useEffect(() => {
    if(!deleteModal.show && !addModal) {
      getPlaylistApi(user.token, selectedStore)
      .then(res => setPlaylists(res))
      .catch(err => {
          dispatch(logoutUser());
          console.error(err)
        })   
    }
  }, [selectedStore, deleteModal, addModal])
  return (
    <>
      <Header/>
      <div 
        dir={i18n.language == "he" ? "rtl" :"ltr"}
         className='h-screen w-full bg-white flex flex-col items-center
          overflow-y-scroll pb-[100px]'
      >
        {deleteModal.show && <DeletePlaylistModal setShowModal={setDeleteModal} playlistId={deleteModal.playListId} />}
        {addModal && <AddPlaylistModal setShowModal={setAddModal}/>}
        <div
          className='flex flex-row items-center justify-end w-full gap-3 p-5'
        >
          <button 
            className='border-2 border-[#2D5186] p-2 px-3 rounded-xl text-white font-bold hover:bg-gray-200 transition-all'
            onClick={() => {
              setAddModal(true)
            }}
          >
            <img src={AddIcon}/>
          </button>
        </div>
        <div className='flex flex-col justify-center items-center w-full gap-3 mt-5'>
          {playlists.map((playlist) => 
            <PlaylistItem
              playListId={playlist.playListId}
              setDeletePlaylistId={setDeletePlaylistId}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default PlaylistPage