import axios from "axios"

export const getTemplates = async (token, storeId) => {
  const result = await axios.get(
    "https://backend.konekt.vip/printTemplates/",
    {
      headers:{
        token:`bearer ${token}`
      },
      params:{
        storeId
      }
    }
  );
  return result.data;
}

export const createTemplate = async (token, data) => {
  const result = await axios.post(
    "https://backend.konekt.vip/printTemplates/",
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return result.data;
}

export const updateTemplate = async (token, data) => {
  const result = await axios.put(
    `https://backend.konekt.vip/printTemplates/`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return result.data;
}

export const getTemplate = async(token, templateName) => {
  const result = await axios.get(
    `https://backend.konekt.vip/printTemplates/getTemplate?name=${templateName}`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return result.data;
}