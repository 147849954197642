import { useState } from "react"
import AddIcon from '../../static/addB.png';
import { useTranslation } from "react-i18next";
import ItemInDevice from '../items/ItemInDevice';
import { useSelector } from "react-redux";
import PreviewComponent from "./PreviewComponent";
import { previewDisplayApi } from "../../api/displayApi";
const EditorPreviewModal = ({setShowModal, displayId}) => {
  const user = useSelector(state => state.user);
  const storeId = useSelector(state => state.storeSelection.selectedStore);
  const [barcodes, setBarcodes] = useState([]);
  const [newBarcode, setNewBarcode] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewDisplay, setPreviewDisplay] = useState(null);
  const {t} = useTranslation();

  const addBarcode = (barcode) => {
    setBarcodes(barcodes => [...barcodes, barcode]);
  }

  const getPreview = async () => {
    try {
      const preview = await previewDisplayApi(
        user.token,
        {
          displayId,
          barcodes,
          storeId
        }
      );
      console.log(preview);
      setPreviewDisplay(preview);
      setShowPreview(true);
    } catch (err) {
      console.log(err);
    }
  }

  if(showPreview) {
    return <div className="">
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className=" flex flex-col justify-center items-center bg-white rounded-lg h-[95%] w-[95%] p-5 border-2 border-black">
          <div className="w-full h-[95%]">
            <PreviewComponent 
              display={previewDisplay} 
              deviceId={"Preview"} 
              showDeviceId={false}
              displayPosition={"Preview"}
              qrCodeIndex={"Preview"}
            />
          </div> 
          <button className="btn mt-5" onClick={() => setShowModal(false)}>
            close
          </button>
        </div>
      </div> 
    </div>
  }

  return (
    <div className="">
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col justify-center items-center bg-white rounded-lg shadow w-[80%] p-5">
            <label
              className="block text-md text-black font-bold mx-2"
            >
              {t("addAbarcode")}
            </label>
            <div className='relative w-full'>
              <input
                type="text"
                name="org"
                onChange={(e) => setNewBarcode(e.target.value)}
                className="h-10 px-12 w-full text-black bg-white border-[1px] border-[#e0e0e2]  focus:ring-none outline-none"
              />
              <button
                className="absolute top-[10px] left-[1%]"
                type="button"
                onClick={() => {
                  addBarcode(newBarcode);
                }}
              >
                <img src={AddIcon} width={25}/>
              </button>
            </div>
            {barcodes && barcodes.length > 0 && <>
              <label
                className="block text-md text-black font-bold"
              >
                {t("barcodes")}
              </label>
              <div className='flex flex-col  gap-2 w-full overflow-scroll'>
                {barcodes.map((barcode, barcodeIndex) => 
                  <ItemInDevice
                    barcode={barcode}
                    key={barcodeIndex}
                    allowReplace={false}
                    allowEdit={false}
                    storeId={storeId}
                    onDelete={() => setBarcodes(barcodes => {
                      let newBarcodes = barcodes.filter((barcode, innerIndex) => barcodeIndex != innerIndex);
                      return newBarcodes
                    })}
                  />
                )}
              </div>
            </>}
            <div className="w-full flex flex-col justify-start items-center mt-2 gap-2">
              <button className="btn" onClick={getPreview}>
                start preview
              </button>
              <button className="btn" onClick={() => setShowModal(false)}>
                close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default EditorPreviewModal