import React, { useEffect, useState } from 'react'
import { getDisplaysApi, getDisplaysForUserApi } from '../api/displayApi';
import { useDispatch, useSelector } from 'react-redux';
import Display from '../components/display/Display';
import Select from 'react-tailwindcss-select';
import i18n from '../i18n';
import { getUsersApi } from '../api/userApi';
import UserSelect from '../components/admin/UserSelect';
import Title from '../components/Title';
import Header from '../components/layout/Header';
import { logoutUser } from '../store';
import AddDisplayModal from '../components/playlist/modals/AddDisplayModal';
import DeleteDisplayFromStoreModal from '../modals/Display/DeleteDisplayFromStoreModal';

const DisplayPage = () => {
  const [displays, setDisplays] = useState([]);
  const [showDisplayModal, setShowDisplayModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDisplayData, setDeleteDisplayData] = useState({
    displayId:"",
    storeId:""
  })
  const dispatch = useDispatch();
  const [triggeredUpdate, setTriggeredUpdate] = useState(true);
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  
  useEffect(() => {
      if(showDeleteModal || showDisplayModal) {
        return;
      }
    
      if(selectedStore && user.superAdmin) {
        getDisplaysForUserApi(user.token, url, selectedStore)
          .then(res => setDisplays(res))
          .catch(err => {
            console.log(err)
            dispatch(logoutUser());
          });
      }
      if(!user.superAdmin) {
        getDisplaysApi(user.token, url)
          .then(res => setDisplays(res))
          .catch(err => {
            console.log(err);
            dispatch(logoutUser());
          });
      }
      setTriggeredUpdate(false);
  }, [selectedStore, triggeredUpdate, showDisplayModal, showDeleteModal])

  return (
    <>
      <Header/>
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='h-full w-full bg-gray-100 flex flex-col items-center overflow-scroll gap-10'>
        <Title title={"displays"}/>
        <div className='w-full p-2 flex items-center justify-center'>
          <button
            className='p-2 bg-blue-500  text-white font-bold rounded-lg hover:bg-blue-400 transition-all'
            onClick={() => setShowDisplayModal(true)}
            >
            add Display
          </button>
        </div>
        <div className='grid grid-cols-1  md:grid-cols-3 gap-3 mt-5'>
          {displays.map(display => <Display
              display={display}
              triggerUpdate={setTriggeredUpdate}
              setDisplayDeleteData={setDeleteDisplayData}
              setShowDelete={setShowDeleteModal}
            />
          )}
        </div>
      </div>
      {showDisplayModal && <AddDisplayModal setShowModal={setShowDisplayModal}/>}
      {showDeleteModal && <DeleteDisplayFromStoreModal 
        setShowModal={setShowDeleteModal}
        storeId={deleteDisplayData.storeId}
        displayId={deleteDisplayData.displayId} 
      />}
    </>
  )
}

export default DisplayPage