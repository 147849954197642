import axios from 'axios';

export const getDisplaysApi = async (token, url, selectedStore) => {
  const res = await axios.get(
    `https://backend.konekt.vip/display/getDisplays`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;  
}


export const getDisplaysForUserApi = async (token, url, selectedStore) => {
  const res = await axios.get(
    `https://backend.konekt.vip/display/getDisplays?selectedStore=${selectedStore}`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
  
}
export const getDisplayApi = async (token, url, displayId,selectedStore) => {
  try {
    const res = await axios.get(
      `https://backend.konekt.vip/display/getDisplay?displayId=${displayId}&selectedStore=${selectedStore}`,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const addDisplayApi = async (token, url, body) => {
  try {
    const res = await axios.post(
      `https://backend.konekt.vip/display/addDisplay`,
      body,
      {
        headers:{
          token:`bearer ${token}`
        }
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const deleteDisplayApi = async (token, params) => {
  const res = await axios.delete(
    `https://backend.konekt.vip/display/deleteDisplay`,
    {
      headers: {
        token: `bearer ${token}`
      },
      params
    }
  );
  return res.data;
}

export const previewDisplayApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/display/previewDisplay`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      },
    }
  );
  return res.data;
}

export const overrideDisplayApi = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/device/addOverride`,
    data,
    {
      headers: {
        token: `bearer ${token}`
      },
    }
  );
  return res.data;
}