import React from 'react'

const TrailLog = ({log}) => {
  return (
    <div className='w-full p-5 flex flex-row justify-start bg-white rounded-xl shadow-lg gap-10'>
      <h2 className='font-bold text-black flex-1'>Date: {log.createdAt}</h2>
      <h2 className='font-bold text-black flex-1'>Action: {log.action}</h2>
      <h2 className='font-bold text-black flex-1'>Store: {log.storeId}</h2>
      <h2 className='font-bold text-black flex-1'>User: {log.userEmail}</h2>

    </div>
  )
}

export default TrailLog