import React, { useEffect, useState } from 'react'
import Title from '../components/Title'
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../store';
import { getViewTemplates } from '../api/viewTemplatesApi';
import ViewTemplate from '../components/ViewTemplates/ViewTemplate';
import Header from '../components/layout/Header';
import CreateTemplateModal from '../components/ViewTemplates/CreateTemplateModal';


const ViewTemplatePages = () => {
  const [templates, setTemplates] = useState([]);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const user = useSelector(state => state.user);
  const selectedStore = useSelector(state => state.storeSelection.selectedStore);
  const dispatch = useDispatch();

  useEffect(() => {
    getViewTemplates(user.token, selectedStore)
      .then(res => setTemplates(res))
      .catch(err =>{
        dispatch(logoutUser());
        console.log(err);
      });
  }, [selectedStore]);

  return (
    <>
      <Header/>
      <div className='w-full h-full flex flex-col items-center'>
        <Title title={"templates"}/>
        {showCreateTemplate && <CreateTemplateModal setShowModal={setShowCreateTemplate}/>}
        <div className='p-2 w-full flex items-center justify-center'>
          <button 
            className='bg-blue-300 text-white font-bold p-2 rounded-xl hover:bg-blue-400 transition-all'
            onClick={() => {
              setShowCreateTemplate(true);
            }}
          >
            Create New Template
          </button>
        </div>
        <div className='grid grid-cols-1  md:grid-cols-3 gap-3 mt-5'>
          {templates.map(template => <ViewTemplate template={template} />)}
        </div>
      </div>
    </>
  )
}

export default ViewTemplatePages