import axios from "axios";

export const loginUserApi = async (login, url) => {
  const response = await axios.post(
    `https://backend.konekt.vip/auth/login`,
    login
  );
  localStorage.setItem("token", response.data.token);
  return response.data;
}

export const authUserApi = async (token, url) => {
  const response = await axios.get(
    `https://backend.konekt.vip/auth/`,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return response.data;
}