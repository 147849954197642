import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../api/itemsApi";
import Delete from '../../static/delete.png';
import Edit from '../../static/stylus.png';
import Scan from '../../static/scan.png';
import { Link, useNavigate } from "react-router-dom";
import { setSelectedStore } from "../../store";

const ItemInDevice = ({barcode, onDelete,displayIndex,barcodeIndex, allowReplace, storeId, allowEdit}) => {
  const [item, setItem] = useState({});
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const dispatch = useDispatch();


  useEffect(() => {
    getItem(user.token,url,barcode, storeId)
      .then(res => setItem(res))
      .catch(err => console.log(err))
  }, []);

  return (
    <div
      className="flex flex-col justify-center items-start gap-1 p-2  w-full  rounded-sm 
      bg-[#F7F7F8] border-2" 
    >
      <h2 className="font-bold text-md ">{barcode}</h2>
      <div className="flex flex-row justify-between items-center w-[90%]">
        {item && <>
          <h2 className="font-bold text-md max-w-[150px] max-h-[20px] overflow-clip">
            {item.name}
          </h2>
        </>}
        <div className="flex gap-8 items-center justify-end flex-1">
          {allowReplace && <button
            onClick={() => navigate('/replaceBarcodeWithScanner',{
              state:{
                item:{displayIndex,barcodeIndex,barcode,name:item.name}
              }}
            )}
          >
            <img src={Scan} width={25}/>
          </button>}
          
          <button
            onClick={() => {
              dispatch(setSelectedStore(storeId));
              navigate(`/products/editProdcut/${barcode}`)
            }}
          >
            <img className="w-[20px] mt-1" src={Edit}/>
          </button>
        
          <button
            onClick={onDelete}
          >
            <img src={Delete} width={22}/>
          </button>
      </div>
      </div>
    </div>
  )
}
export default ItemInDevice