import React, { useEffect, useState } from 'react'
import { getDevicesDetails } from '../../api/AndroidKeyApi';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const AndroidDeviceDetailsModal = ({setShowModal, mac, code, androidkey}) => {
  const {t} = useTranslation();
  const user = useSelector(state => state.user);
  const [deviceDetails, setDeviceDetails] = useState({
    ramTotal: "",
    ramFree: "",
    storageTotal: "",
    storageFree: "",
    IP: "",
    height: "",
    width: "",
    deviceName: "",
    software: "",
    osVersion: "",
    rootLevel: "",
    manufacture:""
  });

  useEffect(() => {
    getDevicesDetails(user.token, {mac})
      .then(res => setDeviceDetails(res));
    
  }, [mac]);

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-center items-center w-full h-full ">
          <div className="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700 w-[60%] p-5">
            <div className='flex w-full justify-center items-center p-2'>
              <h2 className='text-3xl font-extrabold'>Device Details</h2>
            </div>
            <div className='flex w-full h-full justify-start items-center'>
              <div className='ml-5 h-full w-[40%] flex flex-col items-center border-r-2 border-gray-400 p-2'>
                <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Code:</h2>
                  <h2 className='text-xl font-semibold'>{code}</h2>
                </div>
                <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Name:</h2>
                  <h2 className='text-xl font-semibold'>{androidkey.name}</h2>
                </div>
                <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Mac Address:</h2>
                  <h2 className='text-xl font-semibold'>{mac}</h2>
                </div>
                {deviceDetails && deviceDetails.manufacture && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>manufacture:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.manufacture}</h2>
                </div>}
              </div>
              {deviceDetails && <div className='h-full w-[33%] flex flex-col items-center border-r-2 border-gray-400 p-2'>
                {deviceDetails.osVersion && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>OS Version:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.osVersion}</h2>
                </div>}
                {deviceDetails.software && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>App Version:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.software}</h2>
                </div>}
                {deviceDetails.IP &&<div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>IP:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.IP}</h2>
                </div>}
                {deviceDetails.deviceName && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Device Name:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.deviceName}</h2>
                </div>}
              </div>}
              {deviceDetails && <div className='h-full w-[33%] flex flex-col items-center p-2'>
                {deviceDetails.ramTotal && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Ram Total:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.ramTotal}</h2>
                </div>}
                {deviceDetails.ramFree && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Ram Free:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.ramFree}</h2>
                </div>}
                {deviceDetails.height && <div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Screen Height:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.height}</h2>
                </div>}
                {deviceDetails.width &&<div className='w-full flex items-center gap-2'>
                  <h2 className='text-xl font-extrabold'>Screen Width:</h2>
                  <h2 className='text-xl font-semibold'>{deviceDetails.width}</h2>
                </div>}
              </div>}
              
              {/* <div className='w-full flex justify-center items-center gap-5'>
                <h2 className='text-2xl font-extrabold'>Code: {code}</h2>
                <h2 className='text-2xl font-extrabold'>Name: {androidkey.name}</h2>
                <h2 className='text-2xl font-extrabold'>Mac Address: {mac}</h2>
              </div>
              {deviceDetails && <div className='w-full flex justify-center items-center gap-5'>
                  <h2 className='text-2xl font-extrabold'>OS Version: {deviceDetails.osVersion}</h2>
                  <h2 className='text-2xl font-extrabold'>App Version: {deviceDetails.software}</h2>
                  <h2 className='text-2xl font-extrabold'>IP: {deviceDetails.IP}</h2>
              </div>}
              {deviceDetails && <div className='w-full flex justify-center items-center gap-5'>
                <h2 className='text-2xl font-extrabold '>Stroage Free: {deviceDetails.storageFree}</h2>
                <h2 className='text-2xl font-extrabold '>Storage Total: {deviceDetails.storageTotal}</h2>
                <h2 className='text-2xl font-extrabold '>Root Level: {deviceDetails.rootLevel}</h2>
              </div>}
              {deviceDetails && <div className='w-full flex justify-center items-center gap-5'>
                <h2 className='text-2xl font-extrabold '>RAM: {deviceDetails.ramTotal}</h2>
                <h2 className='text-2xl font-extrabold '>Height: {deviceDetails.height}</h2>
                <h2 className='text-2xl font-extrabold '>width: {deviceDetails.width}</h2>

              </div>} */}
              {}
            </div>
            <div className="flex flex-row justify-center gap-10  w-[100%]">
              <button
                className='btn text-xl !p-3'
                onClick={() => setShowModal(false)}
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default AndroidDeviceDetailsModal