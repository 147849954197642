import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Logo from '../static/logoLogin.png';
import EmailIcon from '../static/inputIcons/sms.png';
import LockIcon from '../static/inputIcons/lock.png';
import { loginUserApi } from '../api/auth';
import { loginUser, setSelectedStore } from '../store';
import { setUrl as setUrlRedux } from '../store';
import { useTranslation } from 'react-i18next';
import Select from 'react-tailwindcss-select';
const languages = [
  {value:"en", label:"English"},
  {value:"he", label:"עברית"}
]

const languageLookup = {
  he:"עברית",
  en:"English",
  "en-us":"English",
  "en-uk":"English"
}

const Login = () => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();

  const [url, setUrl] = useState(localStorage.getItem("url") || "");
  const [login, setLogin] = useState({
    email:localStorage.getItem("loginEmail") || "",
    password:localStorage.getItem("loginPassword") || ""
  });
  const [error, setError] = useState("");
  const sendLogin = async (e) => {
    e.preventDefault();
    try {
      const loginToSend = {...login};
      loginToSend.email = loginToSend.email.toLowerCase();
      const res = await loginUserApi(loginToSend, url);
      localStorage.setItem("loginEmail", loginToSend.email);
      localStorage.setItem("loginPassword", login.password);
      dispatch(loginUser(res));
      dispatch(setUrlRedux(url));
      dispatch(setSelectedStore(res.storeId));
    } catch (error) {
      console.log(error)
      if(error?.response?.data?.msg === "user doesn't exist" || error?.response?.data?.msg === "Password is incorrect") {
        setError("אימייל/סיסמה שגויה");
      }
      else {
        setError("שגיאה לא צפיוה, הנה נסו מאוחר יותר")
        
      }
      setTimeout(() => setError(""), 2000)
    }
  }


  const onChange = (e) => {
    setLogin(login => ({...login, [e.target.name]: e.target.value}));
  }

  return (
    <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='flex flex-col justify-start min-h-screen overflow-scroll bg-[#F7F7F8]  w-full gap-2'>
      <div className='flex flex-col justify-start w-full items-center bg-[#2D5186]'>
        <img src={Logo} width={250} className='p-3' alt='Logo'/>
      </div>
      <div className="flex flex-col justify-start items-start w-screen p-6 m-auto mt-0 lg:max-w-xl">
        <div className='relative flex flex-col mb-2 gap-3 z-10 w-full'>
          <label
            className='flex flex-row justify-start text-md font-semibold text-gray-800'
          >
            {t("language")}
          </label>

          <Select
            options={languages}
            value={i18n.language ? {value:i18n.language, label:languageLookup[i18n.language]} : (function (){
              i18n.changeLanguage("he");
              return {value:"he", label:"עברית"};
            }())}
            onChange={(selection)=> i18n.changeLanguage(selection.value)}
            classNames={{
              menu:"w-full absolute w-[100%] bg-white border-2 border-gray overflow-scroll",
              menuButton:() => "flex flex-row w-[100%]  bg-white border-2 border-gray px-2 justify-between",
              listItem: ({ isSelected }) => (
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                    isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`
              )
            }}
          />

        
        </div>
        <form className="mt-2 w-[100%]" onSubmit={sendLogin}>
          <div className="mb-2 flex flex-col">
            <label
              className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
            >
             {t("email")}
            </label>
            <div className="relative flex items-start">
              <img src={EmailIcon} className={`absolute top-[12px] ${i18n.language == "he" ? "left-[90.5%] md:left-[93%]": "left-[1.5%]"}`} width={22} alt='Email'/>
              <input
                type="text"
                name="email"
                value={login.email}
                className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              className="flex flex-row justify-start text-md font-semibold text-gray-800 mb-2"
            >
              {t("password")}
            </label>
            <div className="relative flex items-center">
              <img src={LockIcon} className={`absolute top-[12px] ${i18n.language == "he" ? "left-[90.5%] md:left-[93%]": "left-[1%]"}`} alt='password'/>
              <input
                type="password"
                name="password"
                value={login.password}
                className="block w-full h-10 p-5 px-10 text-black bg-white border-2 border-gray  focus:ring-none outline-none"
                onChange={onChange}
              />
            </div>
          </div>
          {error && <h2 className='text-red-400 font-semibold text-md'>{error}</h2>}
          <div className="mt-6 flex flex-row justify-center items-center">
            <button
              className="w-[100%] px-4 py-2 tracking-wide text-white font-semibold
                transition-colors duration-200 transform bg-[#43A6FB] rounded-md hover:bg-[#3396CB]
                focus:outline-none focus:bg-[#3396GB]"
              type="submit"
            >
              {t("enter")}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login