import React, { useEffect, useState } from 'react'
import AddIcon from '../static/addB.png'
import Search from '../static/search.png'
import { searchItems } from '../api/itemsApi';
import { useDispatch, useSelector } from 'react-redux';
import Item from '../components/items/Item';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import Title from '../components/Title';
import Header from '../components/layout/Header';
import { logoutUser, setLoadingState } from '../store';
import { Link } from 'react-router-dom';
import Loading from '../modals/Loading';

const ItemsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const storeFilter = useSelector(state => state.storeSelection.selectedStore);
  const [items, setItems] = useState([]);
  const {t} = useTranslation()
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const loading = useSelector(state => state.loading.loading);

  const dispatch = useDispatch();
  
  const onSearch = async () => {
    try {
      dispatch(setLoadingState(true));
      const res = await searchItems(
        user.token,
        url,
        searchTerm,
        storeFilter
      );
      console.log(res)
      setItems(res);
      dispatch(setLoadingState(false));
    } catch (err) {
      dispatch(logoutUser());
      console.log(err);
    }
  }

  useEffect(() => {
    if(items && items.length > 0) {
      onSearch();
    }
  }, [storeFilter])
  return (
    <>
      <Header showBack={true} backTitle={""}/>
      {loading && <Loading/>}
      <div dir={i18n.language == "he" ? "rtl" :"ltr"} className='h-full w-full bg-gray-100 flex flex-col items-center overflow-scroll gap-4 p-2'>
        <div className='bg-white p-5 flex flex-col  shadow-md w-[95%]  justify-center gap-4 items-center rounded-lg'>
          <div className='w-full flex justify-end'>
            <Link to={`createProduct/`}>
              <button className='border-2 border-[#2D5186] p-2 px-3 rounded-xl text-white font-bold hover:bg-gray-200 transition-all'>
                <img src={AddIcon} className='w-[20px]'/>
              </button>
            </Link>
          </div>
          <h2 className='font-bold text-2xl'>{t("searchItems")}</h2>
          <div className='flex flex-row justify-between w-[100%] gap-5'>
            <input 
              className="block w-full h-10 pl-8  text-black bg-white
                border-b-2 border-black  focus:ring-none outline-none"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if(e.key == "Enter") {
                  onSearch();
                } 
              }}
            />
            <button className='btn' onClick={onSearch}>
              <img src={Search} className='w-[25px]'/>
            </button>
          </div>
        </div>
        {items?.length > 0 && <div 
          className='bg-white p-5 flex flex-col 
            shadow-md w-[95%]  justify-start gap-4 items-center rounded-lg overflow-scroll'
        > 
          {items.map(item => <Item item={item}/>)}
        </div>}
      </div>
    </>
  )
}

export default ItemsPage