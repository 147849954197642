import React, { useEffect, useState } from 'react'
import Delete from '../../static/delete.png';
import Edit from '../../static/stylus.png';
import DeleteDisplay from '../../modals/Device/DeleteDisplay';
import EditDisplay from '../../modals/Device/EditDisplay';
import Active from '../../static/check_circle.png';
import HideContentIcon from '../../static/collapse.png';
import ShowContentIcon from '../../static/view-details.png'
import Activate from '../../static/eye.png';
import hideIcon from '../../static/eyes.png';
import Up from '../../static/up.png';
import Down from '../../static/down.png';
import UploadIcon from '../../static/uploadB.png';

import {  addDurationApi, deleteDurationApi, deviceSetDates, deviceSetRotation, previewDisplayFromDevice, setDeviceDisplayNumber } from '../../api/deviceApi';
import { useDispatch, useSelector } from 'react-redux';
import { addDuration, removeDuration, setNewDisplayDates, setNewDisplayTime, setNewRotation } from '../../store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import PlaylistElement from '../playlistElements/PlaylistElement';
import { getItemNames } from '../../api/itemsApi';
const DeviceDisplay = ({display, barcodes, deviceId,duration ,displayIndex, active, storeId, posNumber, setDeviceOverwrite, deviceOverride}) => {
  const device = useSelector(state => state.device);
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  const durationState = device?.duration?.find(dur => dur.index == displayIndex);
  const [showDelete, setShowDelete] = useState(false);
  const [barcodeNames, setBarcodeNames] = useState([]);

  const [previewContent, setPreviewContent]  = useState({
    show: false,
    content: []
  })

  const getContent = async () => {
    try {
      if(device?.override && device.override.length > 0 && device.override.findIndex(ovr => ovr.index == displayIndex) > -1) {
        const ind = device?.override.findIndex(ovr => ovr.index == displayIndex);
        if(ind > -1) {
          return setPreviewContent({
            show:true,
            content: device.override[ind].override.elements
          });
        }
      }
      
      const res = await previewDisplayFromDevice(user.token, {
        index: posNumber,
        deviceId: device.deviceId,
        barcodes,
        storeId
      });
      if(res) {
        setPreviewContent({
          show:true,
          content: res
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const removeContent = () => {
    setPreviewContent({
      show:false,
      content: []
    })
  }
  const [time, setTime] = useState(device.displayTime[displayIndex] || {start:0, end:24});
  const [date, setDate] = useState({
    startDate: device?.displayDates[displayIndex]?.start || new Date().toString(),
    endDate: device?.displayDates[displayIndex]?.end || new Date().toString()
  });
  useEffect(() => {
    setDate({
      startDate: device?.displayDates[displayIndex]?.start || new Date().toString(),
      endDate: device?.displayDates[displayIndex]?.end || new Date().toString(),
    })
  }, [])
  useEffect(() => {
    getItemNames(user.token, {barcodes, storeId})
      .then(res => {setBarcodeNames(res.names)})
      .catch(err => console.log(err));
  }, [])

  useEffect(() => {
    getContent();
  }, []);
  
  const [showDate, setShowDate] = useState(device?.displayDates[displayIndex]?.checkDate || false); 
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onChangeTime = async (start, end) => {
    let deviceRotation = device.deviceRotation;
    let displayTime = [...device.displayTime];
    displayTime[displayIndex] = {start, end};
    await deviceSetRotation(
      user.token,
      url,
      {deviceId, deviceRotation, displayTime}
    );
    dispatch(setNewDisplayTime(displayTime));
    setTime({start, end});
  }

  return (
    <div className='flex flex-col w-[100%] min-h-[150px] justify-start items-center text-center rounded-xl  gap-5 border-[1px] border-gray-200'>
      <div className='flex justify-between items-center gap-2 bg-[#F7F7F8] w-full p-2 rounded-t-md'>
        {showDelete && <DeleteDisplay setShowModal={setShowDelete} displayIndex={displayIndex}/>}
        <div className='flex flex-col gap-5 justify-center overflow-x-scroll p-2'>
          <h2 className='font-bold text-md xl:text-xl'>{posNumber+1}. {display}</h2>
        </div>
        <div className='flex justify-center gap-5'>
          {device?.deviceRotation?.includes(displayIndex) ? (<button 
            className=''
            onClick={async () => {
              try {
                const newRotation = device.deviceRotation.filter(
                  x => x !== displayIndex);
                await deviceSetRotation(
                  user.token, url, {deviceId, deviceRotation: newRotation});
                dispatch(setNewRotation(newRotation));
                setTime({start:0, end:24});
              } catch (err) {
                console.log(err)
              }
            }}
          >
          <img className='w-[25px]' src={hideIcon}  alt=''/>
          </button>) : (<button 
            className=''
            onClick={async () => {
              try {
                let newRotation = []
                if(device.deviceRotation) {
                  newRotation = [...device.deviceRotation, displayIndex];
                }
                else {
                  newRotation = [displayIndex];
                }
                await deviceSetRotation(
                  user.token, url, {deviceId, deviceRotation: newRotation});
                dispatch(setNewRotation(newRotation));
              } catch (err) {
                console.log(err)
              }
            }}
          >
            <img className='w-[25px]' src={Activate} alt='activate/deactivate display' />
          </button>)}
          <Link
            to={`/device/editDisplay/${displayIndex}`}
          >
            <button
              className=''
            >
              <img className='w-[25px]' src={Edit} alt='' />
            </button>
          </Link>
          <button 
            className=''
            onClick={() => setShowDelete(true)}
          >
            <img className='w-[25px]' src={Delete} alt='' />
          </button>
        </div>
        
      </div>

      <div className={`items-center flex flex-row xl:flex-row justify-between gap-2  ${device?.deviceRotation?.includes(displayIndex) && "border-b-[1px] pb-4"} w-[90%]`}>
        <h2 className='text-black font-medium text-lg xl:text-xl'>{t('displayStatus')}</h2>
        <h2 className={`font-semibold text-lg xl:text-xl ${device?.deviceRotation?.includes(displayIndex) ? "text-green-500" : "text-gray-400"}`}>
          {device?.deviceRotation?.includes(displayIndex) ? t('active'): t('notActive')}
        </h2>
        
      </div>
      {barcodeNames && barcodeNames.length > 0 && <div className={`items-start flex flex-col  justify-end border-b-[1px] pb-4 w-[90%]`}>
        {barcodeNames.map(brc => <h2 className='font-semibold'>{brc}</h2>)}

      </div>}
      
      {<div className='w-full flex flex-col justify-center items-center'>
        <div className='w-full flex items-center px-5 gap-2 mb-5'>
          {!previewContent.show && <button className='flex items-center gap-5 ' onClick={getContent}>
            <img src={ShowContentIcon} className='w-5'/>
          </button>}
          {previewContent.show &&
          <>
            <button className='flex items-center gap-5 ' onClick={removeContent}>
              <img src={HideContentIcon} className='w-5'/>
            </button>
          </>}
          <button className='flex items-center gap-5 '>
            <img
              src={UploadIcon} 
              className='w-5' 
              onClick={() => {
                removeContent();
                setDeviceOverwrite(posNumber);
              }}
              />
          </button>
        </div>

        {previewContent.show && <div className='relative w-full grid grid-cols-3 p-2 gap-3 overflow-scroll'>
          {previewContent?.content.map(dis => <PlaylistElement element={dis}/>)}
        </div>}
        
      </div>}
      <div className='w-[90%] flex flex-row justify-start items-center gap-2 p-1 '>
      <input 
          type='checkBox'
          checked={!!durationState}
          onChange={async (e) => {
            if(!durationState) {
              await addDurationApi(user.token, {duration: 1, index: posNumber, deviceId: device.deviceId});
              return dispatch(addDuration({duration: 1, index: posNumber}));
              
            }
            
            if(!!durationState) {
              await deleteDurationApi(user.token, {deviceId: device.deviceId, index: posNumber});
              return dispatch(removeDuration({index: posNumber}));
              
            }
          }}
        />
        <h2 className='text-black font-bold text-md'>Set Custom Duration</h2>
      </div>

      {!!durationState && <div className='w-[90%] flex flex-col items-start justify-center mb-4'>
        <div className='flex  gap-2 border-[1px] border-[#2D5186] p-1'>
          <button
            className=''
            disabled={durationState.duration <= 1}
            onClick={async () => {
              await addDurationApi(user.token, {duration: parseInt(durationState.duration) -1, index: posNumber, deviceId: device.deviceId});
              return dispatch(addDuration({duration: durationState.duration -1, index: posNumber}));
              
            }}
          >
            <img src={Down} width={30}/>
          </button>
          <h2>{durationState.duration} Sec.</h2>
          <button
            className=''
            onClick={async () => {
              await addDurationApi(user.token, {duration: parseInt(durationState.duration) + 1, index: posNumber, deviceId: device.deviceId});
              return dispatch(addDuration({duration: durationState.duration +1, index: posNumber}));
              
            }}
          >
            <img src={Up} width={30}/>
          </button>
        </div>
      </div>}
      
       <div className='w-[90%] flex flex-row justify-start items-center gap-2 p-1'>
        <input 
          type='checkBox'
          checked={showDate}
          onChange={async (e) => {
              try {
                let displayDates = [...device.displayDates]
                displayDates[displayIndex] = {
                  start: date.startDate,
                  end: date.endDate,
                  checkDate: !showDate
                };
                const res = await deviceSetDates(user.token, {
                  deviceId,
                  deviceRotation: device.deviceRotation,
                  displayDates
                })
                dispatch(setNewDisplayDates(res));
                setDate({
                  startDate: res[displayIndex].start,
                  endDate: res[displayIndex].end
                })
                setShowDate(!showDate)
              } catch (err) {
                console.log(err)
              }
          }}
        />
        <h2 className='text-black font-bold text-md'>{t("setShowDate")}</h2>
      </div>
      
       
      <div className='items-start flex flex-row justify-start gap-2 pb-2 w-[95%] mt-[-25px]'>
        <div className='flex flex-col justify-start items-start gap-2 w-[50%] p-2'>
         {showDate && <div className='flex flex-col justify-start items-start '>
            <h2 className='text-black m-2'>{t("startDate")}</h2>
            <DatePicker
              selected={date.startDate}
              onChange={async (newDate) => {
                try {
                  let displayDates = [...device.displayDates]
                  displayDates[displayIndex] = {
                    start: newDate,
                    end: date.endDate,
                    checkDate: showDate
                  };
                  const res = await deviceSetDates(user.token, {
                    deviceId,
                    deviceRotation: device.deviceRotation,
                    displayDates
                  })
                  dispatch(setNewDisplayDates(res));
                  setDate((date) => ({...date, startDate:newDate}))
                } catch (err) {
                  console.log(err)
                }
              }}
              className='p-2 border-[#2D5186] border-[1px]  focus:border-[#2D5186] focus:outline-none w-[100%]'
            />
          </div>}
          <h2 className='font-medium text-md'>{t("startHour")}</h2>
          <div className='flex  gap-2 border-[1px] border-[#2D5186] p-1'>
            <button
              className=''
              disabled={time.start <= 0}
              onClick={async () => await onChangeTime(time.start - 1, time.end)}
            >
              <img src={Down} width={30}/>
            </button>
            <h2>{time.start}:00</h2>
            <button
              className=''
              disabled={time.start >= 24}
              onClick={async () => await onChangeTime(time.start + 1, time.end)}
            >
              <img src={Up} width={30}/>
            </button>
          </div>
        </div>
        
        <div className='flex flex-col justify-between items-start gap-2 w-[50%] p-2'>
        {showDate && <div className='flex flex-col justify-start items-start '>
            <h2 className='text-black m-2'>{t("endDate")}</h2>
            <DatePicker
              selected={date.endDate}
              onChange={async (newDate) => {
                try {
                  let displayDates = [...device.displayDates]
                  displayDates[displayIndex] = {
                    start: date.startDate,
                    end: newDate,
                    checkDate: showDate
                  };
                  const res = await deviceSetDates(user.token, {
                    deviceId,
                    deviceRotation: device.deviceRotation,
                    displayDates
                  })
                  dispatch(setNewDisplayDates(res));
                  setDate((date) => ({...date, endDate:newDate}))
                } catch (err) {
                  console.log(err)
                }
              }}
              className='p-2 border-[#2D5186] border-[1px]  focus:border-[#2D5186] focus:outline-none w-[100%]'
            />
          </div>}
          <h2 className='font-medium text-md'>{t("endHour")}</h2>
          <div className='flex gap-2 border-[1px] p-1 border-[#2D5186]'>
            <button
              className=''
              disabled={time.end <= 0}
              onClick={async () => await onChangeTime(time.start, time.end -1)}
            >
              <img src={Down} width={30}/>
            </button>
            <h2>{time.end}:00</h2>
            <button
              className=''
              disabled={time.end >= 24}
              onClick={async () => await onChangeTime(time.start, time.end +1)}
            >
              <img src={Up} width={30}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceDisplay
