import Delete from '../../static/delete.png';
import RefreshLogo from '../../static/refresh.png';
import GreenLogo from '../../static/green.png';
import RedLogo from '../../static/red.png';
import LoadingAnim from '../../static/loading.gif';
import playlistLogo from '../../static/playlistLogo.png';
import upldatePlaylistLogo from '../../static/updatePlaylist.png';
import ViewDetailsIcon from '../../static/view-details.png';
import PreviewIcon from '../../static/eye.png';
import ControlsIcon from '../../static/equalizer.png';
import MessageIcon from '../../static/email.png';
import RotationIcon from '../../static/rotate.png'; 
import KioskIcon from '../../static/kiosk.png';
import GroupIcon from '../../static/brackets.png';

import { useState } from 'react';
import AndroidKeyControls from './AndroidKeyControls';
import AndroidDeviceDetailsModal from './AndroidDeviceDetailsModal.jsx';
import { refreshAndroidDevice } from '../../api/AndroidKeyApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDeviceInfo, previewDevice } from '../../api/deviceApi';
import { setDisplaysModal, setLoadingState, setSelectedStore } from '../../store';
import AndroidMessageModal from './AndroidMessageModal.jsx';
import AndroidRotateModal from './AndroidRotateModal.jsx';
import KioskModeModal from './KioskModeModal.jsx';

const AndroidKey = ({androidKey, setUnbind, addRemoveToList, checked, setChangePlaylistId, selectAndroidGroup}) => {
  const [showControlsModal, setShowControlsModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showRotationModal, setShowRotationModal] = useState(false);
  const [showKioskModal, setShowKioskModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyControls, setKeyControls] = useState({
    volume: androidKey?.controls?.volume || 0,
    brightness: androidKey?.controls?.brightness || 0,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  return (
    <tr key={androidKey._id} className="border-b transition items-center duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
      <td className="text-black font-bold border-r-2 p-2 flex  ">
        <input type='checkbox' className='mx-2' checked={checked} onClick={() => {addRemoveToList(androidKey.mac, androidKey.code)}}/>
        {androidKey.mac}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {androidKey.storeId}
      </td>
      <td className="text-black font-bold border-r-2 p-2 ">
        {androidKey.name}
      </td>
      <td className="text-black font-bold border-r-2 p-2"> {androidKey.code}</td>
      <td className="text-black font-bold border-r-2 p-2  gap-5 flex justify-between">
        <h2 className='max-w-[55%] overflow-scroll'>
          {androidKey.deviceId ? androidKey.deviceId : "No playlist Bound"}
        </h2>
        <div className='flex gap-2 hover:cursor-pointer'>
          {androidKey.deviceId && <img
            src={PreviewIcon} 
            className='w-5'
            onClick={async () => {
              try {
                dispatch(setLoadingState(true));
                const previewData = await previewDevice(
                  user.token,
                  {deviceId: androidKey.deviceId}
                );
                dispatch(setDisplaysModal(previewData));
              } catch (err) {
                console.log(err);
              } finally {
                dispatch(setLoadingState(false));
              }
            }}
          />}
          {androidKey.deviceId && <img 
            src={playlistLogo} 
            className='w-5 hover:cursor-pointer'
            onClick={async () => {
              try {
                const deviceData = await getDeviceInfo(user.token, androidKey.deviceId);
                dispatch(setSelectedStore(deviceData.storeId));
                navigate(`/editDevice/${deviceData.deviceId}`);
              } catch (err) {
                console.log(err)
              }
            }}
          />}
        </div>

      </td>
      <td className="text-black font-bold border-r-2 p-2 relative align-middle content-center text-center" style={{alignItems:"center"}}>{androidKey.connected 
        ? <img src={GreenLogo} className='absolute left-[45%] top-[10px] w-5'/> 
        : <img src={RedLogo} className='absolute left-[45%] top-[10px] w-5'/>}
      </td>
      <td className="text-black font-bold border-r-2 p-2 relative align-middle content-center text-center">
        {androidKey.connected ? "Now" : androidKey.lastActive}
      </td>
      <td className="w-full flex flex-col justify-center items-center mt-2 gap-2 px-2">
       {loading 
        ? <div className='w-full flex flex-row justify-center items-center'>
            <img src={LoadingAnim} className='w-5'/>
        </div>
        :<>
          <div className='w-full flex items-center justify-center gap-2'>
            <button
              onClick={async () => {
                setLoading(true)
                await refreshAndroidDevice(user.token,{mac: androidKey.mac})
                setLoading(false)
              }}
            >
              <img src={RefreshLogo} className='w-5'/>
            </button>
            <button
              onClick={() => setShowControlsModal(true)}
            >
              <img src={ControlsIcon} className='w-5'/>
            </button>
            <button
              onClick={() => setShowDetailsModal(true)}
            >
              <img src={ViewDetailsIcon} className='w-5'/>
            </button>
            <button  
              onClick={()=> {setUnbind(androidKey.code)}}
            >
              <img src={Delete}/>
            </button>
          </div>
          <div className='w-full flex items-center justify-center gap-2'>
            <img
              src={upldatePlaylistLogo} 
              className='w-5 cursor-pointer'
              onClick={() => setChangePlaylistId(androidKey)}
            />
            <img
              src={MessageIcon} 
              className='w-5 cursor-pointer'
              onClick={() => setShowMessageModal(true)}
            />
            <img
              src={RotationIcon} 
              className='w-5 cursor-pointer'
              onClick={() => setShowRotationModal(true)}
            />
            <img
              src={KioskIcon} 
              className='w-5 cursor-pointer'
              onClick={() => setShowKioskModal(true)}
            />
            <img
            src={GroupIcon} 
            className='w-5 cursor-pointer'
            onClick={() => selectAndroidGroup(androidKey)}
          />
          </div>
        </>}
      </td>
      {showKioskModal && <KioskModeModal mac={androidKey.mac} setShowModal={setShowKioskModal} kioskM={androidKey.kioskMode}/>}
      {showRotationModal && <AndroidRotateModal mac={androidKey.mac} deviceRotation={androidKey.rotation} setShowModal={setShowRotationModal}/>}
      {showMessageModal && <AndroidMessageModal mac={androidKey.mac} setShowModal={setShowMessageModal}/>}
      {showDetailsModal && <AndroidDeviceDetailsModal setShowModal={setShowDetailsModal} mac={androidKey.mac} code={androidKey.code} androidkey={androidKey}/>}
      {showControlsModal && <AndroidKeyControls setShowModal={setShowControlsModal} code={androidKey.code} controls={keyControls} setControls={setKeyControls} mac={androidKey.mac}/>}
    </tr>
  )
}

export default AndroidKey