import React, { useEffect, useState } from 'react'
import Webcam from "react-webcam";
import i18n from '../i18n';
import { useSelector } from 'react-redux';
import { bindEsl, scanBarcodeApi, scanEslApi } from '../api/barcodeApi';
import { useNavigate } from 'react-router-dom';
import Scanner from '../components/scanner/Scanner';


const ScannerBind = () => {
  const navigate = useNavigate();

  const [bind, setBind] = useState({
    esl:"",
    itemBarcode:""
  });

  const [scanType, setScanType] = useState("itemBarcode");
  const [scanned, setScanned] = useState("");

  const [error, setError] = useState("");
  const [startCamera, setStartCamera] = useState(true);

  useEffect(() => {
    if(scanned != "") {
      if(scanType == "esl"){
        setBind(bind => ({...bind, "esl": scanned}));
      }
      if(scanType == "itemBarcode") {
        setBind(bind => ({...bind, "itemBarcode": scanned}));
      }
      setScanned("")
    }
  }, [scanned]);

  const handleScan = (code) => {
      setScanned(code);
  }

  const user = useSelector(state => state.user);

  const onChange = (e) => {
    setBind(bind => 
      ({...bind, [e.target.name]:e.target.value}));
  }

  const sendBindData = async () => {
    try {
      await bindEsl(user.token, bind);
      navigate('/');
    } catch (error) {
      console.log(error);
      setError("Binding Failed");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }

  return (
   <div
      dir={i18n.language == "he" ? "rtl" :"ltr"}
      className='h-screen w-full bg-white flex flex-col items-center
        overflow-scroll pb-[250px]'
   >
      <div className='flex flex-col justify-center items-center gap-2 p-5 w-full md:w-[50%]'>

          <Scanner startCamera={startCamera} setStartCamera={setStartCamera} setData={handleScan} style={{height:"20"}}/>
            <h2 className='font-bold text-md'>Scan Type</h2>
          <div className='flex justify-center items-center w-full gap-4'>
            <div className='flex gap-2 justify-center items-center'>
              <input
                className=''
                type='radio'
                name="esl"
                value="esl"
                id='eslRadio'
                onChange={() => {
                  setScanType("esl")
                }}
                checked={scanType === "esl"}
              />
              <label
                className='font-semibold text-[15px]'
              >
                ESL
              </label>
            </div>
            <div className='flex gap-2 justify-center items-center'>
              <input
                className=''
                type='radio'
                name="Barcode"
                id='barcodeRadio'
                value="itemBarcode"
                onChange={() => {
                  setScanType("itemBarcode");
                }}
                checked={scanType ==="itemBarcode"}
              />
              <label
                className='font-semibold text-[15px]'
              >
                Barcode
              </label>
            </div>
          </div>
          <input
            type='text'
            value={bind.esl}
            name="esl"
            placeholder='ESL Code'
            className="h-10 pl-8 w-full text-black bg-white border-[1px] border-[#e0e0e2]  focus:ring-none outline-none"
            onChange={onChange}
          />

          <input
            type='text'
            name="itemBarcode"
            value={bind.itemBarcode}
            placeholder='item Barcode'
            className="h-10 pl-8 w-full text-black bg-white border-[1px] border-[#e0e0e2]  focus:ring-none outline-none"
            onChange={onChange}
          />
          <button className='btn w-full' onClick={sendBindData}>
            Submit
          </button>
          <div className='w-full flex flex-col justify-center items-center'>
            <h2 className='text-red-400 font-bold text-lg'>{error}</h2>
          </div>
        </div>
   </div>
  )
}

export default ScannerBind