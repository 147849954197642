import { Link} from 'react-router-dom';
import DeleteIcon from '../../static/delete.png';
import PreviewIcon from '../../static/eye.png';

const PlaylistRow = ({playlist}) => {
  console.log(playlist)
  console.log(playlist.lastOnline)
  let rots = playlist.deviceRotation;
  let scheduled = 0;
  for(let i = 0; i < rots.length; i++) {
    if(playlist?.displayDates && playlist?.displayDates[rots[i]]?.checkDate) {
      scheduled +=1
    } else if(playlist?.displayWeeks && playlist?.displayWeeks[rots[i]]?.check) {
      scheduled +=1
    }
  } 
  return (
    <tr 
    key={playlist._id} 
    className='border-b transition items-center duration-300 ease-in-out
      hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600'
    >
      <td className='text-black font-bold border-r-2 p-2 flex'>
        {playlist.playlistName ? playlist.playlistName : playlist.deviceId}
      </td>
      <td className='text-black font-bold border-r-2 p-2 '>
        {playlist?.deviceRotation?.length}
      </td>
      <td className='text-black font-bold border-r-2 p-2 '>
        {playlist?.displayIds?.length}
      </td>
      <td className='text-black font-bold border-r-2 p-2'>
        {playlist?.onlineDevices}
      </td>
      <td className='text-black font-bold border-r-2 p-2'>
        {playlist?.offlineDevices}
      </td>
      <td className='text-black font-bold border-r-2 p-2'>
        {scheduled}
      </td>
      <td className='text-black font-bold border-r-2 p-2'>
        <div className='flex flex-col items-center'>
          <h2>
            {playlist?.lastOnline?.split('T')[0]}
          </h2>
          <h2>
            {playlist?.lastOnline?.split('T')[1].split('.')[0]}

          </h2>

        </div>
      </td>
      <td className='text-black font-bold border-r-2 p-2 flex justify-center'>
        <Link to={`/editDevice/${playlist.deviceId}`}>
          <button>    
            <img src={PreviewIcon}/>
          </button>
        </Link>
        <div>
          <button>
            <img src={DeleteIcon}/>
          </button>
        </div>
      </td>
    </tr>
  )
}

export default PlaylistRow