import axios from 'axios';

export const getTrailLogs = async (token, params = {}) => {
  const res = await axios.get(
    `https://backend.konekt.vip/trail`,
    {
      headers:{
        token: `bearer ${token}`
      },
      params:params
    }
  )
  return res.data;
}
