import axios from 'axios';

export const uploadApk = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/uploading/apk`,
    data,
    {headers:{token:`bearer ${token}`}}
  );
  return res.data;
}

export const uploadImage = async (token, url, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/uploading/image`,
    data,
    {headers:{token:`beaer ${token}`}}
  );
  return res.data;
}

export const uploadVideo = async (token, url, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/uploading/video`,
    data,
    {headers:{token:`beaer ${token}`}}
  );
  return res.data;
}

export const uploadPowerPoint = async (token, url, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/uploading/powerpoint`,
    data,
    {headers:{token:`beaer ${token}`}}
  );
  return res.data;
}

export const uploadPdf = async (token, url, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/uploading/pdf`,
    data,
    {headers:{token:`beaer ${token}`}}
  );
  return res.data;
}

export const getUploadsApi = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/uploading`,
    {
      params,
      headers:{
        token:`beaer ${token}`
      }
    }
  );
  return res.data;
}

export const deleteUpload = async (token, params) => {
  const res = await axios.delete(
    `https://backend.konekt.vip/uploading`,
    {
      params,
      headers:{
        token:`beaer ${token}`
      }
    }
  );
  return res.data;
}
export const editUploadName = async (token, body) => {
  const res = await axios.put(
    `https://backend.konekt.vip/uploading`,
    body,
    {
      headers:{
        token:`beaer ${token}`
      }
    }
  );
  return res.data;
}