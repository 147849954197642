import axios from "axios";

export const getGroupListCall = async (token, params) => {
  const res = await axios.get(
    `https://backend.konekt.vip/groupList`,
    {
      headers:{
        token:`bearer ${token}`,
      },
      params
    }
  );
  return res.data;
}

export const createUpdateGroupListCall = async (token, data) => {
  const res = await axios.post(
    `https://backend.konekt.vip/groupList`,
    data,
    {
      headers:{
        token:`bearer ${token}`
      }
    }
  );
  return res.data;
}