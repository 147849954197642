import React, { useState } from 'react'
import { uploadVersion } from '../../api/versionUploadApi';
import CloseIcon from '../../static/crossB.png';
import LoadingAnim from '../../static/loading.gif';
import { useSelector } from 'react-redux';

const VersionUploadModal = ({setShowModal}) => {
  const [version, setVersion] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state.user);

  const handleApkUpload = async (e) => {
    const file = e.target.files[0];
    if(!version) {
      setError("Version field cannot be empty");
      return setTimeout(() => {
        setError("");
      }, 3000)
    }

    const form = new FormData();
    form.append('file', file);
    form.append('version', version);
    setLoading(true);
    try {
      const result = await uploadVersion(user.token, form);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="mt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col justify-start items-center w-full h-full max-w-2xl">
          <div className="relative flex flex-col items-center bg-white rounded-lg shadow dark:bg-gray-700 w-[80%]">

            <button
              className='cursor-pointer absolute left-[92%] top-2 z-20'
              onClick={() => setShowModal(false)}
            >
              <img src={CloseIcon}/>
            </button>
            {loading && <div className='w-full  flex flex-col justify-center items-center text-center p-2 gap-5'>
              <h2 className='font-bold text-lg'>
                Uploading Apk...
              </h2>
              <img
                src={LoadingAnim}
              />
            </div>}
            {!loading && <div className="relative flex flex-col justify-center items-center text-center w-[90%] p-5 gap-2">
              <p
                className="w-full text-lg  text-black font-bold"
              >
                Version
              </p>
              <input 
                className='w-[100%] rounded-lg p-3 text-black font-bold border-2'
                type='text'
                value={version}
                name="version"
                onChange={(e) => setVersion(e.target.value)}
              />
              {error && <h2 className='text-red-500'>{error}</h2>}
              <input id="apkInput" hidden type="file" accept='.apk' onChange={handleApkUpload} />
              <label
                className='bg-[#2D5186] p-2 rounded-lg text-white font-bold'
                htmlFor="apkInput"
                
              >
                Add APK
              </label>
            </div>}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

export default VersionUploadModal