import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-tailwindcss-select';
import { addDisplayApi, getDisplayApi } from '../../api/displayApi';
import { getStoresApi } from '../../api/storesApi';

const CloneDisplay = ({currDisplay, setShowModal, triggerUpdate}) => {
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onChange = (e) => {
    setDisplay(display => ({...display, displayId:e.target.value}))
  }
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [display, setDisplay] = useState(currDisplay);
  useEffect(()=> {
    if(user.superAdmin) {
      getStoresApi(user.token, url)
        .then(res => {
          setStores(res)
          setSelectedStore(res[0].storeId)
        })
        .catch(err => console.log(err))
    }
  }, []);

  const submitDisplay = async () => {
    try {
      await addDisplayApi(user.token, url, {...display, storeId: selectedStore});
      setShowModal(false);
      triggerUpdate(true);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <div className='modal'>
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-9 justify-center items-center">
            <div className="flex items-center justify-center border-b border-solid border-slate-200 rounded-t p-9">
              <h3 className="text-2xl font-bold text-black">
                {t("cloneDisplay")}
              </h3>
            </div>
            <div className="flex flex-col justify-center items-center text-center w-full gap-2">
            <p
              className="block text-xl text-black font-bold"
            >
              {t("displayName")}
            </p>
            <input
              onChange={onChange}
              type="text"
              name="price"
              defaultValue={display.displayId}
              className="h-10 pl-8 text-black  border-b-2 border-black  focus:ring-none outline-none w-[90%]"
            />
          </div>
	        {user.superAdmin && <div className="relative p-3 flex flex-col justify-center items-center gap-3">
              <div className='flex flex-col md:flex-row gap-2 justify-center items-center p-2'>
                <h2 className='w-full font-bold text-md'>{t("cloneToUser")}:</h2>
                <Select
                  options={
                    stores.map(store => 
                      ({value:store.storeId, label:store.storeId}))
                  }
                  value={{value:selectedStore, label:selectedStore}}
                  onChange={(selection)=> setSelectedStore(selection.value)}
                  classNames={{
                    menu:"absolute w-[200px] h-[250px]  bg-white border-2 border-black overflow-scroll",
                    menuButton:() => "flex flex-row w-[200px] bg-white border-2 border-black px-2 justify-center",
                    listItem: ({ isSelected }) => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`
                    )
                  }}
                />
              </div>
            </div>}
            <div className="flex flex-col gap-2 items-center justify-center p-6 border-slate-200 rounded-b">
              <button 
                className='btn uppercase !p-3'
                onClick={submitDisplay}
              >
                {t("clone")}
              </button>
              <button
                className="text-white bg-red-500 rounded-lg font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-red-300"
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t("close")}
              </button>
            </div>
          </div>    
        </div>
      </div>
    </div>
  )
}

export default CloneDisplay
