export const DisplayStructs = {
  HorizontalFullHD1920x1080_OneTemplate:[
    {
      "type": "viewTemplate",
      "style": {
        "left": "-1%",
        "top": "-1%",
        "width": "99%",
        "height": "99%",
      }
    }
  ],
  HorizontalFullHD1920x1080_4Templates:[
    {
      "type": "viewTemplate",
      "style": {
        "left": "-2%",
        "top": "-1%",
        "width": "102.5%",
        "height": "100%",
        "position": "absolute",
        "borderRadius": "NaN%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "45%",
        "height": "45%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "4%",
        "left": "2%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "20%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "45.5%",
        "height": "45.5%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "4%",
        "left": "50.5%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "45%",
        "height": "45%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "52%",
        "left": "2%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
  ],
  HorizontalFullHD1920x1080_6Templates:[
    {
      "type": "viewTemplate",
      "style": {
        "left": "-2%",
        "top": "-1%",
        "width": "102.5%",
        "height": "100%",
        "position": "absolute",
        "borderRadius": "NaN%"
      },
      "content": "https://smartsignage.ams3.cdn.digitaloceanspaces.com/undefined/xVvNid.mp4"
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "23%",
        "height": "31.5%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "1.5%",
        "left": "2%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "22.5%",
        "height": "30.5%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "2%",
        "left": "37.5%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "23.5%",
        "height": "30%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "1.5%",
        "left": "71.5%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "23%",
        "height": "30%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "67.5%",
        "left": "1%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "23.5%",
        "height": "30%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "67.5%",
        "left": "36%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "23.5%",
        "height": "30%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "67.5%",
        "left": "72%"
      },
      "content": ""
    }
  ],
  VerticalFullHD1920x1080_OneTemplate:[
    {
      "type": "viewTemplate",
      "style": {
        "left": "-1%",
        "top": "-1%",
        "width": "99%",
        "height": "99%",
      }
    }
  ],
  VerticalFullHD1920x1080_2ColumnTemplate:[
    {
      "type": "view",
      "style": {
        "width": "100%",
        "height": "48%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "1",
        "left": "1%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": 50
      }
    },
    {
      "type": "view",
      "style": {
        "width": "100%",
        "height": "48%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "50%",
        "left": "1%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": 50
      }
    }
  ],
  VerticalFullHD1920x1080_4Templates: [
    {
      "type": "viewTemplate",
      "style": {
        "left": "-2%",
        "top": "-1%",
        "width": "102.5%",
        "height": "100%",
        "position": "absolute",
        "borderRadius": "NaN%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "45%",
        "height": "45%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "4%",
        "left": "2%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "20%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "45.5%",
        "height": "45.5%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "4%",
        "left": "50.5%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
      "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "45%",
        "height": "45%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "52%",
        "left": "2%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
  ],
  VerticalFullHD1920x1080_10RowPriceList: [
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "0%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "10%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "20%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "30%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "40%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "50%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "60%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "70%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "80%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
    {
      "type": "viewTemplate",
      "style": {
        "width": "100%",
        "height": "10%",
        "position": "absolute",
        "flexDirection": "column",
        "justifyContent": "start",
        "alignItems": "center",
        "top": "90%",
        "left": "0%",
        "box-shadow": "10px 10px 10px 10px black",
        "borderRadius": "15%"
      },
       "content": ""
    },
  ],
  Stretch1920x158_OneTemplate:[
    {
      "type": "viewTemplate",
      "style": {
        "left": "-1%",
        "top": "-1%",
        "width": "99%",
        "height": "99%",
      }
    }
  ]
}