import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getStoreDetails, updateStore } from '../api/storesApi';
import Header from '../components/layout/Header';
import OperationSuccesfulModal from '../components/layout/OperationSuccesfulModal';

const EditStorePage = () => {
  const {storeId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state=> state.user);
  
  const [store, setStore] = useState({
    storeId:storeId,
    name:"",
    storageLimit:"",
    deviceLimit:"",
    zkongAccount:"",
    zkongPassword:"",
    zkongStoreId:"",
    zkongMerchantId:""
  });

  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const fetchData = async () =>{
    try {
      const storeData = await getStoreDetails(user.token, storeId);
      setStore(storeData);
    } catch (err) {
      console.log(err);
    }
  }
  
  const submitUpdate = async () => {
    try {
      await updateStore(user.token,store);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000)
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData();  
  }, []);

  console.log(store);

  return (
    <div className='w-full h-full flex flex-col items-center justify-start gap-2 overflow-scroll'>
      {showSuccessMessage && <OperationSuccesfulModal/>}
      <h2 className='font-bold text-2xl'>Edit Store</h2>
      <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
        <p
          className="w-full text-lg  text-black font-bold"
        >
          Store ID:  {store.storeId}
        </p>
      </div>
      <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
        <p
          className="w-full text-lg  text-black font-bold"
        >
          Name
        </p>
        <input 
          className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
          type='text'
          value={store.name}
          name="code"
          onChange={(e) => setStore(store => ({...store, "name":e.target.value}))}
        />
      </div>
      <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
        <p
          className="w-full text-lg  text-black font-bold"
        >
          ESL Account
        </p>
        <input 
          className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
          type='text'
          value={store.zkongAccount}
          name="code"
          onChange={(e) => setStore(store => ({...store, "zkongAccount":e.target.value}))}
        />
      </div>
      <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
        <p
          className="w-full text-lg  text-black font-bold"
        >
          ESL Password
        </p>
        <div className='w-full flex-col items-center'>
          <div className='flex items-center w-full m-2 gap-2'>
            <input
              className='rounded-lg   text-black font-bold border-2 mt-1'
              type='checkbox'
              checked={changePassword}
              onChange={(e) => setChangePassword(flag => !flag)}
            />
            <h2 className='text-lg font-semibold'>Change Password</h2>
          </div>
          <input 
            className='w-[100%] rounded-lg p-3  text-black font-bold border-2 disabled:bg-gray-500'
            type='text'
            name="code"
            disabled={!changePassword}

            onChange={(e) => setStore(store => ({...store, "zkongPassword":e.target.value}))}
          />
        </div>
      </div>
      <div className="relative flex justify-start items-center text-center w-[50%] gap-2">
        <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
          <p
            className="w-full text-lg  text-black font-bold"
          >
            ESL Store Id
          </p>
          <input 
            className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
            type='text'
            value={store.zkongStoreId}
            name="code"
            onChange={(e) => setStore(store => ({...store, "zkongStoreId":e.target.value}))}
          />
        </div>
        <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
          <p
            className="w-full text-lg  text-black font-bold"
          >
            ESL Merchant
          </p>
          <input 
            className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
            type='text'
            value={store.zkongMerchantId}
            name="code"
            onChange={(e) => setStore(store => ({...store, "zkongMerchantId":e.target.value}))}
          />
        </div>
      </div>
      <div className="relative flex justify-start items-center text-center w-[50%] gap-2">

        <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
          <p
            className="w-full text-lg  text-black font-bold"
          >
            Stoarge Limit
          </p>
          <input 
            className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
            type='number'
            value={store.storageLimit}
            name="code"
            onChange={(e) => {
              if(isNaN(e.target.value) || parseInt(e.target.value) < 0) {
                return;
              }
              setStore(store => ({...store, "storageLimit":parseInt(e.target.value)}))
            }}
          />
        </div>
        <div className="relative flex flex-col justify-start items-center text-center w-[50%] gap-2">
          <p
            className="w-full text-lg  text-black font-bold"
          >
            device Limit
          </p>
          <input 
            className='w-[100%] rounded-lg p-3  text-black font-bold border-2'
            type='number'
            value={store.deviceLimit}
            name="code"
            onChange={(e) => {
              if(isNaN(e.target.value) || parseInt(e.target.value) < 0) {
                return;
              }
              setStore(store => ({...store, "deviceLimit":parseInt(e.target.value)}))
            }}
          />
        </div>
      </div>
      <button className='btn hover:bg-blue-400 transition-all' onClick={submitUpdate}>
        Save Settings
      </button>
    </div>
  )
}

export default EditStorePage