import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getItem } from "../../api/itemsApi";
import Delete from '../../static/delete.png';
import Edit from '../../static/stylus.png';
import Scan from '../../static/scan.png';
import { Link, useNavigate } from "react-router-dom";

const ItemBarcode = ({barcode, storeId}) => {
  const [item, setItem] = useState({});
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const url = useSelector(state => state.url.url);
  


  useEffect(() => {
    console.log(barcode)
    getItem(user.token,url,barcode, storeId)
      .then(res =>{
         setItem(res)
        })
      .catch(err => console.log(err))
  }, []);

  return (
    <>
      
      <div className="flex flex-row justify-between items-center w-[100%]">
        {item && <>
          <h2 className="font-bold text-md   overflow-clip">
            {item.name}
          </h2>
        </>}
      </div>
  </>
  )
}

export default ItemBarcode