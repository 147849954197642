import React, { useEffect, useState } from 'react'
import Edit from '../../static/stylus.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CloneDisplay from '../../modals/Display/CloneDisplay'
import Clone from '../../static/cloneB.png';
import Delete from '../../static/delete.png';
import { useSelector } from 'react-redux'
const Display = ({display, triggerUpdate, setShowDelete, setDisplayDeleteData}) => {
  const [showCloneModal, setShowCloneModal] = useState(false);
  const user = useSelector(state => state.user);
  const {t} = useTranslation()
  return (
    <div className='flex flex-col justify-center items-center bg-white p-5 gap-3 shadow-md'>
      {showCloneModal && <CloneDisplay currDisplay={display} setShowModal={setShowCloneModal} triggerUpdate={triggerUpdate}/>}
      <div className='flex flex-col justify-center items-center gap-1'>
        <h2 className='text-black font-bold'>{display.displayId}</h2>
        <h2  className='text-black font-bold'>{t("structure")}: {display.structure}</h2>
      </div>
      <div className='flex justify-center items-center gap-2'>
        <Link
          to={`/displays/${display.displayId}`}
        >
          <button className=''>
            <img  src={Edit} width={24} className='mt-2'/>
          </button>
        </Link>
        <button className='' onClick={() => setShowCloneModal(true)}>
          <img src={Clone} width={24}/>
        </button>
        <button 
          onClick={() => {
            setDisplayDeleteData({
              displayId:display.displayId,
              storeId: display.storeId
            });
            setShowDelete(true);
          }}
        >
          <img src={Delete} width={24}/>
        </button>
      </div>
    </div>
  )
}

export default Display
